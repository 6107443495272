<template>
	<div class="modal fade" id="advertPromotionModal" ref="advertPromotionModal" tabindex="-1" aria-labelledby="Продвижение объявления" style="z-index: 9999;">
		<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-20">
				<div class="modal-header d-flex align-items-center mb-20 border-0 p-0">
					
					<span  v-if="this.place == null" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Продвижение объявления</span>
					<span v-else-if="this.place == 'zipal'" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Размещение с отчётами</span>
					<span v-else-if="this.place == 'jcat'" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Срок размещения на JCat</span>
					<span v-else-if="this.place == 'avito'" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Срок размещения на Avito</span>
					<span v-else-if="this.place == 'cian'" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Срок размещения на ЦИАН</span>
					<span v-else-if="this.place == 'domclick'" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Срок размещения на ДомКлик</span>
					<span v-else-if="this.place == 'yandex'" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Срок размещения на Я.Недвижимость</span>
					
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body px-2">
					<div v-if="this.inArray(this.place, ['free', 'avito', 'cian', 'domclick', 'yandex'])" class="row-fluid">
						<div class="col">
							
							<InputNumber inputLabel="Количество дней размещения:"
										 :inputValue="this.days_comp"
										 inputMin="1"
										 inputStep="1"
										 inputMax="365"
										 inputClass="rounded-1 mb-10"
										 inputWrapClass="flex-column mb-3"
										 :inputDisabled="this.no_limit"
										 @setValue="(value) => { (value > 1) ? this.days = value : this.days = 1 }" />
						</div>
						<div v-if="!this.use_advert_budget" class="col">
							<Switcher inputLabel="Без ограничения количества дней"
									  :inputValue="this.no_limit"
									  inputClass="ps-0 rounded-1 mb-10"
									  inputWrapClass="flex-column mb-2"
									  @setValue="(value) => this.no_limit = !Boolean(this.no_limit)" />
						</div>
					</div>
					<div v-if="this.inArray(this.place, ['avito', 'cian', 'domclick', 'yandex'])" class="row-fluid">
						<dl class="dl-horizontal mt-3" :class="{ 'pt-2 border-top': !this.use_advert_budget }">
							<dt>Стоимость размещения:</dt>
							<dd>{{ this.total_price }} руб.</dd>
							<dt v-if="this.use_advert_budget">Доступный бюджет:</dt>
							<dd v-if="this.use_advert_budget && (this.is_agency || this.is_unlimited)">не ограничен</dd>
							<dd v-else-if="this.use_advert_budget" :class="{'is_hight': (this.budget), 'is_lower': (!this.budget)}">{{ this.formatPrice(this.budget) }} руб.</dd>
						</dl>
						<div v-if="this.use_advert_budget" class="form-text d-flex fs-5 mb-3">
							<img :src="require('@/assets/icons/info.svg')" alt="Info" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: -2px;">
							Доступный бюджет ответственного по объекту для {{ this.service_name }} из расчёта стоимости размещения за каждый период (30 дней).
						</div>
					</div>
					<div v-if="!this.isEmpty(this.errors)" class="d-flex flex-column mb-10">
						<span class="alert alert-danger mb-2"
							  v-for="(error, index) in this.errors"
							  :key="index"
							  v-text="error" />
					</div>
					<table v-if="!this.isEmpty(this.promo)" class="table table-sm">
						<thead>
							<tr class="mb-3">
								<th>Площадка</th>
								<th>Пакет продвижения</th>
							</tr>
						</thead>
						<tbody>
							<tr v-if="this.place == null || this.place == 'avito'" class="mb-3">
								<td class="position-relative">
									<div class="position-sticky top-0 py-2">
										<img :src="require('@/assets/svg/avito-logo.svg')" alt="Avito" class="d-inline-flex" height="28" />
									</div>
								</td>
								<td>
									<div class="inputs py-2 mt-1 col-form-label-sm">
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="avito_promo_1" name="avito_highlight" :checked="this.promo.avito_highlight" @change="this.check('avito_package', 'avito_highlight', $event)">
											<label for="avito_promo_1" class="form-check-label" title="Выделение объявления">Выделение объявления</label>
										</div>
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="avito_promo_2" name="avito_x2_1" :checked="this.promo.avito_xl" @change="this.check('avito_package', 'avito_xl', $event)">
											<label for="avito_promo_2" class="form-check-label" title="XL-объявление">XL-объявление</label>
										</div>
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="avito_promo_3" name="avito_x2_1" :checked="this.promo.avito_x2_1" @change="this.check('avito_package', 'avito_x2_1', $event)">
											<label for="avito_promo_3" class="form-check-label" title="Пакет «До 2 раз больше просмотров на 1 день»">x2 просмотров на 1 день</label>
										</div>
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="avito_promo_4" name="avito_x2_7" :checked="this.promo.avito_x2_7" @change="this.check('avito_package', 'avito_x2_7', $event)">
											<label for="avito_promo_4" class="form-check-label" title="Пакет «До 2 раз больше просмотров на 7 дней»">x2 просмотров на 7 дней</label>
										</div>
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="avito_promo_5" name="avito_x5_1" :checked="this.promo.avito_x5_1" @change="this.check('avito_package', 'avito_x5_1', $event)">
											<label for="avito_promo_5" class="form-check-label" title="Пакет «До 5 раз больше просмотров на 1 день»">x5 просмотров на 1 день</label>
										</div>
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="avito_promo_6" name="avito_x5_7" :checked="this.promo.avito_x5_7" @change="this.check('avito_package', 'avito_x5_7', $event)">
											<label for="avito_promo_6" class="form-check-label" title="Пакет «До 5 раз больше просмотров на 7 дней»">x5 просмотров на 7 дней</label>
										</div>
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="avito_promo_7" name="avito_x10_1" :checked="this.promo.avito_x10_1" @change="this.check('avito_package', 'avito_x10_1', $event)">
											<label for="avito_promo_7" class="form-check-label" title="Пакет «До 10 раз больше просмотров на 1 день»">x10 просмотров на 1 день</label>
										</div>
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="avito_promo_8" name="avito_x10_7" :checked="this.promo.avito_x10_7" @change="this.check('avito_package', 'avito_x10_7', $event)">
											<label for="avito_promo_8" class="form-check-label" title="Пакет «До 10 раз больше просмотров на 7 дней»">x10 просмотров на 7 дней</label>
										</div>

										<InputText inputId="avito_price"
												   inputName="avito_price"
												   ref="avito_price"
												   inputLabel="Стоимость продвижения:"
												   inputClass="rounded-1 mb-10"
												   inputWrapClass="flex-column mb-2"
												   inputAppend="руб."
												   inputType="number"
												   :inputDisabled="(!this.promo.avito_highlight && !this.promo.avito_xl && !this.promo.avito_x2_1 && !this.promo.avito_x2_7 && !this.promo.avito_x5_1 && !this.promo.avito_x5_7 && !this.promo.avito_x10_1 && !this.promo.avito_x10_7)"
												   :inputValue="(this.promo.avito_price) ? this.promo.avito_price : 0"
												   @setValue="(value) => this.promo.avito_price = Number(value)" />

									</div>
								</td>
							</tr>
							<tr v-if="this.place == null || this.place == 'cian'" class="mb-3">
								<td class="position-relative">
									<div class="position-sticky top-0 py-2">
										<img :src="require('@/assets/svg/cian-logo.svg')" alt="ЦИАН" class="d-inline-flex" height="28" />
									</div>
								</td>
								<td>
									<div class="inputs py-2 mt-1 col-form-label-sm">
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="cian_promo_2" name="cian_standard" :checked="this.promo.cian_standard" :disabled="(this.promo.cian_ignore_pkg)" @change="this.check('cian_package', 'cian_standard', $event)">
											<label for="cian_promo_2" class="form-check-label" title="Платное объявление">Стандарт</label>
										</div>
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="cian_promo_3" name="cian_premium" :checked="this.promo.cian_premium" :disabled="(this.promo.cian_ignore_pkg)" @change="this.check('cian_package', 'cian_premium', $event)">
											<label for="cian_promo_3" class="form-check-label" title="Премиум-объявление">Премиум-объявление</label>
										</div>
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="cian_promo_4" name="cian_top3" :checked="this.promo.cian_top3" :disabled="(this.promo.cian_ignore_pkg)" @change="this.check('cian_package', 'cian_top3', $event)">
											<label for="cian_promo_4" class="form-check-label" title="Топ">Топ</label>
										</div>
										<div class="form-check mb-3 ps-0">
											<label for="cian_promo_5" class="form-control-label mb-2" title="Ставка аукциона">Ставка аукциона (руб.):</label>
											<input id="cian_promo_5" type="number" class="form-control rounded-3" name="cian_bet" v-model="this.promo['cian_bet']">
										</div>
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="cian_promo_6" name="cian_ignore_pkg" :checked="this.promo.cian_ignore_pkg" @change="this.check('cian_package', 'cian_ignore_pkg', $event)">
											<label for="cian_promo_6" class="form-check-label" title="Не использовать пакет размещений при публикации объявления">Не использовать пакет размещений</label>
										</div>

										<InputText inputId="cian_price"
												   inputName="cian_price"
												   ref="cian_price"
												   inputLabel="Стоимость продвижения:"
												   inputClass="rounded-1 mb-10"
												   inputWrapClass="flex-column mb-2"
												   inputAppend="руб."
												   inputType="number"
												   :inputDisabled="(!this.promo.cian_standard && !this.promo.cian_paid && !this.promo.cian_premium && !this.promo.cian_top3 && !this.promo.cian_ignore_pkg)"
												   :inputValue="(this.promo.cian_price) ? this.promo.cian_price : 0"
												   @setValue="(value) => this.promo.cian_price = Number(value)" />

									</div>
								</td>
							</tr>
							<tr v-if="this.place == null || this.place == 'yandex'" class="mb-3">
								<td class="position-relative">
									<div class="position-sticky top-0 py-2">
										<img :src="require('@/assets/svg/yandex-logo.svg')" alt="Я.Недвижимость" class="d-inline-flex" height="28" />
									</div>
								</td>
								<td>
									<div class="inputs py-2 mt-1 col-form-label-sm">
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="yandex_promo_1" name="yandex_premium" :checked="this.promo.yandex_premium" @change="this.check('yandex_package', 'yandex_premium', $event)">
											<label for="yandex_promo_1" class="form-check-label" title="Премиум размещение">Премиум</label>
										</div>
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="yandex_promo_2" name="yandex_raise" :checked="this.promo.yandex_raise" @change="this.check('yandex_package', 'yandex_raise', $event)">
											<label for="yandex_promo_2" class="form-check-label" title="Поднятие объявления">Поднятие</label>
										</div>
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="yandex_promo_3" name="yandex_promotion" :checked="this.promo.yandex_promotion" @change="this.check('yandex_package', 'yandex_promotion', $event)">
											<label for="yandex_promo_3" class="form-check-label" title="Продвижение объявления">Продвижение</label>
										</div>

										<InputText inputId="yandex_price"
												   inputName="yandex_price"
												   ref="yandex_price"
												   inputLabel="Стоимость продвижения:"
												   inputClass="rounded-1 mb-10"
												   inputWrapClass="flex-column mb-2"
												   inputAppend="руб."
												   inputType="number"
												   :inputDisabled="(!this.promo.yandex_premium && !this.promo.yandex_raise && !this.promo.yandex_promotion)"
												   :inputValue="(this.promo.yandex_price) ? this.promo.yandex_price : 0"
												   @setValue="(value) => this.promo.yandex_price = Number(value)" />

									</div>
								</td>
							</tr>
							<tr v-if="this.place == null || this.place == 'domclick'" class="mb-3">
								<td class="position-relative">
									<div class="position-sticky top-0 py-2">
										<img :src="require('@/assets/svg/domclick-logo.svg')" alt="ДомКлик" class="d-inline-flex" height="28" />
									</div>
								</td>
								<td>
									<div class="inputs py-2 mt-1 col-form-label-sm">
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="domclick_promo_1" name="domclick_express" :checked="this.promo.domclick_express"  @change="this.check('domclick_package', 'domclick_express', $event)">
											<label for="domclick_promo_1" class="form-check-label" title="Экспресс">Экспресс</label>
										</div>
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="domclick_promo_2" name="domclick_premium" :checked="this.promo.domclick_premium"  @change="this.check('domclick_package', 'domclick_premium', $event)">
											<label for="domclick_promo_2" class="form-check-label" title="Премиум">Премиум</label>
										</div>
										<div class="form-check mb-3">
											<input class="form-check-input" type="checkbox" value="1" id="domclick_promo_3" name="domclick_top" :checked="this.promo.domclick_top"  @change="this.check('domclick_package', 'domclick_top', $event)">
											<label for="domclick_promo_3" class="form-check-label" title="Топ">Топ</label>
										</div>

										<InputText inputId="domclick_price"
												   inputName="domclick_price"
												   ref="domclick_price"
												   inputLabel="Стоимость продвижения:"
												   inputClass="rounded-1 mb-10"
												   inputWrapClass="flex-column mb-2"
												   inputAppend="руб."
												   inputType="number"
												   :inputDisabled="(!this.promo.domclick_express && !this.promo.domclick_premium && !this.promo.domclick_top)"
												   :inputValue="(this.promo.domclick_price) ? this.promo.domclick_price : 0"
												   @setValue="(value) => this.promo.domclick_price = Number(value)" />

									</div>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div class="modal-footer border-0 p-0">
					<button type="button"
							class="btn btn-primary col-12 rounded-3 p-3 text-white fs-3"
							@click="this.addObjectPromo()">
						Сохранить
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import api from "@/api";
	import CommonService from "@/services/CommonService";
	import InputText from "@/components/inputs/InputText";
	import InputNumber from "@/components/inputs/InputNumber";
	import Switcher from "@/components/inputs/Switcher";
    export default {
        name: 'AdvertPromotionModal',
		props: {
			objectId: { type: Number },
		},
        data() {
			return {
				object_id: null,
				promo: {},
				errors: [],
				place: null,
				days: 30,
				checkout: 0,
				no_limit: true,
				zipal: {
					destinations: [],
				},
				packages: {},
				destinations: {},
				publish: [],
				price: 0,
				total_price: 0,
				budget: 0,
				is_processed: false,
				is_switcher: false
			}
		},
        components: {
			InputText,
			InputNumber,
			Switcher
        },
		methods: {
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			formatPrice(value, currency, sign) {

				let price = CommonService.formatPrice(value ?? '', currency, sign);
				if (!isNaN(price))
					return price;
				else
					return value;

			},
			getPromotion(object_id) {

				let _this = this;
				return api.get('/adverts/promotion', {
					params: {
						object_id: object_id,
					}
				})
				.then((response) => {
					
					CommonService.log('debug', 'getPromotion()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						if (!_this.isEmpty(response.data.packages)) {
							return response.data.packages;
						}
					}

					return false;
				}).catch(function (error) {

					CommonService.log('error', 'getPromotion()::axios', error);

					return false;
				});
			},
			check(section, prop, event) {

				if (section == "avito_package") {
					this.promo.avito_highlight = false;
					this.promo.avito_xl = false;
					this.promo.avito_x2_1 = false;
					this.promo.avito_x2_7 = false;
					this.promo.avito_x5_1 = false;
					this.promo.avito_x5_7 = false;
					this.promo.avito_x10_1 = false;
					this.promo.avito_x10_7 = false;
				} else if (section == "cian_package") {
					this.promo.cian_highlight = false;
					this.promo.cian_standard = false;
					this.promo.cian_paid = false;
					this.promo.cian_premium = false;
					this.promo.cian_top3 = false;
				} else if (section == "yandex_package") {
					this.promo.yandex_premium = false;
					this.promo.yandex_raise = false;
					this.promo.yandex_promotion = false;
				} else if (section == "domclick_package") {
					this.promo.domclick_express = false;
					this.promo.domclick_premium = false;
					this.promo.domclick_top = false;
				}

				if (event.target.checked)
					this.promo[prop] = true;
				else
					this.promo[prop] = false;

			},
			resetData() {
				this.place = null;
				this.days = 30;
				this.checkout = 0;
				this.no_limit = true;
				this.publish = [];
				this.packages = [];
				this.zipal = [];
				this.is_processed = false;
			},
			getPrice(object_id, days, place) {

				this.is_processed = true;

				return api.get('/adverts/price', {
					params: {
						object_id: object_id,
						service: place,
						days_num: days,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getPrices()::axios', {response: response.data});

					setTimeout(() => { this.is_processed = false}, 600);

					if (response.status == 200 && response.data.success) {
						if (!this.isEmpty(response.data)) {
							return response.data;
						}
					}

					return false;
				}).catch(function (error) {

					this.is_processed = false;

					CommonService.log('error', 'getPrices()::axios', error);

					return false;
				});
			},
			addObjectAdverts(state) {

				if (typeof state == "undefined")
					state = false

				let service = this.place;
				let object_id = this.object_id;
				let is_moderation = this.is_moderation;
				let use_advert_budget = this.use_advert_budget;
				CommonService.log('info', 'addRemoveObjectAdverts()', {object_id: object_id, service: service, is_moderation: is_moderation, is_advert_budget: use_advert_budget, state: state});
				this.$emit('setObjectAdvertState', {object_id: object_id, service: service, is_moderation: is_moderation, is_advert_budget: use_advert_budget, state: !state});

				let data = {
					service: service,
					object_id: object_id,
					is_moderation: is_moderation,
					is_advert_budget: use_advert_budget,
					current: state,
					days: (!this.no_limit) ? this.days : null,
				}

				CommonService.log('debug', data);

				if (this.validate && !this.isEmpty(service) && !this.isEmpty(object_id)) {

					if (this.inArray(this.place, ['packages', 'zipal'])) {
						data.publish = (service == 'packages') ? Object.values(this.publish) : Object.entries(this.publish);
						data.price = parseFloat(this.total_amount);
					}

					let _this = this;
					api.put('/adverts/set', data).then((response) => {
						CommonService.log('debug', 'addObjectAdverts()::axios', {response});
						console.log(response.data);
						if (response.status == 200 && response.data.success) {
							_this.resetData();
							this.$emit('setObjectAdvertState', {object_id: object_id, service: service, state: response.data.state});
							if (!is_moderation) {
								_this.$emit('updateObject', this.object_id);
							}
						}
					}).catch(function (error) {

						CommonService.log('error', 'addObjectAdverts()::axios', error);

					});
				}
			},
			addObjectPromo() {
				
			
				this.errors = [];
				/*if (this.promo.avito_price == '' && (this.promo.avito_highlight || this.promo.avito_xl || this.promo.avito_x2_1 || this.promo.avito_x2_7 || this.promo.avito_x5_1 || this.promo.avito_x5_7 || this.promo.avito_x10_1 || this.promo.avito_x10_7))
					this.errors.push('Не указана стоимость продвижения для Avito.');

				if (this.promo.cian_price == '' && (this.promo.cian_highlight || this.promo.cian_paid || this.promo.cian_premium || this.promo.cian_top3 || this.promo.cian_ignore_pkg))
					this.errors.push('Не указана стоимость продвижения для ЦИАН.');

				if (this.promo.yandex_price == '' && (this.promo.yandex_premium || this.promo.yandex_raise || this.promo.yandex_promotion))
					this.errors.push('Не указана стоимость продвижения для Я.Недвижимость.');

				if (this.promo.domclick_price == '' && (this.promo.domclick_express || this.promo.domclick_premium || this.promo.domclick_top))
					this.errors.push('Не указана стоимость продвижения для ДомКлик.');*/

				if (this.isEmpty(this.errors) && !this.isEmpty(this.object_id)) {

					CommonService.log('log', {object_id: this.object_id, packages: this.promo});

					api.put('/adverts/promotion', {
						object_id: this.object_id,
						packages: this.promo
					}).then((response) => {
						
						CommonService.log('debug', 'addObjectPromo()::axios', {response});

						if (response.status == 200 && response.data.success)
							//this.$emit('updateObject', this.object_id);
							this.addObjectAdverts();

						let modal = CommonService.getModal('advertPromotionModal');
						if (modal)
							modal.hide();

					}).catch(function (error) {

						CommonService.log('error', 'addObjectPromo()::axios', error);

					});
				}
			},
			
		},
		computed: {
			validate() {
				let is_valid = true;
				if (this.place == 'packages') {
					if (!this.isEmpty(this.publish)) {
						if (this.publish_packages_total > this.$store.getters.userInfo.services.balance) {
							is_valid = false;
						}
					} else {
						is_valid = false;
					}
				} else if (this.place == 'zipal') {
					/*if (this.isEmpty(this.publish)) {
						is_valid = false;
					}*/
					is_valid = true;
				} else if (this.inArray(this.place, ['avito', 'cian', 'domclick', 'yandex', 'jcat'])) {
					if (!this.is_agency) {
						if (this.use_advert_budget && !this.no_limit && (!this.is_unlimited))
							is_valid = (this.total_price <= this.budget);
					}
				}

				return is_valid;
			},
			days_comp() {

				if (this.days < 1)
					return 1;

				return this.days;
			},
			is_moderation() {
				return this.$store.getters.userInfo.user_can.add_to_moderation;
			},
			base_price: {
				get() {
					if (!this.isEmpty(this.zipal.base_price)) {
						return parseFloat(this.zipal.base_price).toFixed(2) ?? 0.00;
					}

					return 0.00;
				},
				set(value) {
					value = parseFloat(value);
				}
			},
			total_amount() {
				return (parseFloat(this.checkout) + parseFloat(this.base_price)).toFixed(2);
			},
			prices() {
				let prices = [];

				if (Array.isArray(this.zipal.destinations)) {
					this.zipal.destinations.forEach((destination, name) => {
						destination.forEach(item => {
							prices[name + '_' + item.type] = parseFloat(item.price);
						});
					});
				}

				return prices;
			},
			service_name() {

				switch(this.place) {

					case 'avito':
						return "Avito";

					case 'cian':
						return "ЦИАН";

					case 'domclick':
						return "ДомКлик";

					case 'yandex':
						return "Я.Недвижимость";

					case 'jcat':
						return "JCat";

					case 'zipal':
						return "Zipal";

				}

				return null;
			},
			user() {
				return this.$store.getters.userInfo;
			},
			is_agency() {
				return this.user.is_agency;
			},
			use_advert_budget() {
				return this.user.services.use_advert_budget;
			},
			is_unlimited() {

				let advert_budget = this.user.services.advert_budget;
				switch(this.place) {

					case 'avito':
						return advert_budget.avito.is_unlimited;

					case 'cian':
						return advert_budget.cian.is_unlimited;

					case 'domclick':
						return advert_budget.domclick.is_unlimited;

					case 'yandex':
						return advert_budget.yandex.is_unlimited;

					case 'jcat':
						return advert_budget.jcat.is_unlimited;

					case 'zipal':
						return advert_budget.zipal.is_unlimited;

				}

				return false;
			},
			publish_packages_total() {
				let total = 0;
				if (Array.isArray(this.publish)) {
					this.publish.forEach(packg => {
						total = total + parseFloat(packg.total_price);
					});
				}
				return parseFloat(total);
			},
		},
		mounted() {
			var _this = this;
			var modal = this.$refs.advertPromotionModal;
			if (modal && typeof modal !== "undefined") {
				
				modal.addEventListener('hidden.bs.modal', function (event) {
					if(_this.is_switcher){
						_this.$emit('updateObject', _this.object_id);
						_this.is_switcher = false;
					}
					_this.object_id = null;
					_this.promo = {};
					_this.emitter.emit("global.modalClose", modal);
					_this.days = 30;
					_this.place = null;

					
				});

				modal.addEventListener('show.bs.modal', function (event) {
					let target = event.relatedTarget

					let object_id = target.getAttribute('data-bs-object-id');
					if (typeof object_id !== "undefined")
						_this.object_id = Number(object_id);
						let place = target.getAttribute('data-bs-place');
					if (typeof place !== "undefined")
						_this.place = place;
					let is_switcher = target.getAttribute('data-bs-isswitcher');
					if (typeof is_switcher !== "undefined")
						_this.is_switcher = true;
						
						

					if (_this.object_id) {
						_this.getPrice(object_id, _this.days, _this.place).then(data => {

							if (!_this.isEmpty(data))
								_this.price = data.price;
							else
								_this.price = 0;

							_this.total_price = _this.price;

							if (!_this.isEmpty(data))
								_this.budget = data.budget;
							else
								_this.budget = 0;

							});
						_this.getPromotion(object_id).then(data => {
							_this.promo.avito_highlight = (Number(data.avito_highlight) > 0);
							_this.promo.avito_xl = (Number(data.avito_xl) > 0);
							_this.promo.avito_x2_1 = (Number(data.avito_x2_1) > 0);
							_this.promo.avito_x2_7 = (Number(data.avito_x2_7) > 0);
							_this.promo.avito_x5_1 = (Number(data.avito_x5_1) > 0);
							_this.promo.avito_x5_7 = (Number(data.avito_x5_7) > 0);
							_this.promo.avito_x10_1 = (Number(data.avito_x10_1) > 0);
							_this.promo.avito_x10_7 = (Number(data.avito_x10_7) > 0);
							_this.promo.avito_price = Number(data.avito_price);
							_this.promo.cian_highlight = (Number(data.cian_highlight) > 0);
							_this.promo.cian_paid = (Number(data.cian_paid) > 0);
							_this.promo.cian_premium = (Number(data.cian_premium) > 0);
							_this.promo.cian_top3 = (Number(data.cian_top3) > 0);
							_this.promo.cian_standard = (Number(data.cian_standard) > 0);
							_this.promo.cian_bet = Number(data.cian_bet);
							_this.promo.cian_ignore_pkg = (Number(data.cian_ignore_pkg) > 0);
							_this.promo.cian_price = Number(data.cian_price);
							_this.promo.yandex_premium = (Number(data.yandex_premium) > 0);
							_this.promo.yandex_raise = (Number(data.yandex_raise) > 0);
							_this.promo.yandex_promotion = (Number(data.yandex_promotion) > 0);
							_this.promo.yandex_price = Number(data.yandex_price);
							_this.promo.domclick_express = (Number(data.domclick_express) > 0);
							_this.promo.domclick_premium = (Number(data.domclick_premium) > 0);
							_this.promo.domclick_top = (Number(data.domclick_top) > 0);
							_this.promo.domclick_price = Number(data.domclick_price);
						});
					}

					if (process.env.NODE_ENV == "development") {
						console.debug('advertPromotionModal::show.bs.modal', {
							object_id: _this.object_id
						});
					}
					_this.emitter.emit("global.modalShown", modal);
				});
			}
		},
		watch: {
			zipal(value, oldValue) {

				// Включаем бесплатные для размещения площадки по-умолчанию
				if (this.place == 'zipal' && !this.isEmpty(value.destinations)) {
					this.$nextTick(() => {
						this.resetZipalCheckout(value);
					});
				}
			},
			publish(value, oldValue) {
				CommonService.log('debug', 'watch::publish', value);
			},
			no_limit(value, oldValue) {
				if (this.use_advert_budget && this.inArray(this.place, ['avito', 'cian', 'domclick', 'yandex', 'jcat'])) {

					let days = this.days;
					if (value == 1)
						days = null;

					this.getPrice(this.object_id, days, this.place).then(data => {

						if (!this.isEmpty(data))
							this.price = data.price;
						else
							this.price = 0;

						this.total_price = this.price;

						if (!this.isEmpty(data))
							this.budget = data.budget;
						else
							this.budget = 0;

					});
				}
			},
			days(value, oldValue) {
				if (value >= 1 && this.inArray(this.place, ['packages', 'zipal'])) {
					this.getZipal(this.object_id, value, 'zipal').then(data => {
						if (data && !this.isEmpty(data)) {
							this.zipal = data;
							this.zipal.base_price = data.base_price;
							this.zipal.destinations = data.destinations;
							this.resetZipalCheckout(data);
						} else {
							this.zipal.destinations = [];
						}
					});
				}

				if (this.inArray(this.place, ['avito', 'cian', 'domclick', 'yandex', 'jcat'])) {
					this.getPrice(this.object_id, value, this.place).then(data => {

						if (!this.isEmpty(data))
							this.price = data.price;
						else
							this.price = 0;

						this.total_price = this.price;

						if (!this.isEmpty(data))
							this.budget = data.budget;
						else
							this.budget = 0;

					});
				}
			},
		},
		/*watch: {
			promo: {
				handler: function(value, oldValue) {
					this.$nextTick(() => {
						console.log(value);
					});
				},
				deep: true
			},
		}*/
    }
</script>