<template>
	<div class="search position-relative">
		<div class="px-20 pt-20 pb-10 mb-20 bg-white border-bottom border-brd-primary position-relative"
			 style="z-index:1000;">
			<div class="page-header">
				<h1 class="page-title">Поиск</h1>

				<div class="d-flex position-relative">
					<div class="d-flex w-100 text-nowrap-mask scroller scroller-x position-sticky">
						<NavTabs :navItems="this.navTabs"
								 :activeItem="this.filters.operation_type"
								 @onClick="(target_id) => { this.filters.operation_type = target_id.toString() }" />
					</div>
				</div>

				<ToggleSwitcher inputId="viewMode"
								inputName="viewMode"
								:inputOptions="this.viewModeList"
								:inputValue="this.view_mode"
								inputWrap-class="my-3"
								@setValue="this.changeViewMode" />
			</div>

			<Filters :isOpen="this.isFilterOpened"
					 section="search"
					 :filters="this.filters"
					 @filterChange="this.filterChange"
					 @applyFilter="this.doSearch" />

		</div>

		<div v-show="this.pagination !== false" class="col px-20">
			<button type="button" class="btn btn-light rounded-3 w-100 mb-4"
					v-text="(this.isFilterOpened) ? 'Свернуть фильтр' : 'Развернуть фильтр'"
					@click="this.isFilterOpened = !this.isFilterOpened" />
		</div>

		<div v-show="!this.isFilterOpened">

			<ObjectsMap v-if="this.view_mode == 'map' && !this.isEmpty(this.geo_objects)"
						:input-objects="this.geo_objects"
						:inputCoords="this.geo_coords"
						:inputZoom="12"
						@mapInitialized="this.mapLoaded"
						@setDataPoint="(data) => this.getObjects(data, true)"
						class="col px-20 mb-4" />

			<Loader v-else-if="this.view_mode == 'map' && this.isSearchOnMap" />

			<div v-show="this.view_mode != 'map' || (this.view_mode == 'map' && !this.isEmpty(this.geo_objects))"
				 id="objectsContainer"
				 ref="objectsContainer"
				 class="px-0">

				<ObjectsToolbar v-if="this.pagination.total > 0"
								currentSection="search"
								:selectedCount="this.selected_count"
								@checkObjects='this.onClickSelectAll'
								@uncheckObjects='this.onClickResetAll'
								@sendObjectsToPDF='this.addObjectsToPDF'
								@clearAllSelected='this.clearSelectedObjects' />

				<ObjectsList :isAllChecked="this.isAllChecked"
							 :tagsList="this.tagsList"
							 :isGlobalSearch="true"
							 layout="search"
							 :inputFilters="this.filters"
							 @selectedItems="(selected) => this.selected = selected"
							 @objectsLoaded="(data) => this.objectsLoaded(data)"
							 currentSection="search"
							 @applyFilter="this.doSearch"
							 ref="objectsList" />

				<ObjectsToolbar v-if="this.pagination.total > 0"
								currentSection="search"
								:selectedCount="this.selected_count"
								@checkObjects='this.onClickSelectAll'
								@uncheckObjects='this.onClickResetAll'
								@sendObjectsToPDF='this.addObjectsToPDF'
								@clearAllSelected='this.clearSelectedObjects' />
			</div>

			<Pagination v-if="this.pagination.pages > 1"
					:initialPage="this.pagination.current"
					:itemsList="this.pagination.items"
					:labels="{
						first: false,
						last: false,
						previous: false,
						next: false
					}"
					:pageSize="this.pagination.per_page"
					:disableDefaultStyles="true"
					@changePage="(page) => this.loadObjects(page)"
					:maxPages="3"
          ref="pagination"/>

		</div>
	</div>

	<SubmitToVKModal />
	<SubmitComplaintModal />
	<PresentationNav />

</template>

<script>
    import CommonService from "../services/CommonService";
	import NavTabs from "@/components/common/NavTabs";
	import ObjectsList from "@/components/objects/ObjectsList";
	import ToggleSwitcher from "@/components/inputs/ToggleSwitcher";
	import Filters from "@/components/common/Filters";
	import Pagination from "@/components/common/Pagination";
	import ObjectsMap from "@/components/objects/ObjectsMap";
	import Loader from "@/components/common/Loader";
	import ObjectsToolbar from "@/components/objects/ObjectsToolbar";
	import SubmitToVKModal from "@/components/modals/SubmitToVKModal";
	import SubmitComplaintModal from "@/components/modals/SubmitComplaintModal";
	import PresentationNav from "@/components/common/PresentationNav";
	import api from "@/api";

    export default {
        name: "search",
		components: {
			PresentationNav,
			SubmitComplaintModal,
			SubmitToVKModal,
			ObjectsToolbar,
			Loader,
			ObjectsMap,
			Pagination,
			Filters,
			ToggleSwitcher,
			ObjectsList,
			NavTabs,
		},
		data() {
            return {
                title: "Поиск объектов",
				selected: [],
				isAllChecked: false,
				navTabs: {
					'sale': {
						id: 1,
						label: "Продажа",
					},
					'rent': {
						id: 0,
						label: "Аренда",
					},
				},
				viewModeList: [{
					name: 'Списком',
					value: 'list'
				}, {
					name: 'На карте',
					value: 'map'
				}],
				geo_objects: [],
				geo_coords: [59.89444, 30.26417],
				view_mode: 'list',
				filters: {
					'regions': [],
					'object_type': 1,
					'operation_type': 1,
					'commercial_type': [],
					//...
					'price_from': null,
					'price_to': null,
					'rooms_from': null,
					'rooms_to': null,
					'area_full_from': null,
					'area_full_to': null,
          'year_from': null,
          'year_to': null,
					'flat_from': null,
					'flat_to': null,
					'area_live_from': null,
					'area_live_to': null,
					'area_kitchen_from': null,
					'area_kitchen_to': null,
					//...
					'district': null,
					'metro': null,
					'metro_time': null,
					'to_metro': null,
					'address': '',
					//...
					'with_photo': false,
					'order_by_price': false,
					'company_objects': false,
					'with_commission': false,
					//...
					'custom_fields': [],
					//...
					'search_query': '',
					//...
					'search_target': 1,
					//...
					'with_geo': false,
				},
				isFilterOpened: true,
				isSearchOnMap: false,
				pagination: {
          current: 0,
          pages: 0,
          items: [],
          total: 0,
          per_page: 10,
        },
				user_region_id: null,
				user_region_code: null,
				is_restricted_access: false,
				tagsList: [],
            };
        },
        methods: {
			onClickSelectAll(state) {
				if (typeof state != "undefined") {
					if (state === true) {
						for (const [key, value] of Object.entries(this.$refs.objectsList.objectsItems)) {
							this.selected.push(value.id);
						}
						this.isAllChecked = true;
					} else {
						this.selected = [];
						this.isAllChecked = false;
					}
				} else {
					this.isAllChecked = !this.isAllChecked;
				}

				this.$refs.objectsList.selectedItems = this.selected;

				CommonService.log('log', 'onClickSelectAll()', this.selected);

			},
			onClickResetAll() {
				this.selected = [];
				this.$refs.objectsList.selectedItems = this.selected;
				this.isAllChecked = false;

				CommonService.log('log', 'onClickResetAll()', this.selected);
			},
			addObjectsToPDF() {

				this.selected = this.$refs.objectsList.selectedItems;
				CommonService.log('log', 'addObjectsToPDF()', this.selected);

				this.$store.commit('setObjectsToPDF', this.selected);
			},
			clearSelectedObjects() {

				this.selected = []
				this.$refs.objectsList.selectedItems = this.selected;
				CommonService.log('log', 'clearSelectedObjects()', this.selected);

			},
			filterChange(filters) {
				console.log(filters, 'gilteredas');
				filters = CommonService.removeEmpty(filters);
				this.filters = [this.filters, ...filters];
			},
			doSearch(filters) {

				this.isFilterOpened = false;

				if (typeof filters !== "undefined")
					this.filters = filters;

				CommonService.log('log', 'doSearch()', this.filters);

				this.isFilterOpened = false;
				if (this.view_mode == 'map')
					this.isSearchOnMap = true;

				this.$refs.objectsList.loadObjects(1, 10, 'search', this.filters, null, true);

			},
			loadObjects(page, per_page, section, filters, search) {

				if (!page)
					page = 1;

				if (!per_page)
					per_page = 10;

				if (!filters)
					filters = this.filters;

				if (!search)
					search = null;

				if (section && typeof section !== "undefined")
					this.section = section;

				CommonService.log('log', 'loadObjects()', [page, per_page, 'search']);

				this.$refs.objectsList.loadObjects(page, per_page, 'search', filters, search);

			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			getTags() {
				return api.get('/common/tags').then((response) => {

					CommonService.log('debug', 'getTags()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								id: option.id,
								name: option.name,
								isChecked: false
							});
						});

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getTags()::axios', error);

				});
			},
			scrollToEl(ref) {
				const element = ((typeof ref === 'object') ? ref : (this.$refs[ref] || document.getElementById(ref) || this.$el));
				this.$smoothScroll({
					scrollTo: element,
					hash: '#'+ref
				});
			},
			scrollToContainer() {
				const headerHeight = 100;
				const position = this.$refs.objectsContainer.getBoundingClientRect().top;
				const offset = position + window.pageYOffset - headerHeight;
				this.$nextTick(() => {
					window.scrollTo({
						top: offset,
						behavior: "smooth"
					});
				});
			},
			objectsLoaded(data) {

				CommonService.log('log', 'objectsLoaded()', data);

				if (!this.isEmpty(data.objects)) {
					if (!this.isEmpty(this.filters)) {
						if (this.filters['with_geo']) {
							this.geo_objects = data.objects;
							this.filters['with_geo'] = false;
							this.$refs.objectsList.loadObjects(1, 10, 'search', this.filters, '', true);

							/*let objects_ids = this.geo_objects.map((object) => {
								return object.id;
							});

							this.getObjects({
								objects: objects_ids
							});*/
						} else if (!this.filters['with_geo'] && !this.isEmpty(this.filters['objects_ids'])) {
							this.scrollToContainer();
						}
					}
				}

        if (!this.isEmpty(data.pagination))
          this.pagination = data.pagination;

        if (this.$refs.pagination) {
          this.$refs.pagination.updateProps(this.pagination);
          this.$refs.pagination.setPage(this.pagination.current, false);
        }
			},
			mapLoaded(data) {

				CommonService.log('log', 'mapLoaded()', data);

				if (this.view_mode == 'map')
					this.isSearchOnMap = false;

			},
			changeViewMode(mode) {
				this.view_mode = mode;
				this.isSearchOnMap = false;

				if (this.view_mode == 'map') {
					this.geo_objects = [];
					this.filters['with_geo'] = true;
				} else {
					this.filters['with_geo'] = false;
				}

				//this.pagination = false;
				this.isFilterOpened = true;
				this.$refs.objectsList.resetData();

			},
			getObjects(data, from_map) {

				if (typeof from_map == "undefined")
					from_map = false;

				if (!this.isEmpty(data.coords))
					this.geo_coords = data.coords;

				if (!this.isEmpty(data.objects)) {

					let filters = this.filters;
					filters['with_geo'] = false;

					if (from_map) {// загрузка объектов из кластера карты
						if (data.objects.length > 0)
							filters['objects_ids'] = data.objects;
						else
							return false;
					}

					CommonService.log('debug', 'getObjects()', filters);

					this.isFilterOpened = false;
					this.$refs.objectsList.loadObjects(1, 10, 'search', filters, '', true);
				}
			}
        },
		mounted() {

            CommonService.setPageTitle(this.title);

			this.selected = [];
			this.selected.length = 0;

			if (!this.isEmpty(this.$store.getters.tagsList)) {
				let list = [];
				Object.entries(this.$store.getters.tagsList).forEach(([key, option]) => {
					list.push({
						id: option.value,
						name: option.name,
						isChecked: false
					});
				});
				this.tagsList = list;
			} else {
				this.getTags().then(data => {
					this.tagsList = data;
				});
			}

			if (!this.isEmpty(this.$store.getters.userInfo)) {
				if (typeof this.$store.getters.userInfo.region_id !== "undefined") {
					this.user_region_id = this.$store.getters.userInfo.region_id;
				}
			}

			if (!this.isEmpty(this.$store.getters.userInfo)) {
				if (typeof this.$store.getters.userInfo.region.code !== "undefined") {
					this.user_region_code = this.$store.getters.userInfo.region.code;
				}
			}

			if (!this.isEmpty(this.$store.getters.userInfo.restricted_access))
				this.is_restricted_access = this.$store.getters.userInfo.restricted_access;

			this.changeViewMode(this.view_mode);

        },
		computed: {
			selected_count() {
				return this.selected.length;
			},
		},
    };
</script>