<template>
	<div class="modal fade" id="advertDaysModal" ref="advertDaysModal" tabindex="-1" aria-labelledby="Выбор срока размещения" style="z-index: 9999;">
		<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-20">
				<div class="modal-header d-flex align-items-center mb-20 border-0 p-0">
					
					<span v-if="this.service == 'packages'" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Пакетное размещение</span>
					<span v-else-if="this.service == 'zipal'" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Размещение с отчётами</span>
					<span v-else-if="this.service == 'jcat'" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Срок размещения на JCat</span>
					<span v-else-if="this.service == 'avito'" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Срок размещения на Avito</span>
					<span v-else-if="this.service == 'cian'" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Срок размещения на ЦИАН</span>
					<span v-else-if="this.service == 'domclick'" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Срок размещения на ДомКлик</span>
					<span v-else-if="this.service == 'yandex'" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Срок размещения на Я.Недвижимость</span>
					<span v-else class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Срок размещения</span>
					
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body px-2">
					<div v-if="this.inArray(this.service, ['free', 'jcat', 'avito', 'cian', 'domclick', 'yandex'])" class="row-fluid">
						<div class="col">
							{{ this.days_comp }}
							<InputNumber inputLabel="Количество дней размещения:"
										 :inputValue="this.days_comp"
										 inputMin="1"
										 inputStep="1"
										 inputMax="365"
										 inputClass="rounded-1 mb-10"
										 inputWrapClass="flex-column mb-3"
										 :inputDisabled="this.no_limit"
										 @setValue="(value) => { (value > 1) ? this.days = value : this.days = 1 }" />
						</div>
						<div v-if="!this.use_advert_budget" class="col">
							<Switcher inputLabel="Без ограничения количества дней"
									  :inputValue="this.no_limit"
									  inputClass="ps-0 rounded-1 mb-10"
									  inputWrapClass="flex-column mb-2"
									  @setValue="(value) => this.no_limit = !Boolean(this.no_limit)" />
						</div>
					</div>
					<div v-else-if="this.service == 'packages'" class="row-fluid">
						<div v-if="!this.isEmpty(this.packages)" class="col position-relative">
							<p>
								<img :src="require('@/assets/icons/info-blue-icon.svg')" alt="Info" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: -3px;">
								Публикация будет выполнена в течение 10-15 минут.
							</p>
							<ul class="list-unstyled">
								<li v-for="(item, index) of this.packages" :key="index" class="d-inline-flex flex-column mb-2">
									<Switcher :inputId="'pack_'+item.id+'_'+this.object_id"
											  :inputName="'pack_'+item.id+'_'+this.object_id"
											  :inputLabel="item.name + ' (' + item.days_count_string + ')'"
											  :inputValue="(item.is_active) ? 1 : 0"
											  inputClass="px-0"
											  :inputSubLabel="(item.is_error) ? item.error_text : (!this.isEmpty(item.status_text)) ? item.status_text : null"
											  :inputSubLabelClass="['text-nowrap-mask', {'text-success': !item.is_error && item.is_active, 'text-warning': !item.is_error && !item.is_active, 'text-danger': item.is_error || item.left_days <=5 }]"
											  inputWrapClass="flex-column mb-3"
											  @setValue="(value) => this.addRemovePublish(item.id, item.days_count, value)" />
									<p v-if="!this.isEmpty(item.targets)" class="fs-5">{{ this.splitJoin(item.targets) }}</p>
									<p v-if="!this.isEmpty(item.error)" class="alert alert-danger">{{ item.error }}</p>
									<ul v-if="!this.isEmpty(this.publish[item.id])" class="list-group pb-2 mb-3 border-bottom">
										<li class="list-group-item border-0 px-0 py-0">
											<InputNumber :inputId="'days_count_'+item.id"
														 inputLabel="Дней размещения:"
														 :inputName="'days_count_'+item.id"
														 :ref="'days_count_'+item.id"
														 inputClass="rounded-1 mb-10"
														 inputMin="1"
														 inputMax="100"
														 inputGroupClass="col w-50"
														 inputWrapClass="row row-cols-2 flex-nowrap align-items-center"
														 inputLabelClass="col w-50 text-dark-gray"
														 :inputValue="this.publish[item.id].days_count"
														 @setValue="(value) => this.changeDaysCount(item.id, value)" />
										</li>
										<li class="list-group-item border-0 px-0 py-1">
											Базовая цена: {{ this.publish[item.id].base_price ?? 0 }} ₽
										</li>
										<li class="list-group-item border-0 px-0 py-1">
											Зипал: {{ this.publish[item.id].zipal_price ?? 0 }} ₽
										</li>
										<li class="list-group-item border-0 px-0 py-1">
											Доп. площадки: {{ this.publish[item.id].addition_price ?? 0 }} ₽
										</li>
										<li class="list-group-item border-0 px-0 py-1">
											Итого: {{ this.publish[item.id].total_price ?? 0 }} ₽
										</li>
									</ul>
								</li>
							</ul>
							<div v-if="!this.isEmpty(this.publish) && this.publish_packages_total > 0" class="d-flex flex-fill flex-column w-100">
								<p class="d-inline-flex"><b>Итого:</b> {{ this.publish_packages_total }} ₽</p>
								<div v-if="this.publish_packages_total > this.$store.getters.userInfo.services.balance"
								class="alert alert-danger mt-3">
									Внимание! Недостаточно средств на балансе пользователя (агентства) для осуществления публикации.
								</div>
							</div>
						</div>
						<div v-else-if="this.packages == false" class="col">
							<p class="pb-0">
								<img :src="require('@/assets/icons/warning.svg')" alt="Warning" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: -3px;">
								Подходящих по параметрам пакетов не найдено.
							</p>
						</div>
						<div v-else class="col">
							<Loader />
						</div>
					</div>
					<div v-else-if="this.service == 'zipal'" class="row-fluid">
						<div v-if="!this.isEmpty(this.zipal)" class="col">

							<p>
								<img :src="require('@/assets/icons/info-blue-icon.svg')" alt="Info" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: -3px;">
								Изменения будут отражены в течение 10-15 минут.
							</p>

							<InputNumber inputLabel="Дней размещения:"
										 :inputValue="this.days_comp"
										 inputMin="1"
										 inputStep="1"
										 inputMax="365"
										 inputClass="rounded-1 mb-10"
										 inputWrapClass="flex-column mb-3"
										 @setValue="(value) => { (value > 1) ? this.days = value : this.days = 1 }" />

							<p><b>Базовая цена:</b> {{ this.base_price }} ₽</p>

							<p><b>Итого:</b> {{ this.total_amount }} ₽</p>

							<div class="position-relative vh-50 overflow-auto" :class="{'opacity-50': this.is_processed}">
								<div v-for="(label, name) of this.zipal.groups" :key="name">
									<div v-if="!this.isEmpty(this.zipal.destinations[name])" class="d-flex flex-column">
										<div class="bg-white sticky-top py-2">
											<Switcher :inputId="name"
													  :inputName="name"
													  :ref="'dest_' + name"
													  :inputLabel="label"
													  :inputValue="this.isActiveDestination(name)"
													  inputClass="px-0 mb-3"
													  inputWrapClass="flex-column mb-2"
													  @setValue="(value) => this.addRemoveDestination(name)" />
										</div>
										<ul v-if="!this.is_processed" class="d-flex flex-column w-100 list-unstyled mb-2">
											<li v-for="(destination, index) of this.zipal.destinations[name]" :key="index">
												{{ this.prices[name + '_' + destination.type] }}
												<InputRadio :inputLabel="
																!this.isEmpty(destination.price) ?
																destination.description + ' (' + destination.price + ' ₽)' :
																destination.description
															"
															:inputName="name"
															:inputId="name + '_' + destination.type"
															:inputDisabled="!this.isActiveDestination(name)"
															inputWrapClass="ms-1 py-2"
															:inputValue="this.isActiveDestinationSet(name, destination.type)"
															@setValue="(value) => this.addRemoveDestinationSet(name, destination.type, destination.price)" />

											</li>
										</ul>
									</div>
								</div>
							</div>

						</div>
						<div v-else-if="this.zipal == false" class="col">
							<p>
								<img :src="require('@/assets/icons/info-blue-icon.svg')" alt="Warning" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: -3px;">
								При помощи данной функции Вы сможете выставить свое объявление на 80 досок
								по недвижимости и получить отчет в виде ссылок на объявление.
							</p>
							<p class="pb-0">
								<img :src="require('@/assets/icons/warning.svg')" alt="Warning" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: -3px;">
								Для настройки данной функции свяжитесь с нашей поддержкой по тел. <a href="tel:88129815617">8 812 981-56-17</a>
							</p>
						</div>
						<div v-else class="col">
							<Loader />
						</div>
					</div>
					<div v-if="this.inArray(this.service, ['avito', 'cian', 'domclick', 'yandex', 'jcat'])" class="row-fluid">
						<dl class="dl-horizontal mt-3" :class="{ 'pt-2 border-top': !this.use_advert_budget }">
							<dt>Стоимость размещения:</dt>
							<dd>{{ this.total_price }} руб.</dd>
							<dt v-if="this.use_advert_budget">Доступный бюджет:</dt>
							<dd v-if="this.use_advert_budget && (this.is_agency || this.is_unlimited)">не ограничен</dd>
							<dd v-else-if="this.use_advert_budget" :class="{'is_hight': (this.budget), 'is_lower': (!this.budget)}">{{ this.formatPrice(this.budget) }} руб.</dd>
						</dl>
						<div v-if="this.use_advert_budget" class="form-text d-flex fs-5 mb-3">
							<img :src="require('@/assets/icons/info.svg')" alt="Info" class="d-inline-flex ms-0 me-2" width="20" height="20" style="margin-top: -2px;">
							Доступный бюджет ответственного по объекту для {{ this.service_name }} из расчёта стоимости размещения за каждый период (30 дней).
						</div>
					</div>
				</div>
				<div v-if="(this.service !== 'zipal' && this.service !== 'packages') || (this.service == 'zipal' && !this.isEmpty(this.zipal)) || (this.service == 'packages' && !this.isEmpty(this.packages))" class="modal-footer border-0 p-0">
					<button type="button"
							class="btn btn-secondary col me-2 rounded-3 p-3 fs-3"
							data-bs-dismiss="modal">
						Отмена
					</button>
					<button type="button"
							class="btn btn-primary col ms-2 rounded-3 p-3 text-white fs-3"
							data-bs-dismiss="modal"
							:disabled="!this.validate"
							@click="this.addObjectAdverts()">
						Разместить
					</button>
				</div>
				<div v-else class="modal-footer border-0 p-0">
					<button type="button"
							class="btn btn-primary col-12 rounded-3 p-3 text-white fs-3"
							data-bs-dismiss="modal">
						Ok
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import Switcher from "@/components/inputs/Switcher";
	import InputNumber from "@/components/inputs/InputNumber";
	import api from "@/api";
	import CommonService from "@/services/CommonService";
	import InputCheckbox from "@/components/inputs/InputCheckbox";
	import Loader from "@/components/common/Loader";
	import InputRadio from "@/components/inputs/InputRadio";

    export default {
        name: 'AdvertDaysModal',
		props: {
			objectId: { type: Number },
			advertService: { type: String },
			isModeration: { type: Boolean },
		},
        data() {
			return {
				service: null,
				object_id: null,
				days: 30,
				checkout: 0,
				no_limit: false,
				zipal: {
					destinations: [],
				},
				packages: {},
				destinations: {},
				publish: [],
				price: 0,
				total_price: 0,
				budget: 0,
				is_processed: false,
				is_switcher: false,
			}
		},
        components: {
			InputRadio,
			Loader,
			InputNumber,
			Switcher,
        },
		methods: {
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			formatPrice(value, currency, sign) {

				let price = CommonService.formatPrice(value ?? '', currency, sign);
				if (!isNaN(price))
					return price;
				else
					return value;

			},
			splitJoin(data) {
				return CommonService.splitJoin(data, ', ');
			},
			resetData() {
				this.service = null;
				this.days = 30;
				this.checkout = 0;
				this.no_limit = 0;
				this.publish = [];
				this.packages = [];
				this.zipal = [];
				this.is_processed = false;
			},
			resetZipalCheckout(data) {
				this.checkout = 0;
				this.publish = [];
				Object.entries(data.destinations).forEach(destination => {

					let name = destination[0];
					Object.values(data.destinations[name]).forEach((item, index) => {

						if (item.is_free) {
							this.addRemoveDestination(name);
							if (!this.isEmpty(item.type)) {
								this.addRemoveDestinationSet(name, item.type, 0.00);
							}

						}

						item.price = (!this.isEmpty(item.price)) ? parseFloat(item.price) : null;
						item.description = (item.is_feed) ? 'Фид - ' + item.description : item.description;
					});
				});
			},
			addRemovePublish(package_id, days_count, value) {
				if (value) {
					this.publish[package_id] = {
						package_id: package_id,
						days_count: days_count ?? 1,
					};
					this.getZipalPackage(this.object_id, package_id, days_count ?? 1);
				} else {
					let _this = this;
					api.put('/adverts/set', {
						service: _this.service,
						object_id: _this.object_id,
						is_moderation: _this.is_moderation,
						unpublish: {
							packages: {
								package_id: package_id
							}
						},
						current: true
					}).then((response) => {
						CommonService.log('debug', 'addRemovePublish()::axios', {response});

						if (response.status == 200 && response.data.success) {

							let packages = response.data.packages;
							_this.packages.forEach(pack => {
								if (pack.id == package_id) {
									pack.status_text = packages[package_id].status_text;
									pack.is_active = packages[package_id].is_active;
									setTimeout(() => {
										pack.status_text = null;
									}, 3000);
								}
							});

							let index = this.publish.findIndex((value, key) => {
								return key === package_id;
							});

							if (index !== -1)
								_this.publish.splice(index, 1);

							if (!this.is_moderation)
								_this.$emit('updateObject', _this.object_id);

						}
					}).catch(function (error) {

						CommonService.log('error', 'addObjectAdverts()::axios', error);

					});
				}

				CommonService.log('debug', 'addRemovePublish()', {package_id, days_count, value, packages: this.publish});

			},
			changeDaysCount(package_id, value) {
				if (value > 1)
					this.publish[package_id].days_count = value
				else
					this.publish[package_id].days_count = 1

				let days_count = this.publish[package_id].days_count
				this.getZipalPackage(this.object_id, package_id, days_count);
			},
			isActiveDestination(name) {
				//return this.publish.includes(name);
				return (typeof (this.publish[name]) !== "undefined");
			},
			isActiveDestinationSet(name, type) {
				/*if (this.publish.includes(name)) {
					let index = this.publish[name].indexOf(type);
					if (index !== -1) {
						return true;
					}
				}*/

				if (this.isActiveDestination(name)) {
					let index = this.publish[name].findIndex((value, key) => {
						return value === type;
					});

					return (index !== -1);
				}

				return false;
			},
			addRemoveDestination(name) {
				if (!Array.isArray(this.publish))
					this.publish = new Array();

				if (!this.publish.includes(name)) {
						this.publish[name] = new Array ();
					} else {
						let index = this.publish.indexOf(name);
						if (index !== -1) {
							let price = 0;
							this.publish[name].forEach(item => {
								if (this.publish[name].indexOf(item.type) !== -1) {
									price = price + item.price;
								}
							});
							this.checkout = this.checkout - price;
							this.publish.splice(index, 1);
						}
					}

				CommonService.log('debug', 'addRemoveDestination()', this.publish);

			},
			addRemoveDestinationSet(name, type, price) {


				if (typeof (this.publish[name]) !== "object")
					this.publish[name] = [];

				if (!this.publish[name].includes(type)) {
					this.publish[name].push(type);
					this.checkout = this.checkout + price;
				} else {
					let index = this.publish[name].indexOf(type);
					if (index !== -1) {
						this.publish[name].splice(index, 1);
						this.checkout = this.checkout - price;
					}
				}

				console.log({name, type, price, checkout: this.checkout});

				let destinations = this.publish;
				this.destinations = destinations;
				this.publish = this.destinations;

				CommonService.log('debug', 'addRemoveDestinationSet()', this.publish);

			},
			getPrice(object_id, days, service) {

				this.is_processed = true;

				return api.get('/adverts/price', {
					params: {
						object_id: object_id,
						service: service,
						days_num: days,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getPrices()::axios', {response: response.data});

					setTimeout(() => { this.is_processed = false}, 600);

					if (response.status == 200 && response.data.success) {
						if (!this.isEmpty(response.data)) {
							return response.data;
						}
					}

					return false;
				}).catch(function (error) {

					this.is_processed = false;

					CommonService.log('error', 'getPrices()::axios', error);

					return false;
				});
			},
			getZipal(object_id, days_num, service) {

				this.is_processed = true;

				let url = '/adverts/zipal/prices';
				if (service == 'packages')
					url = '/adverts/zipal/packages';

				return api.get(url, {
					params: {
						object_id: object_id,
						days_num: days_num
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getZipal()::axios', {response: response.data});

					setTimeout(() => { this.is_processed = false}, 600);

					if (response.status == 200 && response.data.success) {
						if (!this.isEmpty(response.data.packages)) {
							if (service == 'zipal') {
								return response.data.packages[0];
							} else {
								return response.data.packages;
							}
						}
					}

					return false;
				}).catch(function (error) {

					this.is_processed = false;

					CommonService.log('error', 'getZipal()::axios', error);

					return false;
				});
			},
			getZipalPackage(object_id, package_id, days_num) {

				this.is_processed = true;

				return api.get('/adverts/zipal/packages', {
					params: {
						object_id: object_id,
						package_id: package_id,
						days_num: days_num
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getZipalPackage()::axios', {response: response.data});

					this.is_processed = false;

					if (response.status == 200 && response.data.success) {
						if (!this.isEmpty(response.data.package)) {
							let packg = response.data.package;

							/*let total_price = Math.round((parseFloat(packg.total_price) * 100) / 100);
							let zipal_price = Math.round((parseFloat(packg.zipal_price) * 100) / 100);
							let addition_price = Math.round((parseFloat(packg.addition_price) * 100) / 100);*/

							this.publish[package_id].days_count = parseInt(packg.days_count);
							this.publish[package_id].base_price = parseFloat(packg.base_price);
							this.publish[package_id].zipal_price = parseFloat(packg.zipal_price);
							this.publish[package_id].addition_price = parseFloat(packg.addition_price);
							this.publish[package_id].total_price = parseFloat(packg.zipal_price) + parseFloat(packg.addition_price);

							return packg;
						}
					}

					return false;
				}).catch(function (error) {

					this.is_processed = false;

					CommonService.log('error', 'getZipalPackage()::axios', error);

					return false;
				});
			},
			addObjectAdverts(state) {

				if (typeof state == "undefined")
					state = false

				let service = this.service;
				let object_id = this.object_id;
				let is_moderation = this.is_moderation;
				let use_advert_budget = this.use_advert_budget;
				CommonService.log('info', 'addRemoveObjectAdverts()', {object_id: object_id, service: service, is_moderation: is_moderation, is_advert_budget: use_advert_budget, state: state});
				this.$emit('setObjectAdvertState', {object_id: object_id, service: service, is_moderation: is_moderation, is_advert_budget: use_advert_budget, state: !state});

				let data = {
					service: service,
					object_id: object_id,
					is_moderation: is_moderation,
					is_advert_budget: use_advert_budget,
					current: state,
					days: (!this.no_limit) ? this.days : null,
				}

				CommonService.log('debug', data);

				if (this.validate && !this.isEmpty(service) && !this.isEmpty(object_id)) {

					if (this.inArray(this.service, ['packages', 'zipal'])) {
						data.publish = (service == 'packages') ? Object.values(this.publish) : Object.entries(this.publish);
						data.price = parseFloat(this.total_amount);
					}

					let _this = this;
					api.put('/adverts/set', data).then((response) => {
						CommonService.log('debug', 'addObjectAdverts()::axios', {response});

						if (response.status == 200 && response.data.success) {
							_this.resetData();
							this.$emit('setObjectAdvertState', {object_id: object_id, service: service, state: response.data.state});
							if (!is_moderation) {
                this.$emit('updateObject', response.data.object_id);
              }
						}
					}).catch(function (error) {

						CommonService.log('error', 'addObjectAdverts()::axios', error);

					});
				}
			},
		},
		mounted() {
			var _this = this;
			
			var modal = this.$refs.advertDaysModal;
			if (modal && typeof modal !== "undefined") {
				
				modal.addEventListener('hidden.bs.modal', function (event) {
					var object_id = _this.object_id;
					console.log(_this.is_switcher);
					_this.service = null;
					_this.object_id = null;
					_this.days = 30;
					_this.no_limit = false;
					_this.publish = {
						packages: [],
						destinations: [],
					};
					if(_this.is_switcher){
						_this.$emit('updateObject', object_id);
						_this.is_switcher = false;
					}
					_this.emitter.emit("global.modalClose", modal);
					_this.$emit('modalClosed');
					
				});

				modal.addEventListener('show.bs.modal', function (event) {
					_this.is_switcher = false;
					let target = event.relatedTarget

					let service = target.getAttribute('data-bs-service');
					if (typeof service !== "undefined")
						_this.service = service;

					let object_id = target.getAttribute('data-bs-object-id');
					if (typeof object_id !== "undefined")
						_this.object_id = Number(object_id);

					let is_switcher = target.getAttribute('data-bs-isswitcher');
					if (typeof is_switcher !== "undefined")
						_this.is_switcher = true;
					
					

					if (_this.service == 'packages') {
						_this.days = 30;
						_this.getZipal(object_id, _this.days, 'packages').then(data => {
							if (data && !_this.isEmpty(data))
								_this.packages = data;
							else
								_this.packages = [];
						});
					} else if (_this.service == 'zipal') {
						_this.days = 3;
						_this.getZipal(object_id, _this.days, 'zipal').then(data => {
							if (data && !_this.isEmpty(data))
								_this.zipal = data;
							else
								_this.zipal = [];
						});
					} else {
						_this.getPrice(object_id, _this.days, _this.service).then(data => {

							if (!_this.isEmpty(data))
								_this.price = data.price;
							else
								_this.price = 0;

							_this.total_price = _this.price;

							if (!_this.isEmpty(data))
								_this.budget = data.budget;
							else
								_this.budget = 0;

						});
					}

					if (process.env.NODE_ENV == "development") {
						console.debug('advertDaysModal::show.bs.modal', {
							service: _this.service,
							object_id: _this.object_id
						});
					}
					_this.emitter.emit("global.modalShown", modal);
				});
			}
		},
		watch: {
			zipal(value, oldValue) {

				// Включаем бесплатные для размещения площадки по-умолчанию
				if (this.service == 'zipal' && !this.isEmpty(value.destinations)) {
					this.$nextTick(() => {
						this.resetZipalCheckout(value);
					});
				}
			},
			publish(value, oldValue) {
				CommonService.log('debug', 'watch::publish', value);
			},
			no_limit(value, oldValue) {
				if (this.use_advert_budget && this.inArray(this.service, ['avito', 'cian', 'domclick', 'yandex', 'jcat'])) {

					let days = this.days;
					if (value == 1)
						days = null;

					this.getPrice(this.object_id, days, this.service).then(data => {

						if (!this.isEmpty(data))
							this.price = data.price;
						else
							this.price = 0;

						this.total_price = this.price;

						if (!this.isEmpty(data))
							this.budget = data.budget;
						else
							this.budget = 0;

					});
				}
			},
			days(value, oldValue) {
				if (value >= 1 && this.inArray(this.service, ['packages', 'zipal'])) {
					this.getZipal(this.object_id, value, 'zipal').then(data => {
						if (data && !this.isEmpty(data)) {
							this.zipal = data;
							this.zipal.base_price = data.base_price;
							this.zipal.destinations = data.destinations;
							this.resetZipalCheckout(data);
						} else {
							this.zipal.destinations = [];
						}
					});
				}

				if (this.inArray(this.service, ['avito', 'cian', 'domclick', 'yandex', 'jcat'])) {
					this.getPrice(this.object_id, value, this.service).then(data => {

						if (!this.isEmpty(data))
							this.price = data.price;
						else
							this.price = 0;

						this.total_price = this.price;

						if (!this.isEmpty(data))
							this.budget = data.budget;
						else
							this.budget = 0;

					});
				}
			},
		},
		computed: {
			validate() {
				let is_valid = true;
				if (this.service == 'packages') {
					if (!this.isEmpty(this.publish)) {
						if (this.publish_packages_total > this.$store.getters.userInfo.services.balance) {
							is_valid = false;
						}
					} else {
						is_valid = false;
					}
				} else if (this.service == 'zipal') {
					/*if (this.isEmpty(this.publish)) {
						is_valid = false;
					}*/
					is_valid = true;
				} else if (this.inArray(this.service, ['avito', 'cian', 'domclick', 'yandex', 'jcat'])) {
					if (!this.is_agency) {
						if (this.use_advert_budget && !this.no_limit && (!this.is_unlimited))
							is_valid = (this.total_price <= this.budget);
					}
				}

				return is_valid;
			},
			days_comp() {

				if (this.days < 1)
					return 1;

				return this.days;
			},
			is_moderation() {
				return this.$store.getters.userInfo.user_can.add_to_moderation;
			},
			base_price: {
				get() {
					if (!this.isEmpty(this.zipal.base_price)) {
						return parseFloat(this.zipal.base_price).toFixed(2) ?? 0.00;
					}

					return 0.00;
				},
				set(value) {
					value = parseFloat(value);
				}
			},
			total_amount() {
				return (parseFloat(this.checkout) + parseFloat(this.base_price)).toFixed(2);
			},
			prices() {
				let prices = [];

				if (Array.isArray(this.zipal.destinations)) {
					this.zipal.destinations.forEach((destination, name) => {
						destination.forEach(item => {
							prices[name + '_' + item.type] = parseFloat(item.price);
						});
					});
				}

				return prices;
			},
			service_name() {

				switch(this.service) {

					case 'avito':
						return "Avito";

					case 'cian':
						return "ЦИАН";

					case 'domclick':
						return "ДомКлик";

					case 'yandex':
						return "Я.Недвижимость";

					case 'jcat':
						return "JCat";

					case 'zipal':
						return "Zipal";

				}

				return null;
			},
			user() {
				return this.$store.getters.userInfo;
			},
			is_agency() {
				return this.user.is_agency;
			},
			use_advert_budget() {
				return this.user.services.use_advert_budget;
			},
			is_unlimited() {

				let advert_budget = this.user.services.advert_budget;
				switch(this.service) {

					case 'avito':
						return advert_budget.avito.is_unlimited;

					case 'cian':
						return advert_budget.cian.is_unlimited;

					case 'domclick':
						return advert_budget.domclick.is_unlimited;

					case 'yandex':
						return advert_budget.yandex.is_unlimited;

					case 'jcat':
						return advert_budget.jcat.is_unlimited;

					case 'zipal':
						return advert_budget.zipal.is_unlimited;

				}

				return false;
			},
			publish_packages_total() {
				let total = 0;
				if (Array.isArray(this.publish)) {
					this.publish.forEach(packg => {
						total = total + parseFloat(packg.total_price);
					});
				}
				return parseFloat(total);
			},
		},
    }
</script>