<template>
	<div>
		<div class="modal fade" id="orderEgrnModal" ref="orderEgrnModal" tabindex="-1" aria-labelledby="Заказ выписки из ЕГРН" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content p-3">
					<div class="modal-header d-flex mb-20 border-0 p-1">
						<span class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
							Заказ выписки из ЕГРН
						</span>
						<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body d-flex flex-column p-1">
						<span class="mb-3 text-secondary">Доступно выписок: </span>
						<Switcher inputId="simple_type"
								  inputName="simple_type"
								  ref="simple_type"
								  inputLabel="Выписка из ЕГРН"
								  :inputValue="this.simple_type"
								  :inputDisabled="this.isEmpty(this.egrn_count)"
								  inputClass="px-0 mb-3"
								  inputWrapClass="flex-column mb-3"
								  @setValue="(value) => this.simple_type = value" />
						<Switcher inputId="move_owner_type"
								  inputName="move_owner_type"
								  ref="move_owner_type"
								  inputLabel="Выписка из ЕГРН о переходе прав на объект недвижимости"
								  :inputValue="this.move_owner_type"
								  :inputDisabled="this.isEmpty(this.egrn_count)"
								  inputClass="px-0 mb-3"
								  inputWrapClass="flex-column mb-3"
								  @setValue="(value) => this.move_owner_type = value" />
					</div>
					<div class="modal-footer border-0 p-1">
						<button type="button"
								class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
								:disabled="!this.object_id"
								@click="getOrderEgrn()" >
							Заказать
						</button>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="orderEgrnSuccessModal" ref="orderEgrnSuccessModal" tabindex="-1" aria-labelledby="Выписка успешно заказана" aria-hidden="true">
			<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content p-3">
					<div class="modal-header d-flex flex-column border-0 p-1">
						<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body d-flex flex-column align-items-center p-1">
						<span class="d-inline-flex bg-primary rounded-circle p-4" style="width: 5rem; height: 5rem;">
							<img :src="require('@/assets/icons/checked.svg')" alt="..." width="32" height="32">
						</span>
						<span class="modal-title fs-2 fw-semi font-semi lh-1 mt-3 mb-2">
							Спасибо!
						</span>
						<span v-if="this.isEmpty(this.results)"
							  class="mb-3 text-center text-secondary mt-3">
							Выписка успешно заказана.
						</span>
						<span v-else-if="!this.isEmpty(this.results)">
							<span class="mb-3 text-center text-secondary mt-3"
								  v-for="(result, index) in this.results"
								  :key="index"
								  v-text="result" />
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class="modal fade" id="orderEgrnErrorModal" ref="orderEgrnErrorModal" tabindex="-1" aria-labelledby="Ошибка заказа выписки" aria-hidden="true">
			<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
				<div class="modal-content p-3">
					<div class="modal-header d-flex flex-column border-0 p-1">
						<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body d-flex flex-column align-items-center p-1">
						<span class="d-inline-flex bg-danger rounded-circle p-4" style="width: 5rem; height: 5rem;">
							<img :src="require('@/assets/icons/removed.svg')" alt="..." width="32" height="32">
						</span>
						<span class="modal-title fs-2 fw-semi font-semi lh-1 mt-3 mb-2">
							Ой :(
						</span>
						<span v-if="this.isEmpty(this.errors)"
							  class="mb-3 text-center text-secondary mt-3">
							Попробуйте позже или обратитесь в поддержку.
						</span>
						<span v-else-if="!this.isEmpty(this.errors)">
							<span class="mb-3 text-center text-secondary mt-3"
								  v-for="(error, index) in this.errors"
								  :key="index"
								  v-text="error" />
						</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import * as bootstrap from 'bootstrap';
import api from "@/api";
import Switcher from "@/components/inputs/Switcher";
import CommonService from "@/services/CommonService";

export default {
	name: 'OrderEgrnModal',
	components: { Switcher },
	data() {
		return {
			object_id: null,
			simple_type: 0,
			move_owner_type: 0,
			results: [],
			errors: [],
		}
	},

	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		getOrderEgrn: function () {

			let orderEgrnEl = this.$refs.orderEgrnModal;
			let orderEgrnModal = bootstrap.Modal.getInstance(orderEgrnEl);
			orderEgrnModal.hide();

			let orderEgrnSuccessEl = this.$refs.orderEgrnSuccessModal;
			let orderEgrnSuccessModal = new bootstrap.Modal(orderEgrnSuccessEl);

			let orderEgrnErrorEl = this.$refs.orderEgrnErrorModal;
			let orderEgrnErrorModal = new bootstrap.Modal(orderEgrnErrorEl);

			api.post('/object/egrn', {
				object_id: this.object_id,
				simple_type: this.simple_type,
				move_owner_type: this.move_owner_type,
			})
			.then((response) => {

				CommonService.log('debug', 'getOrderEgrn()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {
					if (!this.isEmpty(response.data.errors)) {
						this.errors = response.data.errors;
						orderEgrnErrorModal.show();
					} else if (!this.isEmpty(response.data.results)) {
						this.results = response.data.results;
						orderEgrnSuccessModal.show();
					}

					if (typeof response.data.egrn_count !== "undefined") {
						let egrn_count = response.data.egrn_count;
						this.emitter.emit("updateEgrnCounter", egrn_count);
					}
				} else {
					if (!this.isEmpty(response.data.errors)) {
						this.errors = response.data.errors;
					}
					orderEgrnErrorModal.show();
				}
			}).catch(function (error) {

				CommonService.log('error', 'getOrderEgrn()::axios', error);

				orderEgrnErrorModal.show();
			});

		}
	},
	mounted() {
		var _this = this;
		var modal = this.$refs.orderEgrnModal;
		if (modal && typeof modal !== "undefined") {

			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.object_id = null;
				_this.simple_type = 0;
				_this.move_owner_type = 0;
				_this.emitter.emit("global.modalClose", modal);
			});

			modal.addEventListener('show.bs.modal', function (event) {
				let target = event.relatedTarget

				_this.results = [];
				_this.errors = [];

				let object_id = target.getAttribute('data-bs-object-id');
				if (typeof object_id !== "undefined") {
					_this.object_id = object_id;
				}

				if (process.env.NODE_ENV == "development") {
					console.debug('orderEgrnModal::show.bs.modal', {
						object_id: _this.object_id
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
	computed: {
		egrn_count () {
			if (!this.isEmpty(this.$store.getters.userInfo)) {
				if (typeof this.$store.getters.userInfo.services.egrn_count !== "undefined") {
					return this.$store.getters.userInfo.services.egrn_count;
				}
			}

			return 0;
		}
	},
}
</script>