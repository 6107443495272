<template>
	<div>
		<div class="modal fade" id="updateServiceModal" ref="updateServiceModal" tabindex="-1" aria-labelledby="Изменить" aria-hidden="true">
			<div  class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div  class="modal-content p-3">
					<div class="modal-header d-flex mb-20 border-0 p-1">
						<span v-if="is_loaded" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
							{{ serviceName }}
						</span>
						<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
                    <template v-if="is_loaded">
                        <div  class="modal-body d-flex flex-column p-1">
                            <Editor 
                            inputLabel="Комментарий"
                            inputName="editText"
                            ref="editText"
                            inputClass="rounded-1 mb-10 d-none"
                            inputWrapClass="flex-column mb-2"
                            :inputValue="this.editText"
                            :editorConfig="{
                                        language: 'ru',
                                        toolbar: [ 'bold', 'italic', 'underline', '|', 'undo', 'redo', '|', 'cleanup', '|', 'numberedList', 'bulletedList' ]
                                    }"
                            :inputHelpText="this.icon(require('@/assets/icons/info.svg'), 'd-inline me-2', 'Info', [16, 16]) + 'Во избежании блокировки в описании не следует указывать телефон, сайт, e-mail или другие контакты.'"
                            @setValue="(value) => this.setAndValidate('editText', value)" />
                        </div>
                        <InputSelect v-if="this.service == 'parking'"  inputId="parking_select"
                                        inputName="parking_select"
                                        :inputValue="this.parking_type_id"
                                        :inputOptions="this.parking_list"
                                        inputClass="rounded-1 p-2"
                                        inputWrapClass="flex-column"
                                        inputUsePlaceholder="true"
                                        @setValue="(value) => this.parking_type_id = value" />
                        <InputSelect v-if="this.service == 'mebel'"  inputId="mebel_select"
                                        inputName="mebel_select"
                                        :inputValue="this.mebel_list_id"
                                        :inputOptions="this.mebel_list"
                                        inputClass="rounded-1 p-2"
                                        inputWrapClass="flex-column"
                                        inputUsePlaceholder="true"
                                        @setValue="(value) => this.mebel_list_id = value" />
                        <Switcher v-if="this.service == 'no_child'" inputId="is_no_child"
                            inputName="is_no_child"
                            ref="is_no_child"
                            inputLabel="С детьми"
                            :inputValue="this.no_child"
                            inputClass="px-0"
                            inputWrapClass="flex-column mb-3"
                            @setValue="(value) => this.setAndValidate('no_child', value)" />   
                            
                            <Switcher v-if="this.service == 'no_pets'" inputId="is_no_pets"
                            inputName="is_no_pets"
                            ref="is_no_pets"
                            inputLabel="С животными"
                            :inputValue="this.no_pets"
                            inputClass="px-0"
                            inputWrapClass="flex-column mb-3"
                            @setValue="(value) => this.setAndValidate('no_pets', value)" />            
                    </template>                 
                    <Loader v-else />
					<div v-if="is_loaded" class="modal-footer border-0 p-1">
						<button type="button"
								class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
								:disabled="!this.object_id"
								@click="saveService()" >
							Сохранить
						</button>
					</div>

				</div>
                
			</div>
            
		</div>
		
	</div>
</template>

<script>
import * as bootstrap from 'bootstrap';
import api from "@/api";
import Editor from '@/components/inputs/Editor.vue';
import CommonService from "@/services/CommonService";
import Loader from '@/components/common/Loader.vue';
import InputSelect from "@/components/inputs/InputSelect";
import Switcher from '@/components/inputs/Switcher.vue';


export default {
	name: 'UpdateServiceModal',
	components: { Editor, Loader, InputSelect, Switcher },
	data() {
		return {
			object_id: null,
			service: null,
            is_loaded: false,
            serviceName: null,
            editText: null,
            mebel_list_id: 0,
            mebel_list: [],
            parking_list: [],
            no_child: 0,
            no_pets: 0,
            parking_type_id: null
		}
	},

	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
        inArray(needle, haystack) {
            return CommonService.inArray(needle, haystack);
        },
        getListMebel: function(){
            return api.get('/object/serviceLists')
				.then((response) => {
                   // console.log(response.data);
					CommonService.log('debug', 'getRoles()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let listMebel = [];
						if (response.data.mebel_list) {
							Object.entries(response.data.mebel_list).forEach(([key, mebel]) => {
								listMebel.push({
									value: mebel.id.toString(),
									name: mebel.name
								});
							});
						}
                        this.mebel_list = listMebel; 

                        let listParking = [];
						if (response.data.parking_types) {
							Object.entries(response.data.parking_types).forEach(([key, mebel]) => {
								listParking.push({
									value: mebel.id.toString(),
									name: mebel.name
								});
							});
						}
                        this.mebel_list = listMebel;
                        this.parking_list = listParking;

						return listMebel;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getListMebel()::axios', error);

				});
        },
        getObjectService: function (){
            api.get('/object', {
						params: {
							object_id: this.object_id,
							section: this.service,
						}
			})
			.then((response) => {

				console.log(response.data);
                if (response.status == 200 && response.data.success) {
                    switch (this.service){
                        case 'mebel': {
                            if(!this.isEmpty(response.data.item.comment_mebel)){
                                this.editText = response.data.item.comment_mebel;
                            }
                            this.mebel_list_id = response.data.item.mebel_list_id;
                            break;
                        }
                        case 'no_child': {
                            if(!this.isEmpty(response.data.item.comment_no_child)){
                                this.editText = response.data.item.comment_no_child;
                            }
                            this.no_child = response.data.item.no_child;
                            break;
                        }
                        case 'no_pets' : {
                            if(!this.isEmpty(response.data.item.comment_no_pets)){
                                this.editText = response.data.item.comment_no_pets;
                            }
                            this.no_pets = response.data.item.no_pets;
                            break;
                        }
                        case 'parking' : {
                            if(!this.isEmpty(response.data.item.comment_no_parking)){
                                this.editText = response.data.item.comment_no_parking;
                            }
                            this.parking_type_id = response.data.item.parking_type_id;
                            break;
                        }
                    }
                }
                this.is_loaded = true;
				
			}).catch(function (error) {

				CommonService.log('error', 'getOrderEgrn()::axios', error);

				
			});
        },
		
        setAndValidate(name, value, skip_advert_check) {

            let errors = [];
            let parent = null;
            let child = null;
            let parts = name.split('.', 2);
            if (parts.length == 2) {
            parent = parts[0];
            child = parts[1];
            }

            skip_advert_check = false;
            if (typeof skip_advert_check != "undefined")
            skip_advert_check = true;

            // Валидация значения
            if (this.$refs[name] && typeof this.$refs[name] !== "undefined") {
            /*if ('invalid' in this.$refs[name])
                this.$refs[name].invalid = false;*/

            if (this.$refs[name].inputLabel) {
                let label = this.$refs[name].inputLabel.replace(/:+$/, '');

                let is_skip = false;
                /*if (name == 'funnel_id' && value == 0)
                is_skip = true;*/

                if (this.$refs[name].required && !is_skip) {
                if (value) {
                    if (this.$refs[name].required && value.toString() == '0')
                    errors.push('Поле `' + label + '` обязательно к заполнению!');
                    else if (this.$refs[name].required && this.isEmpty(value))
                    errors.push('Поле `' + label + '` обязательно к заполнению!');
                } else {
                    errors.push('Поле `' + label + '` обязательно к заполнению!');
                }

                if (
                    this.inArray(name, ['deal_type', 'avito_room_type_id', 'address.country_id', 'house_material_type', 'house_type']) &&
                    value === 'Не выбрано') {
                    errors.push('Поле `' + label + '` обязательно к заполнению!');
                    value = null;
                }
                }

                if (name == 'metro.type' && this.isEmpty(value) && !this.isEmpty(this.metro.time)) {
                errors.push('Поле `' + label + '` обязательно к заполнению!');
                }

                if (name == 'photos') {
                errors.push('Поле `' + label + '` обязательно к заполнению!');
                }

                if (!this.isEmpty(errors)) {

                this.errors[name] = {
                    name: name,
                    label: label,
                    errors: errors
                };

                this.$refs[name].valid = false;
                this.$refs[name].invalid = errors.join('<br/>');
                } else {
                this.$refs[name].valid = true;
                this.$refs[name].invalid = false;
                }
            }
            }

            // Проверки перед установкой значения
            if (name) {

            if (name == 'address.full' && typeof value === "string" && !this.isEmpty(value)) {
                value = value.replace(/^\d{6,},/g, ''); // чистим от почтового индекса (dadata.ru)
            }

            if (name == 'operation_type' && value == 1)
                this.lease_type = 2;
            else if (name == 'operation_type' && value !== 2)
                this.lease_type = null;

            if ((name == 'geo.lat' || name == 'geo.lng') && !this.isEmpty(value))
                value = parseFloat(value);

            if (name == 'object_type' && value == 4)
                this.commercial_type = 1;
            else if (name == 'object_type' && value !== 4)
                this.commercial_type = null;

            if (name == 'address.region_id') {
                this.metro = null;
                this.to_metro = null;
                this.metro_list = null;
                this.getMetroList(value).then(data => {

                if (!this.isEmpty(data))
                    this.metro_list = data;
                else
                    this.metro_list = null;

                });

                this.getNewBuildings(value).then(data => {
                if (!this.isEmpty(data))
                    this.newbuildings_list = this.sortArray(data)
                else
                    this.newbuildings_list = null;
                });
            }

            if (this.inArray(name, ['prices.amount', 'prices.amount_commission', 'prices.prep', 'prices.start', 'prices.fact', 'prices.price_avito'])) {
                if (typeof value === "string" && !this.isEmpty(value)) {
                value = parseInt(value.replace(/\s/g, ''));
                }
            }

            if (!skip_advert_check && this.id && (name == 'use_in_advert' && value == 0)) {
                let confirm = CommonService.getModal('addEditObjectConfirmRemoveFromAds');
                confirm.show();
            }
            }

            // Установка значения
            if (parent && child) {
            if (!this.isEmpty(this[parent])) {
                if (typeof (this[parent][child]) !== "undefined") {
                this[parent][child] = value;
                } else {
                this[parent] = {
                    ...{
                    [child]: value
                    },
                    ...this[parent]
                };
                }
            } else {
                this[parent] = {
                [child]: value
                };
            }
            } else if (parent == null && name) {
            /*if (name == 'fields') {
                return;
            } else {
                this[name] = value;
            }*/
            this[name] = value;
            } else {
            if (parent && child)
                this[parent][child] = null;
            else
                this[name] = null;
            }

            this.is_form_changed = true;

            CommonService.log('debug', 'setAndValidate()', { name: name, value: value, errors: errors });



            if (!this.isEmpty(errors))
            return false;

            return true;
        },

        saveService: function (){

            let data = {
					service: this.service,
					object_id: this.object_id,
                    mebel_list_id: this.mebel_list_id,
                    comment: this.editText,
                    no_child: this.no_child,
                    no_pets: this.no_pets,
                    parking_type_id: this.parking_type_id,
					
				}

				CommonService.log('debug', data);

				if (!this.isEmpty(this.service) && !this.isEmpty(this.object_id)) {

					

					let _this = this;
					api.put('/object/setService', data).then((response) => {
						//CommonService.log('debug', 'saveService()::axios', {response});
                        console.log(response.data);
						if (response.status == 200 && response.data.success) {
							
                            _this.$emit('updateObject', _this.object_id);

                            let modal = CommonService.getModal('updateServiceModal');
                            if (modal)
                                modal.hide();
              
						}
					}).catch(function (error) {

						CommonService.log('error', 'saveService()::axios', error);

					});
				}
        },

        icon(path, class_name, title, size) {
            return CommonService.buildIconImage(path, class_name, title, size);
        },
	},
	mounted() {
		var _this = this;
		var modal = this.$refs.updateServiceModal;
        this.getListMebel();
		if (modal && typeof modal !== "undefined") {

			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.object_id = null;
				_this.service = null;
                _this.serviceName = null;
                _this.mebel_list_id = null;
                _this.editText = null;
                _this.is_loaded = false;
                _this.no_child = 0;
                _this.no_pets = 0;
                _this.parking_type_id = null;
				_this.emitter.emit("global.modalClose", modal);
			});

			modal.addEventListener('show.bs.modal', function (event) {
				let target = event.relatedTarget

				_this.results = [];
				_this.errors = [];

				let object_id = target.getAttribute('data-bs-object-id');
				if (typeof object_id !== "undefined") {
					_this.object_id = object_id;
				}

                let service = target.getAttribute('data-bs-service');
                if (typeof service !== "undefined") {
					_this.service = service;
                    
                    switch (_this.service){
                        case 'mebel': _this.serviceName = "Мебель"; break;
                        case 'no_child': _this.serviceName = "С детьми"; break; 
                        case 'no_pets': _this.serviceName = "С животными"; break;
                        case 'parking': _this.serviceName = "Паркинг"; break;
                        
                    }
				}

                if(!_this.isEmpty(_this.object_id) && !_this.isEmpty(_this.service)){
                    _this.getObjectService();
                }

				if (process.env.NODE_ENV == "development") {
					console.debug('updateServiceModal::show.bs.modal', {
						object_id: _this.object_id
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
	computed: {
		
	},
}
</script>