<template>
	<div>
		<div class="modal fade" id="updateStatusModal" ref="updateStatusModal" tabindex="-1" aria-labelledby="Изменить" aria-hidden="true">
			<div  class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
				<div  class="modal-content p-3">
					<div class="modal-header d-flex mb-20 border-0 p-1">
						<span v-if="is_loaded" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">
							Смена статуса объекта
						</span>
						<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
                    <template v-if="is_loaded">
                        
                        <InputSelect  inputId="status_select"
                                        inputName="status_select"
                                        :inputValue="this.status_id"
                                        :inputOptions="this.status_list"
                                        inputClass="rounded-1 p-2"
                                        inputWrapClass="flex-column"
                                        inputUsePlaceholder="true"
                                        @setValue="(value) => this.status_id = value" />
                              
                    </template>                 
                    <Loader v-else />
					<div v-if="is_loaded" class="modal-footer border-0 p-1">
						<button type="button"
								class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
								:disabled="!this.object_id"
								@click="saveStatus()" >
							Сохранить
						</button>
					</div>

				</div>
                
			</div>
            
		</div>
		
	</div>
</template>

<script>
import * as bootstrap from 'bootstrap';
import api from "@/api";
import CommonService from "@/services/CommonService";
import Loader from '@/components/common/Loader.vue';
import InputSelect from "@/components/inputs/InputSelect";


export default {
	name: 'UpdateStatusModal',
	components: { Loader, InputSelect },
	data() {
		return {
			object_id: null,
            is_loaded: false,
            status_list: [],
            status_id: null
		}
	},

	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
        inArray(needle, haystack) {
            return CommonService.inArray(needle, haystack);
        },
        getListStatus: function(){
            return api.get('/object/statusLists',{
                params: {
					object_id: this.object_id,
                    
				}
                })
				.then((response) => {
                  //  console.log(response.data);
					CommonService.log('debug', 'getRoles()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let listStatus = [];
						if (response.data.status_lists) {
							Object.entries(response.data.status_lists).forEach(([key, status]) => {
								listStatus.push({
									value: status.id.toString(),
									name: status.name
								});
							});
						}
                        this.status_list = listStatus; 
                        this.is_loaded = true;
                       
						return listStatus;
					} else {
						return {};
					}

                    
				}).catch(function (error) {

					CommonService.log('error', 'getListMebel()::axios', error);

				});
        },
        
        
        saveStatus: function (){

            let data = {
					
					object_id: this.object_id,
                    status_id: this.status_id,
					
				}

				CommonService.log('debug', data);

				if (!this.isEmpty(this.status_id) && !this.isEmpty(this.object_id)) {

					

					let _this = this;
					api.put('/object/setStatus', data).then((response) => {
						//CommonService.log('debug', 'saveService()::axios', {response});
                        console.log(response.data);
						if (response.status == 200 && response.data.success) {
							
                            _this.$emit('updateObject', _this.object_id);

                            let modal = CommonService.getModal('updateStatusModal');
                            if (modal)
                                modal.hide();
              
						}
					}).catch(function (error) {

						CommonService.log('error', 'saveService()::axios', error);

					});
				}
        },

        icon(path, class_name, title, size) {
            return CommonService.buildIconImage(path, class_name, title, size);
        },
	},
	mounted() {
		var _this = this;
		var modal = this.$refs.updateStatusModal;
        
		if (modal && typeof modal !== "undefined") {

			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.object_id = null;
                _this.status_id = null;
				_this.emitter.emit("global.modalClose", modal);
			});

			modal.addEventListener('show.bs.modal', function (event) {
				let target = event.relatedTarget

				_this.results = [];
				_this.errors = [];

				let object_id = target.getAttribute('data-bs-object-id');
				if (typeof object_id !== "undefined") {
					_this.object_id = object_id;
				}

                let status_id = target.getAttribute('data-bs-status-id');
                if (typeof status_id !== "undefined") {
					_this.status_id = status_id;
                    
                    
				}

                if(!_this.isEmpty(_this.object_id) && !_this.isEmpty(_this.status_id)){
                    _this.getListStatus();
                }

				if (process.env.NODE_ENV == "development") {
					console.debug('updateStatusModal::show.bs.modal', {
						object_id: _this.object_id
					});
				}
				_this.emitter.emit("global.modalShown", modal);
			});
		}
	},
	computed: {
		
	},
}
</script>