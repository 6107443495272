<template>
	<div class="modal fade" :id="(!this.isEmpty(this.modal_id)) ? this.modal_id : 'addEditContractModal'" tabindex="-1" aria-labelledby="Добавление/редактирование договора" aria-hidden="true">
		<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-20">
				<div class="modal-header d-flex align-items-center mb-20 border-0 p-0">
					<span v-if="this.contract_id" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Редактировать договор</span>
					<span v-else class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Добавить договор</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div class="modal-body d-flex flex-column p-0">
					<div class="row-fluid row-cols-1">
						<div class="col">
							<SelectBox inputId="type"
								   inputName="type"
								   ref="type"
								   inputLabel="Тип договора:"
								   :inputOptions="this.contract_types"
								   :inputValue="this.item.type"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputRequired="true"
								   @setValue="(value) => this.setAndValidate('type', value)" />
						</div>
						<div class="col">
							<InputText inputId="number"
								   inputName="number"
								   ref="number"
								   inputLabel="Номер договора:"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   :inputValue="this.item.number"
								   inputRequired="true"
								   @setValue="(value) => this.setAndValidate('number', value)" />
						</div>
						<div class="col">
							<div class="d-flex flex-column mb-10 mx-0">
								<span class="text-font-secondary mb-3">Период договора</span>
								<div class="row row-cols-2">
									<div class="col">
										<DateTimePicker inputId="date_start"
														inputName="date_start"
														ref="date_start"
														inputLabel="Дата начала:"
														inputLabelClass="text-font-secondary mb-3"
														inputClass="rounded-3 p-2"
														inputWrapClass="mb-20"
														:inputValue="this.item.date_start"
														inputRequired="true"
														displayFormat="DD.MM.YYYY"
														@setValue="(value) => this.setAndValidate('date_start', value)" />
									</div>
									<div class="col">
										<DateTimePicker inputId="date_end"
														inputName="date_end"
														ref="date_end"
														inputLabel="Дата окончания:"
														inputLabelClass="text-font-secondary mb-3"
														inputClass="rounded-3 p-2"
														inputWrapClass="mb-20"
														:inputValue="this.item.date_end"
														inputRequired="true"
														displayFormat="DD.MM.YYYY"
														@setValue="(value) => this.setAndValidate('date_end', value)" />
									</div>
								</div>
							</div>
						</div>
						<div class="col">
							<InputNumber inputId="notify_days"
								   inputName="notify_days"
								   ref="notify_days"
								   inputLabel="Предупредить об окончании:"
								   inputLabelClass="text-font-secondary mb-3"
								   inputClass="rounded-3 p-2"
								   inputWrapClass="mb-20"
								   inputAppend="дней"
								   :inputValue="this.item.notify_days ?? 10"
								   @setValue="(value) => this.setAndValidate('notify_days', value)" />
						</div>
						<div class="col">
							<Switcher inputId="is_notified"
								  inputName="is_notified"
								  ref="is_notified"
								  inputLabel="Отправлять уведомления"
								  :inputValue="this.item.is_notified"
								  inputClass="px-0"
								  inputWrapClass="flex-column mb-3"
								  @setValue="(value) => this.setAndValidate('is_notified', value)" />
						</div>
					</div>
				</div>
				<div class="modal-footer border-0 p-0">
					<button
						type="button"
						class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
						@click="this.addEditContract()">
						Сохранить
					</button>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
import SelectBox from "@/components/inputs/SelectBox";
import api from "@/api";
import ResultsModal from "@/components/modals/ResultsModal";
import CommonService from "@/services/CommonService";
import InputText from "@/components/inputs/InputText";
import Switcher from "@/components/inputs/Switcher";
import InputNumber from "@/components/inputs/InputNumber";
import DateTimePicker from "@/components/inputs/DateTimePicker";

export default {
	name: "AddEditContractModal",
	props: {
		modalId: { type: String },
		contractSection: { type: String },
		contractId: { type: [Number, String] },
		sourceId: { type: [Number, String] },
		simulateAdd: { type: [Boolean] },
	},
	data() {
		return {
			modal_id: (typeof this.modalId !== "undefined") ? this.modalId : null,
			contract_id: (typeof this.contractId !== "undefined") ? this.contractId : null,
			section: (typeof this.contractSection !== "undefined") ? this.contractSection : null,
			source_id: (typeof this.sourceId !== "undefined") ? parseInt(this.sourceId) : null,
			item: {
				type: null,
				number: null,
				date_start: null,
				date_end: null,
				notify_days: null,
				is_notified: null,
			},
			simulate: (typeof this.$props.simulateAdd !== "undefined") ? (this.$props.simulateAdd) : false,
			contract_types: [],
			errors: [],
		}
	},
	methods: {
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		sortArray(data) {
			return CommonService.sortArray(data);
		},
		resetData() {
			this.contract_id = null;
			this.source_id = null;
			this.simulate = false;
			this.item = {
				type: null,
				number: null,
				date_start: null,
				date_end: null,
				notify_days: null,
				is_notified: null,
			};
		},
		getTypes(section) {
			if (typeof section !== "undefined") {
				return api.get('/common/types', {
					params: {
						section: section
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getTypes()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						let list = [];
						Object.entries(response.data.list).forEach(([key, option]) => {
							list.push({
								value: option.id,
								name: option.name
							});
						});

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getTypes()::axios', error);

				});
			}
		},
		getContract(section, source_id) {
			return api.get('/contract', {
				params: {
					section: section,
					source_id: source_id,
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getContract()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {

						if (response.data.item)
						return response.data.item;

				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getContract()::axios', error);

			});
		},
		setAndValidate(name, value, as_array) {

			let errors = [];
			let parent = null;
			let child = null;
			let parts = name.split('.', 2);
			if (parts.length == 2) {
				parent = parts[0];
				child = parts[1];
			}

			// Валидация значения
			if (this.$refs[name] && typeof this.$refs[name] !== "undefined") {

				/*if ('invalid' in this.$refs[name])
					this.$refs[name].invalid = false;*/

				if (this.$refs[name].inputLabel) {
					let label = this.$refs[name].inputLabel.replace(/:+$/, '');

					let is_skip = false;
					if (name == 'funnel_id' && value == 0)
						is_skip = true;

					if (this.$refs[name].required && !is_skip) {
						if (value) {
							if (value.toString() == '0' || value.toString() == '-1')
								errors.push('Поле `' + label + '` обязательно к заполнению!');
							else if (this.isEmpty(value))
								errors.push('Поле `' + label + '` обязательно к заполнению!');
						} else {
							errors.push('Поле `' + label + '` обязательно к заполнению!');
						}
					}

					if (!this.isEmpty(errors)) {
						this.errors[name] = {
							name: name,
							label: label,
							errors: errors
						};

						this.$refs[name].valid = false;
						this.$refs[name].invalid = errors.join('<br/>');
					} else {
						this.errors[name] = null;
						this.$refs[name].valid = true;
						this.$refs[name].invalid = false;
					}
				}
			}

			// Установка значения
			if (parent && child) {
				if (!this.isEmpty(this.item[parent])) {
					if (typeof (this.item[parent][child]) !== "undefined") {
						this.item[parent][child] = value;
					} else {
						this.item[parent] = {
							...{
								[child]: value
							},
							...this.item[parent]
						};
					}
				} else {
					this.item[parent] = {
						[child]: value
					};
				}
			} else if (parent == null && name) {
				this.item[name] = value;
			} else {
				if (parent && child)
					this.item[parent][child] = null;
				else
					this.item[name] = null;
			}

			this.is_form_changed = true;

			CommonService.log('debug', 'setAndValidate()', { name: name, value: value, is_form_changed: this.is_form_changed, errors: errors });

		},
		validateAll(fields) {

			this.errors = [];

			let is_valid = false;
			let collection = CommonService.proxyToObject(fields);

			let invalid_input = null;
			for (const [name, value] of Object.entries(collection)) {
				this.setAndValidate(name, value);
			}

			setTimeout(() => {
				this.errors = CommonService.removeEmpty(this.errors);
			}, 600);

			if (typeof Object.values(this.errors) == "object") {
				this.errors = CommonService.removeEmpty(this.errors);
				if (typeof (this.errors[0]) !== "undefined") {
					if (!this.isEmpty(Object.values(this.errors)[0]['name'])) {
						let invalid_input = Object.values(this.errors)[0]['name'];
						if (invalid_input && typeof invalid_input !== "undefined") {
							let input = this.$refs[invalid_input].$el;
							if (input) {
								CommonService.scrollIntoView(input);
							}
						}
					}
				}
			}

			is_valid = this.isEmpty(this.errors);

			CommonService.log('debug', 'validateAll()', {fields: collection, is_valid: is_valid, errors: this.errors});

			return is_valid;
		},
		addEditContract() {

			console.info(this.simulate);

			if (!this.simulate && this.validateAll(this.item)) {
				api.post('/contract/add', {...this.item, ...{source_id: this.source_id}, ...{section: this.section}}).then((response) => {
					CommonService.log('debug', 'addEditContract()::axios', {response});

					if (response.status == 200 && response.data.success) {
						this.$emit('contractAdded', {
							section: this.section,
							source_id: this.source_id,
							is_simulated: false,
							contract: {
								id: response.data.item.id,
								type: response.data.item.type,
								number: response.data.item.number,
								date_start: response.data.item.date_start,
								date_end: response.data.item.date_end,
								days_mes_end: response.data.item.days_mes_end,
								no_mes_end: response.data.item.no_mes_end,
							}
						});

						let modal = CommonService.getModal('addEditContractModal')
						if (modal) {
							modal.hide();
						}
					}
				}).catch(function (error) {

					CommonService.log('error', 'addEditContract()::axios', error);

				});
			} else {

				this.validateAll(this.item);

				let data = {
					section: this.section,
					source_id: this.source_id,
					is_simulated: true,
					contract: {
						id: this.item.id,
						type: this.item.type,
						number: this.item.number,
						date_start: this.item.date_start,
						date_end: this.item.date_end,
						days_mes_end: this.item.days_mes_end,
						no_mes_end: this.item.no_mes_end,
					}
				};

				CommonService.log('debug', 'addEditContract()::axios', {data});

				this.$emit('contractAdded', data);
			}
		},

	},
	components: {
		DateTimePicker,
		InputNumber,
		Switcher,
		InputText,
		SelectBox

	},
	mounted() {

		if (this.section !== null && this.contract_id !== null)
			this.getContract(this.section, this.contract_id).then(data => { this.item = data });

		this.getTypes('contract_types').then(data => { this.contract_types = data });

		let modal = document.getElementById('addEditContractModal');
		if (modal && typeof modal !== "undefined") {

			let _this = this;
			modal.addEventListener('hidden.bs.modal', function (event) {
				_this.section = null;
				_this.contract_id = null;
				_this.source_id = null;
				_this.item = {};
				_this.emitter.emit("global.modalClose", modal);
				_this.$emit('modalClosed');
				_this.resetData();
			});

			modal.addEventListener('shown.bs.modal', function (event) {


				let target = event.relatedTarget
				if (typeof target !== "undefined") {

					let section = target.getAttribute('data-bs-section');
					if (typeof section !== "undefined") {
						_this.section = section;
					}

					let contract_id = target.getAttribute('data-bs-contract-id');
					if (typeof contract_id !== "undefined") {
						_this.contract_id = contract_id;
					}

					let source_id = target.getAttribute('data-bs-source-id');
					if (typeof source_id !== "undefined") {
						_this.source_id = source_id;
					}

					let simulate = target.getAttribute('data-bs-simulate');
					if (typeof simulate !== "undefined") {
						_this.simulate = simulate;
					}

					if (_this.section && _this.contract_id) {
						_this.getContract(_this.section, _this.contract_id).then(data => { _this.item = data });
					}
				}

				if (process.env.NODE_ENV == "development") {
					console.debug('addEditContractModal::show.bs.modal', {
						simulate: _this.simulate,
						section: _this.section,
						contract_id: _this.contract_id,
						source_id: _this.source_id
					});
				}
				_this.emitter.emit("global.modalShown", modal);
				_this.$emit('modalShowed');
			});
		}
	},
	computed: {

	},
	watch: {
		contract_id: function() {
			return this.contract_id;

		},
	}
}
</script>