<template>
    <div>
        <div class="modal fade modal-fullscreen" id="historyTasksModal" tabindex="-1" aria-labelledby="История и задачи" aria-hidden="true">
			<div class="modal-dialog modal-fullscreen modal-fullscreen"
				 :class="(
						 (!this.isEmpty(this.tasks) && this.tabIsShow('tasks')) ||
						 (!this.isEmpty(this.history) && this.tabIsShow('history')) ||
						 (!this.isEmpty(this.notes) && this.tabIsShow('notes')) ||
						 (!this.isEmpty(this.variants) && this.tabIsShow('variants'))
					 ) ? 'modal-dialog-scrollable' : 'modal-dialog-centered'">
				<div v-if="!this.isEmpty(this.item)" class="modal-content rounded-1">
                    <div class="modal-header p-20 d-flex flex-column align-items-start p-0">
						<div class="d-flex w-100 flex-fill flex-column align-items-start p-0">
                            <div v-if="this.section == 'objects'"
								 class="d-flex flex-column align-items-start mb-20 order-1 mw-80">
                                <span v-if="this.item.title"
									  class="font-semi fw-semi fs-2 me-10"
									  style="text-decoration: underline;"
									  v-text="this.item.title" />
								<span v-else
									  class="font-semi fw-semi fs-2 me-10"
									  style="text-decoration: underline;"
									  v-text="this.tempBlockTitle" />

								<span v-if="!this.isEmpty(this.item.id)"  class="text-secondary me-10 mt-2 mb-1 fs-5">
									ID {{ this.item.id }}
								</span>

								<span v-if="!this.isEmpty(this.item.address)">
									<span class="text-secondary me-20 fs-4"
										  v-text="this.item.address.full" />
								</span>

                            </div>
							<div v-else-if="this.section == 'clients' || this.section == 'requisitions'"
								 class="d-inline-flex flex-wrap align-items-start mb-10 order-1 mw-80">
								<span v-if="!this.isEmpty(this.item.published_at)" class="text-secondary me-10 mb-1 fs-5">{{ this.formatDateTime(this.item.published_at, 'L') }}</span>
								<span v-if="!this.isEmpty(this.item.roles) && !this.isEmpty(this.roles_list)" class="text-secondary me-10 mb-1 fs-5">
									<span v-for="(role, index) in this.item.roles"
										  :key="index"
										  v-text="(index > 0) ? ' / ' + this.roles_list[role] : this.roles_list[role]"/>
								</span>
								<span v-if="!this.isEmpty(this.item.types) && !this.isEmpty(this.types_list)" class="text-secondary me-10 mb-1 fs-5">
									<span v-for="(role, index) in this.item.types"
										  :key="index"
										  v-text="(index > 0) ? ' / ' + this.types_list[role] : this.types_list[role]"/>
								</span>

								<span v-if="!this.isEmpty(this.item.id)"  class="text-secondary me-10 mb-1 fs-5">
									ID {{ this.item.id }}
								</span>

								<span v-if="this.source && !this.partner && !this.type" class="text-secondary me-10 mb-1 fs-5">
									{{ this.source }}
								</span>
								<span v-else-if="this.source && this.partner && this.type" class="text-secondary me-10 mb-1 fs-5">
									{{ this.type }} / {{ this.source }} / {{ this.partner }}
								</span>
								<span v-else-if="this.source && this.partner" class="text-secondary me-10 mb-1 fs-5">
									{{ this.source }} / {{ this.partner }}
								</span>
								<span v-else-if="this.source && this.type" class="text-secondary me-10 mb-1 fs-5">
									{{ this.type }} / {{ this.source }}
								</span>
								<span v-else-if="!this.source && this.partner && !this.type" class="text-secondary me-10 mb-1 fs-5">
									{{ this.partner }}
								</span>
								<span v-else-if="!this.source && !this.partner && this.type" class="text-secondary me-10 mb-1 fs-5">
									{{ this.type }}
								</span>
                            </div>
							<div v-if="this.section == 'clients' || this.section == 'requisitions'"
								 class="d-flex flex-column align-items-start mb-20 order-2">
								<div v-if="this.item.name" class="d-flex align-items-center">
									<span class="fs-2 fw-semi font-semi"
										  style="text-decoration: underline;"
										  v-text="this.item.name" />

									<span v-if="this.item.is_hot" class="d-inline-flex ms-1 my-auto">
										<img :src="require('@/assets/icons/fire-icon.svg')" alt="HOT" width="24" height="24">
									</span>

									<span v-if="this.item.priority == 1"
										  class="d-inline-flex p-2 ms-1 my-auto bg-raspberry rounded-circle"/>
									<span v-else-if="this.item.priority == 2"
										  class="d-inline-flex p-2 ms-1 my-auto bg-warning rounded-circle"/>
									<span v-else-if="this.item.priority == 3"
										  class="d-inline-flex p-2 ms-1 my-auto bg-ocean rounded-circle"/>
									<span v-else-if="this.item.priority == 4"
										  class="d-inline-flex p-2 ms-1 my-auto bg-success rounded-circle"/>
								</div>
								<div v-else
									  class="font-semi fw-semi fs-2 me-10"
									  style="text-decoration: underline;"
									  v-text="this.tempBlockTitle" />

							</div>
                            <button type="button" class="position-absolute d-flex ms-auto align-self-end btn-close order-0" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div v-if="this.is_potential_show"
							 class="d-flex w-100 flex-fill  align-items-center">
							<Switcher inputId="is_potential"
									  inputName="is_potential"
									  ref="is_potential"
									  inputLabel="Потенциальный объект"
									  :inputValue="(!this.isEmpty(this.item.is_potential)) ? (this.item.is_potential == 1) : 0"
									  inputClass="px-0"
									  inputWrapClass="flex-column"
									  @setValue="(value) => this.setAndValidate('is_potential', Number(value))" />
                        </div>
                        <div v-if="this.section == 'clients' || this.section == 'requisitions'" class="d-flex w-100 flex-fill  align-items-center">
							<div class="d-inline-flex"
								 role="button"
								 data-bs-toggle="modal"
								 data-bs-target="#changeStageModal"
								 :data-bs-client-id="this.stage_client_id"
								 :data-bs-requisition-id="this.stage_requisition_id"
								 :data-bs-funnel-id="this.stage_funnel_id">
								<div v-if="this.is_last_stage" class="crm-btn btn border border-brd-primary icon icon-color-secondary bg-light-gray rounded-3 me-10">
									<img :src="require('@/assets/icons/back-icon.svg')" alt="Этап воронки" width="20" height="20" />
								</div>
								<div v-else class="crm-btn btn border border-brd-primary bg-light-gray rounded-3 me-10">
									<img :src="require('@/assets/icons/btn-arrow-icon.svg')" alt="Этап воронки" width="20" height="20" />
								</div>
								<div class="d-flex flex-column">
									<span class="text-font-secondary pt-1 fs-4 pb-1">Этап воронки:</span>
									<span v-if="!this.isEmpty(this.stage_name)"
										  class="link border-0"
										  :class="(this.is_last_stage) ? 'text-danger' : ''"
										  :data-stage-id="this.stage_id">
										{{ this.stage_name }}
										<sup class="badge bg-danger rounded-3" v-if="this.item.is_deleted || this.item.is_canceled || this.item.is_completed">закрыт</sup>
									</span>
									<span v-else class="link border-0"
										  :data-stage-id="this.stage_id">
										Новый клиент
									</span>
								</div>
							</div>
                        </div>
                    </div>
                    <div v-show="this.is_state_ready" class="modal-body p-0" ref="modalBody">
                        <div v-if="this.section == 'clients'" class="pt-20 px-20 popover-container">
                            <div class="d-flex flex-column mb-10">
								<div v-if="this.item.phone" class="d-flex flex-column mb-10">
									<span class="mb-1 fs-5 text-font-secondary">Телефон</span>
									<span class="mb-20">
										<a :href="'tel:' + this.item.phone"
										   target="_blank"
										   class="d-inline-flex me-auto pb-0 mb-0 link">
											{{ this.formatPhone(this.item.phone, true) }}
										</a>
									</span>
								</div>
								<div v-if="this.item.email" class="d-flex flex-column mb-10">
									<span class="mb-1 fs-5 text-font-secondary">Почта</span>
									<span>
										<a :href="'mailto:'+this.item.email" target="_blank"
											 class="d-inline-flex me-auto pb-0 mb-2 link">
											{{ this.item.email }}
										</a>
									</span>
								</div>
                            </div>
                        </div>
                        <div v-if="this.inArray(this.section, ['clients', 'requisitions'])" class="pt-20 px-20 popover-container">
                            <div class="d-flex flex-column mb-10">
								<div v-if="this.item.client_name" class="d-flex flex-column mb-10">
									<span class="mb-1 fs-5 text-font-secondary">Клиент</span>
									<span class="mb-20">
										<router-link :to="'/edit-client?id=' + this.item.client_id"
													 class="link d-inline me-auto">
											{{ this.item.client_name }}
										</router-link>
									</span>
								</div>
								<div v-if="this.item.client_phone" class="d-flex flex-column mb-10">
									<span class="mb-1 fs-5 text-font-secondary">Телефон</span>
									<span class="mb-20">
										<a :href="'tel:' + this.item.client_phone"
										   target="_blank"
										   class="d-inline-flex me-auto pb-0 mb-0 link">
											{{ this.formatPhone(this.item.client_phone, true) }}
										</a>
									</span>
								</div>
								<div v-if="this.item.client_email" class="d-flex flex-column mb-10">
									<span class="mb-1 fs-5 text-font-secondary">Почта</span>
									<span>
										<a :href="'mailto:'+this.item.client_email" target="_blank"
											 class="d-inline-flex me-auto pb-0 mb-2 link">
											{{ this.item.client_email }}
										</a>
									</span>
								</div>
                            </div>
							<div class="d-flex mb-20 popover-container">
                                <button v-if="this.section === 'requisitions' && this.item.funnel_id > 0"
										type="button"
										class="btn btn-sm me-2"
										data-bs-toggle="modal"
										data-bs-dismiss="modal"
										data-bs-target="#checkListModal"
										:data-bs-client-id="this.client_id"
										:data-bs-requisition-id="this.requisition_id"
										:data-bs-funnel-id="(!this.isEmpty(this.item.funnel_id)) ? this.item.funnel_id : null">
                                    <img :src="require('@/assets/icons/checklist-icon.svg')" alt="Чек-лист" width="24" height="24">
                                </button>
                                <button v-if="this.inArray(this.section, ['objects', 'clients', 'requisitions'])"
										type="button"
										class="btn btn-sm me-2"
										data-bs-toggle="modal"
										data-bs-dismiss="modal"
										data-bs-target="#documentsListModal"
										:data-bs-from-section="this.section"
										:data-bs-object-id="this.object_id"
										:data-bs-client-id="this.client_id"
										:data-bs-requisition-id="this.requisition_id">
                                    <img :src="require('@/assets/icons/folder.svg')" alt="Документы" width="24" height="24">
                                </button>
								<template v-if="this.section === 'requisitions' && (this.item.type_id == 1 || this.item.heir_type_id == 1)">
									<button v-show="!this.isEmpty(this.filter_fields)"
											type="button"
											class="btn btn-sm me-2"
											data-bs-toggle="popover"
											:data-bs-target="'#autosearchInfo_' + this.item.id"
											data-bs-placement="bottom"
											data-bs-class="popover-info shadow"
											data-bs-body-class="pt-2 pe-3">
										<img :src="require('@/assets/icons/info-icon.svg')" alt="Параметры автопоиска" width="24" height="24">
									</button>
									<div v-show="!this.isEmpty(this.filter_fields)" class="d-none">
                                    <div :id="'autosearchInfo_' + this.item.id" class="rounded-3 p-0">
                                        <ul class="m-0 p-0 p-2">
											<li v-for="(filter, field, index) in this.filter_fields"
												:key="index"
												class="d-flex align-items-start justify-content-between mb-2">
												<span class="d-block text-font-secondary fs-4 w-50 text-truncate"
													  :data-field="field">{{ filter.label }}:</span>
												<span class="d-block fs-4 w-50 ps-20">{{ filter.value }}</span>
											</li>
											<li class="d-flex align-items-start justify-content-between mb-2">
												<span class="d-block text-font-secondary fs-4 w-50 text-truncate">ID фильтра:</span>
												<span class="d-block fs-4 w-50 ps-20">{{ this.item.autosearch_id }}</span>
											</li>
										</ul>
                                    </div>
                                </div>
								</template>

<!--                                <button v-show="!this.isEmpty(this.custom_fields)"
										type="button"
										class="btn btn-sm me-2"
										data-bs-toggle="popover"
										:data-bs-target="'#customFieldsInfo_' + this.item.id"
										data-bs-placement="bottom"
										data-bs-class="popover-info shadow"
										data-bs-body-class="pt-2 pe-3">
                                    <img :src="require('@/assets/icons/doc-icon.svg')" alt="Переменные" width="24" height="24">
                                </button>
								<div v-show="!this.isEmpty(this.custom_fields)" class="d-none">
									<div :id="'customFieldsInfo_' + this.item.id" class="rounded-3 p-0">
										<ul class="m-0 p-0 p-2">
											<li v-for="(field, index) in this.custom_fields"
												:key="index"
												class="d-flex align-items-start justify-content-between mb-2">
												<span class="d-block text-font-secondary fs-4 w-50 text-truncate"
													  :data-field="field.id">{{ field.label }}:</span>
												<span v-if="!this.isEmpty(field.value)" class="d-block fs-4 w-50 ps-20">{{ field.value }}</span>
												<span v-else class="d-block fs-4 w-50 ps-20 text-danger">не заполнено</span>
											</li>
										</ul>
									</div>
								</div>-->

								<button v-if="this.section != 'requisitions' && this.item.this_user_can_edit_clients" type="button"
										class="btn btn-sm me-2"
										alt="Редактировать"
										@click="this.hideAndGoToRoute('/edit-client?id=' + this.client_id)">
									<img :src="require('@/assets/icons/options-icon.svg')" alt="Редактировать" width="24" height="24">
								</button>
								<button v-else-if="this.section == 'requisitions' && this.item.this_user_can_edit_req" type="button"
										class="btn btn-sm me-2"
										alt="Редактировать"
										@click="this.hideAndGoToRoute('/edit-requisition?id=' + this.requisition_id)">
									<img :src="require('@/assets/icons/options-icon.svg')" alt="Редактировать" width="24" height="24">
								</button>
								<button v-if="this.section != 'requisitions' && this.item.is_deleted && this.item.this_user_can_edit_clients" type="button"
										class="btn btn-sm"
										alt="Восстановить" @click="this.initRestore()">
									<img :src="require('@/assets/icons/back-icon.svg')" alt="Восстановить" width="22" height="22">
								</button>
								<button v-else-if="!this.item.is_deleted && this.section != 'requisitions' && this.item.this_user_can_edit_clients" type="button"
										class="btn btn-sm"
										data-bs-toggle="modal"
										data-bs-dismiss="modal"
										data-bs-target="#closeClientModal"
										:data-bs-client-id="this.client_id"
										:data-bs-requisition-id="this.requisition_id"
										alt="Закрыть">
									<img :src="require('@/assets/icons/close-icon.svg')" alt="Закрыть" width="22" height="22">
								</button>

                <button v-if="this.section == 'requisitions' && this.item.is_deleted && this.item.this_user_can_edit_req" type="button"
                        class="btn btn-sm"
                        alt="Восстановить" @click="this.initRestore()">
                  <img :src="require('@/assets/icons/back-icon.svg')" alt="Восстановить" width="22" height="22">
                </button>
                <button v-else-if="this.section == 'requisitions' && !this.item.is_deleted && this.item.this_user_can_edit_req" type="button"
                        class="btn btn-sm"
                        data-bs-toggle="modal"
                        data-bs-dismiss="modal"
                        data-bs-target="#closeClientModal"
                        :data-bs-client-id="this.client_id"
                        :data-bs-requisition-id="this.requisition_id"
                        alt="Закрыть">
                  <img :src="require('@/assets/icons/close-icon.svg')" alt="Закрыть" width="22" height="22">
                </button>

								<button v-if="this.section != 'requisitions' && this.item.is_deleted && (this.$store.getters.userInfo.is_agency || this.$store.getters.userInfo.is_users_admin)" type="button"
										class="btn btn-sm"
										alt="Удалить" @click="this.initDelete()">
									<img :src="require('@/assets/icons/basket-icon.svg')" alt="Удалить" width="22" height="22">
								</button>
                <button v-else-if="this.section == 'requisitions' && this.item.is_deleted && (this.$store.getters.userInfo.is_agency || this.$store.getters.userInfo.is_users_admin)" type="button"
                        class="btn btn-sm"
                        alt="Удалить" @click="this.initDelete()">
                  <img :src="require('@/assets/icons/basket-icon.svg')" alt="Удалить" width="22" height="22">
                </button>
                            </div>
                        </div>
                        <div class="position-relative">
                            <div v-if="!this.isEmpty(this.tabItems)" class="d-flex bg-white position-sticky top-0" style="z-index: 10;">
								<div class="d-flex bg-white m-0 text-nowrap-mask scroller scroller-x pb-0 mb-0 px-20"
									 role="tablist">
									<NavTabs ref="tabItems" :navItems="this.tabItems"/>
								</div>
                            </div>
                            <div class="tab-content position-relative" id="historyTasks" ref="tabContent">
                                <div class="tab-pane fade bg-light-gray border-top border-brd-primary"
									 id="tasks"
									 ref="tasks-tab"
									 role="tabpanel"
									 aria-labelledby="Вкладка задачи">

									<!-- -->
                                    <div v-if="this.section == 'objects'" class="day-content d-flex flex-column flex-fill align-items-center">
										<span class="position-sticky top-0 bg-light-gray d-flex text-font-secondary mb-20 p-10 p-sm-20"
											  v-text="this.formatDateTime(this.item.published_at)" />
                                        <span class="bg-ocean fs-4 font-semi fw-semi px-20 py-10 rounded-1 text-white">Объект добавлен</span>
                                    </div>
                                    <div v-else-if="this.section == 'clients'" class="day-content d-flex flex-column flex-fill align-items-center">
                                        <span class="position-sticky top-0 bg-light-gray d-flex text-font-secondary mb-20 p-10 p-sm-20"
											  v-text="this.formatDateTime(this.item.published_at)" />
                                        <span class="bg-ocean fs-4 font-semi fw-semi px-20 py-10 rounded-1 text-white">Клиент добавлен</span>
                                    </div>
                                    <div v-else-if="this.section == 'requisitions'" class="day-content d-flex flex-column flex-fill align-items-center">
                                        <span class="position-sticky top-0 bg-light-gray d-flex text-font-secondary mb-20 p-10 p-sm-20"
											  v-text="this.formatDateTime(this.item.created_at)" />
                                        <span class="bg-ocean fs-4 font-semi fw-semi px-20 py-10 rounded-1 text-white">Создана заявка</span>
                                    </div>

									<!-- EventCard -->
									<div v-if="!this.isEmpty(this.tasks)" class="day-content d-flex flex-column flex-fill align-items-center">
										<div v-for="(task, index) in this.tasks"
											 :key="task.id"
											 class="day-content container-fluid px-0 mb-2"
											 :data-id="task.id"
											 :data-num="index+1">
											<div v-if="!this.isEmpty(task.chat_date)" class="position-sticky top-0 bg-light-gray d-flex col flex-column w-100 flex-fill align-items-center mt-10 mb-3 py-10 p-sm-20">
												<span class="text-font-secondary">{{ task.chat_date }}</span>
											</div>
											<div class="d-flex col flex-column w-100 flex-fill align-items-center"
												 :class="this.isEmpty(task.chat_date) ? ((this.current_event_id == task.id) ? 'mt-20 opacity-50' : 'mt-20') : ((this.current_event_id == task.id) ? 'opacity-50' : '')"
												 :ref="'task_'+task.id"
												 :data-type="task.type">
												<div class="d-flex row-cols-auto w-100 px-20">
													<div class="col"
														 :class="(task.chat_position == 'left') ? 'me-3 ' : 'order-2 ms-3'" style="min-width:60px;">
														<UserPic :userId="task.user_id"
																 :agencyId="task.agency_id"
																 :userLogo="task.user_logo"
																 :userName="task.user_name"
																 :showIsOnline="true"
																 :logoSize="60"
																 className="d-flex rounded-circle border-1 border-dark mb-auto shadow" />
														<span v-if="!this.isEmpty(task.time)"
															  class="d-flex flex-column align-items-center text-center fs-5 text-font-secondary mx-auto mt-2 mb-auto"
															  v-text="task.time" />
													</div>
													<div class="col flex-fill">
														<div class="bg-white shadow container-fluid p-20 pe-3 pb-2 rounded-3"
															 style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);">
															<div class="d-flex align-items-start mb-1">
																<span v-if="task.type == 'file'" class="font-semi fw-semi ms-0 text-darkblue">Примечание</span>
																<span v-else-if="task.type == 'partner_id'" class="font-semi fw-semi ms-0 text-darkblue">Примечание</span>
																<span v-else-if="task.type == 'call' && task.event.owner == 1" class="font-semi fw-semi s-0o text-primary">Звонок собственнику</span>
																<span v-else-if="task.type == 'call'" class="font-semi fw-semi s-0o text-primary">Звонок</span>
																<span v-else-if="task.type == 'meet'" class="font-semi fw-semi ms-0 text-pink">Встреча</span>
																<span v-else-if="task.type == 'show'" class="font-semi fw-semi ms-0 text-fire">Показ</span>
																<span v-else-if="task.type == 'deal'" class="font-semi fw-semi ms-0 text-raspberry">Сделка</span>
																<span v-else-if="task.type == 'even'" class="font-semi fw-semi ms-0 text-ocean">{{ task.event.title }}</span>
																<span v-else-if="task.type == 'step'" class="font-semi fw-semi ms-0 text-lightred">Этап: &laquo;{{ task.event.title }}&raquo;</span>
																<span v-else-if="task.type == 'partner'" class="font-semi fw-semi ms-0 text-success">Партнер</span>
																<span v-else-if="task.type == 'pending_moderation'" class="font-semi fw-semi ms-0 text-purple">Отправлен на модерацию</span>
																<span v-else-if="task.type == 'accepted_moderation'" class="font-semi fw-semi ms-0 text-success">Модератор подтвердил рекламу</span>
																<span v-else class="font-semi fw-semi me-auto text-dark">Примечание</span>
																
																<sup v-if="task.event.is_cancel && this.inArray(task.type, ['call', 'show', 'meet', 'deal', 'even', 'pending_moderation']) && !task.event.is_completed"
																	  class="badge bg-danger text-white rounded-1 ms-2 fs-5 fw-normal me-auto" v-text="this.getCancelLabel(task.type)" />
																	  
																	  <input v-else-if="task.event.owner == 1" v-show="this.inArray(task.type, ['call'])"
																	   class="form-check-input rounded-1 ms-auto me-0"
																	   type="checkbox"
																	   :id="'event_'+task.id"
																	   value="1"
																	   @change="(event) => this.changeStatusEventOwner(task.real_type, task.id, Boolean(task.event.is_completed), index)"
																	   v-model="task.event.is_completed" />	  
																<input v-else v-show="this.inArray(task.type, ['call', 'meet', 'show', 'deal', 'even', 'pending_moderation'])"
																	   class="form-check-input rounded-1 ms-auto me-0"
																	   type="checkbox"
																	   :id="'event_'+task.id"
																	   value="1"
																	   :checked="Boolean(task.event.is_completed)" @change="(event) => this.changeStatusEvent(task.id, !Boolean(task.event.is_completed))" />
															</div>

															<div v-if="!this.isEmpty(task.event.title) && this.inArray(task.type, ['file'])" class="d-flex flex-column align-items-start font-semi fw-semi ms-0 text-black mb-2">
																{{ task.event.title }}
															</div>

															<div class="d-flex flex-column align-items-start">
																<div v-if="task.type == 'call'" class="d-flex flex-column mb-3">
																	<span v-if="task.event.is_tracking" class="fs-4 text-font-secondary mb-1">Позвонить c {{ task.event.schedule_date_to }} по {{ task.event.schedule_date }}</span>
																	<span v-else class="fs-4 text-font-secondary mb-1">Позвонить {{ task.event.schedule_date_string }}</span>
																</div>
																<div v-else-if="task.type == 'meet'" class="d-flex flex-column mb-3">
																	<span class="fs-4 text-font-secondary mb-1">Запланирована встреча</span>
																	<span class="fs-4">на {{ task.event.schedule_date_string }}</span>
																</div>
																<div v-else-if="task.type == 'show'" class="d-flex flex-column mb-3">
																	<span class="fs-4 text-font-secondary mb-1">Запланирована показ</span>
																	<span class="fs-4">на {{ task.event.schedule_date_string }}</span>
																</div>
																<div v-else-if="task.type == 'deal'" class="d-flex flex-column mb-3">
																	<span class="fs-4 text-font-secondary mb-1">Запланирована сделка</span>
																	<span class="fs-4">на {{ task.event.schedule_date_string }}</span>
																</div>
																<div v-else-if="task.type == 'even'" class="d-flex flex-column mb-3">
																	<span class="fs-4 text-font-secondary mb-1">Мероприятие состоится</span>
																	<span class="fs-4">{{ task.event.schedule_date_string }}</span>
																</div>
																<div v-else-if="task.type == 'step'" class="d-flex flex-column mb-3">
																	<span v-if="task.event.is_checklist" class="fs-4 text-font-secondary mb-1">Добавлено событие по чек листу:</span>
																	<span v-else-if="!task.event.is_checklist" class="fs-4 text-font-secondary mb-1">Закрыт со следующими событиями:</span>
																	<div class="d-flex flex-column mt-1 fs-4" style="word-break: break-word; white-space: pre-line;" v-html="fixLinks(task.event.comment)" />
																</div>

																<div v-if="!this.isEmpty(task.event.summ)" class="d-flex flex-column mb-3">
																	<span class="fs-4 text-font-secondary mb-1">Сумма сделки: </span>
																	<span class="fs-4">{{ task.event.summ }} руб.</span>
																</div>

																<div v-if="!this.isEmpty(task.event.address)" class="d-flex flex-column mb-3">
																	<span class="fs-4 text-font-secondary mb-1">Адрес: </span>
																	<span class="fs-4">{{ task.event.address }}</span>
																</div>

																<div v-if="!this.isEmpty(task.event.comment) && (task.type != 'step' && task.type != 'pending_moderation' && task.type != 'accepted_moderation')" class="d-flex flex-column mb-3">
																	<span v-show="task.type !== 'comment'" class="fs-4 text-font-secondary mb-1">Комментарий: </span>
																	<span class="fs-4" style="word-break: break-word; white-space: pre-line;" v-html="task.event.comment" />
																</div>

																<div v-if="!this.isEmpty(task.event.date_close) && (task.real_type == 'call_owner')" class="d-flex flex-column mb-3">
																	
																	<span class="fs-4 text-font-secondary mb-1">Закрыта {{ task.event.date_close }} со статусом {{ task.event.status_close_name }}</span>
																</div>

																<!-- event pending_moderation -->
																<div v-if="task.type == 'pending_moderation'" class="d-flex flex-column mb-3">
																	<span class="fs-5 text-font-secondary mb-1">Ответственный: {{ task.event.employee_name }}</span>
																	<span class="fs-4">Провести модерацию: {{ task.event.schedule_date }}</span>
																	<span class="fs-4">Выбранные площадки: {{ this.toFlatList(task.event.services_list) }}</span>
																	<span v-if="!this.isEmpty(task.event.comment)" class="fs-4">Комментарий: {{ task.event.comment }}</span>
																	<span v-if="!this.isEmpty(task.event.sub_comment.id)" class="fs-4 mt-2">
																		Комментарий к задаче: {{ task.event.sub_comment.comment }} <span class="text-font-secondary">({{ task.event.sub_comment.author }})</span><br/>
																		Ответственный: <span class="text-font-secondary">{{ task.event.sub_comment.user_for }}</span>
																		<span class="d-flex mt-0">
																			<a v-show="this.userCan(task.event.sub_comment.author_id).edit_sub_comment"
																			   href="javascript:{}" class="d-inline-flex fs-4 me-1 p-2 mt-2"
																			   @click="this.editSubComment(task.type, task.id, task.event.sub_comment)">
																				<img :src="require('@/assets/icons/pen-icon.svg')" alt="Редактировать" width="18" height="18">
																			</a>
																			<a v-show="this.userCan(task.event.sub_comment.author_id).delete_sub_comment"
																			   href="javascript:{}" class="d-inline-flex fs-4 me-1 p-2 mt-2"
																			   @click="this.deleteSubComment(task.type, task.id, task.event.sub_comment)">
																				<img :src="require('@/assets/icons/basket-icon.svg')" alt="Удалить" width="18" height="18">
																			</a>
																		</span>
																	</span>
																</div>
																<!-- /event pending_moderation -->
																<!-- event accepted_moderation -->
																<div v-else-if="task.type == 'accepted_moderation'" class="d-flex flex-column mb-3">
																	<span v-if="!this.isEmpty(task.event.accepted_date)" class="fs-4">{{ task.event.accepted_date }}</span>
																</div>
																<!-- /event accepted_moderation -->


																<!-- event file_list -->
																<div v-if="!this.isEmpty(task.event.file_list) && (task.type == 'file' || task.type == 'partner_id')" class="d-flex flex-column mb-3">
																	<span class="fs-4 text-font-secondary mb-1">Файлы: </span>
																	<div v-for="(file, index) in task.event.file_list"
																		 :key="index" class="d-inline-flex flex-column mb-3">
																		<a :href="file.path" :data-file-id="file.id"
																		   class="fs-4 text-black text-decoration-underline"
																		   target="_blank" :download="file.filename">
																			<img :src="require('@/assets/icons/blank-icon.svg')" alt="Файл" width="18" height="18" class="me-2">
																			{{ file.filename }}
																		</a>
																	</div>
																</div>
																<!-- /event file_list -->

																<!-- event records -->
																<div v-if="!this.isEmpty(task.event.records) && task.type == 'call'" class="d-flex flex-column mb-3">
																	<span class="fs-4 text-font-secondary mb-1">Звонки: </span>
																	<div v-for="(record, index) in task.event.records"
																		 :key="index" class="d-inline-flex flex-column mb-3">
																		<audio controls style="max-width: 100%;" @play="this.playAudio(record, $event)">
																			<source :src="record.source_path" />
																			<span class="fs-4">Браузер не поддерживает веб-аудио.</span>
																			<a :href="record.source_path"
																			   class="fs-4 text-black text-decoration-underline"
																			   target="_blank">Запись разговора</a>
																		</audio>
																	</div>
																</div>
																<!-- /event records -->

																<!-- event documents -->
																<div v-if="!this.isEmpty(task.event.document)" class="d-flex flex-column mb-3">

																	<div v-if="!this.isEmpty(task.event.document.parties)">
																		<div v-for="(part, index) in task.event.document.parties"
																			 :key="index" class="d-inline-flex flex-column mb-3">
																			<span class="fs-4 text-font-secondary mb-1">{{ index+1 }}-я сторона: </span>
																			<a href="javascript:{}"
																			   :data-client-id="part.client_id"
																			   :data-object-id="part.object_id"
																			   class="fs-4 text-black text-decoration-underline">
																				{{ part.title }}<span v-if="!this.isEmpty(part.contacts)">{{ ' ' + part.contacts }}</span><span v-else-if="!this.isEmpty(part.address)">{{ ', ' + part.address }}</span>
																			</a>
																		</div>
																	</div>
																	<div v-if="!this.isEmpty(task.event.document.name)" class="d-flex flex-column mb-3">
																		<span class="fs-4 text-font-secondary mb-2">Документ: </span>
																		<div class="d-flex align-items-start">
																			<img :src="require('@/assets/icons/blank-icon.svg')" alt="Документ" width="18" height="18" class="me-10">
																			<a :href="task.event.document.url"
																			   :data-doc-id="task.event.document.id"
																			   class="fs-4 text-black text-decoration-underline"
																			   :download="task.event.document.name">
																				{{ task.event.document.name }}
																			</a>
																			<!--																		<button class="btn btn-outline d-flex align-items-center justify-content-center" style="width: 40px; height: 40px;">
																				<img :src="require('@/assets/icons/menu-black-icon.svg')" alt="Документ" width="18" height="18" class="d-block">
																			</button>-->
																		</div>
																	</div>
																</div>
																<!-- /event documents -->

																<!-- event actions -->
																<div v-if="!this.isEmpty(task.user_id) && (!this.inArray(task.type, ['accepted_moderation']))"
																	 class="d-flex flex-row w-100 align-items-center mt-2">
																	<div v-show="this.userCan(task.user_id).edit || this.userCan(task.user_id).delete" class="d-flex align-items-center">
																		<button v-if="!task.event.is_cancel && this.userCan(task.user_id).edit"
																				type="button"
																				class="crm-btn btn bg-light-gray rounded-circle ms-0 me-2" @click="this.editEvent(task.id, task.real_type)">
																			<img :src="require('@/assets/icons/options-icon.svg')" alt="Настройка" width="24" height="24">
																		</button>
																		<button v-if="this.inArray(task.type, [/*'call', 'meet', 'show', 'deal',*/ 'pending_moderation'])" type="button"
																				class="crm-btn btn bg-light-gray rounded-circle icon-color-grays ms-0 me-2"
																				@click="this.showSubCommentForm(task.id, task.type, task.user_id, !this.isEmpty(task.event.sub_comment) ? task.event.sub_comment.id : null)">
																			<img :src="require('@/assets/icons/add-icon-gray.svg')" alt="Добавить комментарий к задаче" width="18" height="18">
																		</button>
																		<template v-if="(task.event.owner==0 || (task.event.owner==1 && !this.isEmpty(task.event.date_close)))">
																			<button v-if="this.userCan(task.user_id).delete"
																					type="button"
																					class="crm-btn btn bg-light-gray rounded-circle ms-0 me-2" @click="this.deleteEvent(task.id)">
																				<img :src="require('@/assets/icons/basket-icon.svg')" alt="Удалить" width="24" height="24">
																			</button>
																		</template>
																	</div>
																	<div v-if="this.inArray(this.section, ['clients', 'requisitions']) && !task.event.is_cancel && (this.userCan(task.user_id).cancel_meet && this.inArray(task.type, ['call', 'show', 'meet', 'deal', 'even'])) && !task.event.is_completed"
																		 class="d-flex flex-column ms-auto">
																		<button
																			class="btn btn-outline-danger py-2 rounded-3"
																			@click="this.cancelEvent(task.id)">
																			Отменить
																		</button>
																	</div>
																</div>
																<!-- /event actions -->
															</div>
															<div class="d-flex align-content-end mt-2">
																<span class="ms-auto d-inline-flex text-info fs-5">Ответственный {{ task.who_work }}</span>
																<span class="ms-auto d-inline-flex text-info fs-5">Добавил(а) {{ task.user_name }}</span>
																
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div v-show="this.sub_comment.is_show" class="position-fixed start-0 end-0 bottom-0 bg-white shadow p-4" style="z-index: 115">
											<div class="d-flex row">
												<div class="col">

													<button type="button"
															class="position-absolute d-flex ms-auto align-self-end btn-close order-0"
															style="top: 1rem; right: 1rem;"
															aria-label="Close" @click="this.sub_comment.is_show = false"></button>

													<div class="d-inline-flex col-form-label">
														<span class="font-semi fw-semi s-0">Комментарий к задаче</span>
														<span v-if="this.sub_comment.task_type == 'call'" class="font-semi fw-semi ms-1 text-primary">о Звонке</span>
														<span v-else-if="this.sub_comment.task_type == 'meet'" class="font-semi fw-semi ms-1 text-pink">о Встрече</span>
														<span v-else-if="this.sub_comment.task_type == 'show'" class="font-semi fw-semi ms-1 text-fire">о Показе</span>
														<span v-else-if="this.sub_comment.task_type == 'deal'" class="font-semi fw-semi ms-1 text-raspberry">о Сделке</span>
														<span v-else-if="this.sub_comment.task_type == 'pending_moderation'" class="font-semi fw-semi ms-1 text-purple">о Модерации</span>
													</div>

													<SelectBox v-show="this.sub_comment.task_type == 'pending_moderation'" inputLabelClass="text-font-secondary mb-3"
															   inputId="sub_comment.employee"
															   inputName="sub_comment.employee"
															   inputLabel="Ответственный:"
															   :inputOptions="this.employees_list"
															   inputClass="rounded-1 p-2"
															   inputWrapClass="mb-20"
															   :inputValue="this.sub_comment.employee_id"
															   @setValue="(value) => this.sub_comment.employee_id = value" />

													<TextArea inputLabelClass="text-font-secondary mb-3"
															  inputId="sub_comment.employee"
															  inputName="sub_comment.employee"
															  inputLabel="Комментарий:"
															  inputClass="rounded-1 p-2"
															  inputWrapClass="mb-20"
															  :inputValue="this.sub_comment.text"
															  @setValue="(value) => this.sub_comment.text = value" />

													<button type="button"
															class="btn btn-secondary col me-2 rounded-3 p-3 fs-3"
															@click="this.showSubCommentForm()">
														Отмена
													</button>
													<button type="button"
															class="btn btn-primary col ms-2 rounded-3 p-3 text-white fs-3"
															@click="this.submitSubComment()"
															:disabled="this.isEmpty(this.sub_comment.employee_id) || this.isEmpty(this.sub_comment.text)">
														Отправить
													</button>
												</div>
											</div>
										</div>
									</div>
									<div v-else-if="this.isEmpty(this.tasks)" class="day-content d-flex flex-column flex-fill align-items-center">
										<EmptyList message="Задачи не найдены" />
									</div>
									<div v-else class="day-content d-flex flex-column flex-fill align-items-center">
										<Loader />
									</div>
									<!-- /EventCard -->

									<div v-if="this.section == 'objects' && this.item.is_archived == 1" class="day-content d-flex flex-column flex-fill align-items-center mt-3 mb-4">
<!--										<span class="text-font-secondary mb-20"
											  v-text="this.formatDateTime(this.item.created_at)" />-->
										<span class="bg-raspberry fs-4 font-semi fw-semi px-20 py-10 rounded-1 text-white">Перемещён в архив</span>
									</div>
									<div v-else-if="this.section == 'clients' && this.item.is_deleted == 1" class="day-content d-flex flex-column flex-fill align-items-center mt-3 mb-4">
<!--                                        <span class="text-font-secondary mb-20"
											  v-text="this.formatDateTime(this.item.published_at)" />-->
										<span class="bg-raspberry fs-4 font-semi fw-semi px-20 py-10 rounded-1 text-white">Клиент закрыт</span>
									</div>
									<div v-else-if="this.section == 'requisitions' && this.item.is_deleted == 1" class="day-content d-flex flex-column flex-fill align-items-center mt-3 mb-4">
<!--                                        <span class="text-font-secondary mb-20"
											  v-text="this.formatDateTime(this.item.created_at)" />-->
										<span class="bg-raspberry fs-4 font-semi fw-semi px-20 py-10 rounded-1 text-white">Заявка закрыта</span>
									</div>

									<!-- -->

                                    <div class="position-sticky bottom-0 bg-white d-flex align-items-center justify-content-start p-10 p-sm-20 border-top border-brd-primary"
										 style="box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);z-index: 111;">
                                        <div class="input-group">
                                            <div v-if="!this.isEmpty(this.clientHistoryMenu)"
												 class="dropdown dropup order-1">
                                                <button class="crm-btn d-flex align-items-center justify-content-center btn btn-primary dropdown-toggle rounded-circle me-10 me-sm-20"
														type="button"
														id="clientHistoryMenu"
														data-bs-toggle="dropdown"
														aria-expanded="false"
														aria-label="Открыть меню">
                                                    <img :src="require('@/assets/icons/add-icon.svg')" alt="Открыть" width="14" height="14" class="d-block">
                                                </button>
                                                <div class="dropdown-menu shadow m-0 p-0 rounded-3 border-0 mb-3"
													 aria-labelledby="clientHistoryMenu">
                                                    <img :src="require('@/assets/icons/dropdown-side-arr-icon.svg')" alt="" width="18" height="14" aria-hidden="true"
														 style="position: absolute; bottom: -9px; left: 14px; transform: rotate(90deg);">
                                                    <ul class="m-0 p-0"
														style="z-index: 10000; box-shadow: 0px 0px 44px rgba(0, 0, 0, 0.1);">
                                                        <li v-for="(item, index) in this.clientHistoryMenu"
															:key="index"
															class="border-bottom border-brd-primary">
                                                            <a href="javascript:{}"
															   class="p-10 d-flex align-items-center btn btn-outline dropdown-item"
															   @click="item.action">
                                                                <div class="d-flex align-items-center justify-content-center rounded-circle me-10"
																	 :class="item.className"
																	 style="width:34px; height: 34px;">
                                                                    <img :src="item.icon" alt="Открыть" width="16" height="16" class="d-block">
                                                                </div>
                                                                <span class="font-semi fw-semi">{{ item.label }}</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <textarea type="text"
													  class="form-control rounded-1 me-10 order-2"
													  style="flex-shrink: 1;"
													  ref="commentInput"
													  rows="1"
													  v-model="this.comment_input"
													  aria-label="Введите сообщение" />
											<button v-if="!this.isEmpty(this.current_event_id)" class="crm-btn d-flex align-items-center justify-content-center btn btn-danger rounded-circle me-10 me-sm-20 order-3"
													type="button"
													@click="this.newEventReset()">
												<img :src="require('@/assets/icons/removed.svg')" alt="Отмена" width="14" height="14" class="d-block">
											</button>
                                            <button type="button"
													aria-label="Оставить комментарий"
													class="crm-btn btn btn-outline-secondary bg-light-gray border ms-auto border-brd-primary rounded-3 order-3"
													@click="this.sendEventComment()">
                                                <img :src="require('@/assets/icons/tg-icon.svg')" alt="Оставить комментарий" width="24" height="24">
                                            </button>
                                            <button v-if="this.section == 'clients' || this.section == 'requisitions'"
													type="button"
													aria-label="Написать в WhatsApp"
													@click="this.sendToMessanger('whatsapp')"
													class="crm-btn btn btn-outline-secondary bg-light-gray ms-10 border border-brd-primary rounded-3 order-4">
                                                <img :src="require('@/assets/icons/whatsapp-icon.svg')" alt="Иконка WhatsApp" width="24" height="24">
                                            </button>
                                            <button v-if="this.section == 'clients' || this.section == 'requisitions'"
													type="button"
													aria-label="Написать в Telegram"
													@click="this.sendToMessanger('telegram')"
													class="crm-btn btn btn-outline-secondary bg-light-gray ms-10 border border-brd-primary rounded-3 order-4">
                                                <img :src="require('@/assets/icons/telegram-icon.svg')" alt="Иконка Telegram" width="24" height="24">
                                            </button>
                                        </div>
                                    </div>

								</div>
                                <div class="tab-pane fade border-top border-brd-primary"
									 id="variants"
									 ref="variants-tab"
									 role="tabpanel"
									 aria-labelledby="Вкладка предложенные варианты">

									<div class="d-none input-group mb-20">
										<input type="text" class="form-control rounded-end rounded-1" placeholder="Поиск по презентациям" aria-label="Поиск по презентациям" aria-describedby="button-addon2">

										<button type="button" aria-label="Поиск" id="button-addon2" class="crm-btn btn bg-primary me-10 rounded-start rounded-1">
											<img :src="require('@/assets/icons/search-white-icon.svg')" alt="Поиск" width="18" height="18">
										</button>
									</div>
									<ul v-if="!this.isEmpty(this.variants)"
										class="list-unstyled px-20 pt-2 row-fluid row-cols-1">

										<li v-for="(item, index) in this.variants"
											:key="index"
											:date-string="date_string"
											:date-group="date_group"
											class="col mb-10">
											<div class="day-content">

												<div v-if="item.is_new || (item.date_string !== date_string && 'variants_'+index !== date_group)"
													 class="d-inline-block w-100 mt-2 mb-2"
													 data-bs-toggle="collapse"
													 :data-bs-target="date_group = '#variants_'+index"
													 role="button"
													 aria-expanded="false">
													<img :src="require('@/assets/icons/mail-icon.svg')" alt="Иконка почты" width="16" height="16" class="me-10">
													<span v-text="date_string = item.date_string" />
												</div>

												<div class="collapse"
													 :id="date_group.replace('#', '')"
													 data-bs-parent="#variants"
													 :data-object-id="item.object_id"
													 :data-object-title="item.object_title"
													 :data-object-address="item.object_address">

													<button v-if="item.is_new || item.date_string !== date_string" class="d-flex align-items-center btn btn-outline-gray bg-light-gray rounded-3 py-10 px-20 mb-20"
															@click="this.addObjectsToPDF(date_group, $event)">
														<span class="text-secondary font-semi fw-semi me-10">Отправить все презентации</span>
														<img :src="require('@/assets/icons/send-icon.svg')" alt="Отправить" width="18" height="18">
													</button>

													<div class="btn-group align-items-stretch rounded-3" role="group">
														<a v-if="item.is_newbuilding" :href="item.path"
														   target="_blank"
														   class="btn d-flex fs-4 text-font-secondary border text-start rounded-end rounded-3 border-brd-primary p-10">
															{{ item.time_string }} [{{ item.sended_type }}] → Новостройка от {{ item.object_owner }}, {{ item.object_phone }}
														</a>
														<a v-else :href="item.path"
														   target="_blank"
														   class="btn d-flex fs-4 text-font-secondary border text-start rounded-end rounded-3 border-brd-primary p-10">
															{{ item.time_string }} [{{ item.sended_type }}] → {{ item.filename }}
														</a>
														<button v-if="!item.is_sended"
																class="btn d-flex align-items-center btn  rounded-start rounded-3 p-10"
																:class="this.inArray(item.object_id, this.$store.getters.toPDFList.map(object => object.id)) ? 'btn-success text-white icon-color-white' : 'btn-outline-gray bg-light-gray'"
																@click="this.addObjectToPDF({
																	object_id: item.object_id,
																	object_title: item.object_title,
																	object_address: item.object_address
																}, $event)">
															<img :src="require('@/assets/icons/send-icon.svg')" alt="Отправить" width="18" height="18">
														</button>
													</div>

												</div>

											</div>
										</li>
									</ul>
									<div v-else-if="this.isEmpty(this.variants)" class="px-20 pt-2 row-fluid row-cols-1">
										<EmptyList message="Нет отправленных вариантов" />
									</div>
									<div v-else class="px-20 pt-2 row-fluid row-cols-1">
										<Loader />
									</div>
                                </div>
                                <div class="tab-pane fade bg-white border-top border-brd-primary"
									 id="history"
									 ref="history-tab"
									 role="tabpanel"
									 aria-labelledby="Вкладка история">
                                    <ul v-if="!this.isEmpty(this.history)" class="list-unstyled px-20 pt-2 row-fluid row-cols-1">
                                        <li v-for="(item, index) in this.history"
											:key="index"
											:date-string="date_string"
											class="col mb-10">
                                            <div class="day-content mb-10">
                                                <div v-if="item.date_string !== date_string" class="d-inline-block w-100 mt-2 mb-2">
                                                    <img :src="require('@/assets/icons/mail-icon.svg')" alt="Иконка почты" width="16" height="16" class="me-10">
                                                    <span v-text="date_string = item.date_string" />
                                                </div>
                                                <span class="d-inline-block w-100 fs-4 text-font-secondary border border-brd-primary p-10 rounded-1">
                                                        {{ item.time_string }} → <span v-html="item.event" />
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
									<div v-else-if="this.isEmpty(this.history)" class="px-20 pt-2 row-fluid row-cols-1">
										<EmptyList message="В истории пока пусто" />
									</div>
									<div v-else class="px-20 pt-2 row-fluid row-cols-1">
										<Loader />
									</div>
                                </div>
                                <div class="tab-pane fade bg-light-gray border-top border-brd-primary"
									 id="notes"
									 ref="notes-tab"
									 role="tabpanel"
									 aria-labelledby="Вкладка заметок">

									<!-- NoteCard -->
									<div v-if="!this.isEmpty(this.notes)" class="day-content d-flex flex-column flex-fill align-items-center">
										<div v-for="(note, index) in this.notes"
											 :key="note.id"
											 class="day-content container-fluid px-0 mb-2"
											 :data-id="note.id"
											 :data-num="index+1">
											<div v-if="!this.isEmpty(note.chat_date)" class="d-flex col flex-column w-100 flex-fill align-items-center mt-10 p-10 p-sm-20">
												<span class="text-font-secondary">{{ note.chat_date }}</span>
											</div>
											<div class="d-flex col flex-column w-100 flex-fill align-items-center"
												 :class="this.isEmpty(note.chat_date) ? ((this.current_note_id == note.id) ? 'mt-20 opacity-50' : 'mt-20') : ((this.current_note_id == note.id) ? 'opacity-50' : '')"
												 :ref="'note_'+note.id"
												 :data-type="note.type">
												<div class="d-flex row-cols-auto w-100 px-20">
													<div class="col"
														 :class="(note.chat_position == 'left') ? 'me-3 ' : 'order-2 ms-3'" style="min-width:60px;">

														<UserPic :userId="note.user_id"
																 :agencyId="note.agency_id"
																 :userLogo="note.user_logo"
																 :userName="note.user_name"
																 :showIsOnline="true"
																 :logoSize="60"
																 className="d-flex rounded-circle border-1 border-dark mb-auto shadow" />

														<span v-if="!this.isEmpty(note.time)"
															  class="d-flex flex-column align-items-center text-center fs-5 text-font-secondary mx-auto mt-2 mb-auto"
															  v-text="note.time" />
													</div>
													<div class="col flex-fill">
														<div class="bg-white shadow position-relative container-fluid p-20 pe-3 pb-2 rounded-3"
															 style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);">
															<div class="d-flex flex-column align-items-start">

																<div v-if="!this.isEmpty(note.comment)" class="d-flex flex-column">
																	<span class="fs-4" style="word-break: break-word; white-space: pre-line;" v-html="note.comment" />
																</div>

																<!-- event actions -->
																<div v-if="!this.isEmpty(note.user_id)" class="d-flex flex-row align-items-center mt-2">
																	<div v-show="this.userCan(note.user_id).edit || this.userCan(note.user_id).delete" class="d-flex align-items-center">
																		<button v-if="this.userCan(note.user_id).edit"
																				type="button"
																				class="crm-btn btn bg-light-gray rounded-circle me-10" @click="this.editNote(note.id)">
																			<img :src="require('@/assets/icons/options-icon.svg')" alt="Настройка" width="24" height="24">
																		</button>
																		<button v-if="this.userCan(note.user_id).delete"
																				type="button"
																				class="crm-btn btn bg-light-gray rounded-circle me-10" @click="this.deleteNote(note.id)">
																			<img :src="require('@/assets/icons/basket-icon.svg')" alt="Удалить" width="24" height="24">
																		</button>
																	</div>
																</div>
																<!-- /event actions -->
															</div>
															<div class="d-flex align-content-end mt-2">
																<span class="ms-auto d-inline-flex text-info fs-5">{{ note.user_name }}</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div v-else-if="this.isEmpty(this.notes)" class="day-content d-flex flex-column flex-fill align-items-center">
										<EmptyList message="Примечания не найдены" />
									</div>
									<div v-else class="day-content d-flex flex-column flex-fill align-items-center">
										<Loader />
									</div>
									<!-- /NoteCard -->

									<div class="position-sticky bottom-0 bg-white d-flex align-items-center justify-content-start p-10 p-sm-20 border-top border-brd-primary"
										 style="box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);z-index: 111;">
										<div class="input-group">
											<textarea type="text"
												   class="form-control rounded-1 me-10 me-sm-20 order-1"
												   style="flex-shrink: 1;"
												   ref="noteInput"
												   v-model="this.note_input"
												   aria-label="Введите сообщение" />
											<button type="button"
													aria-label="Оставить примечание"
													class="crm-btn btn btn-outline-secondary bg-light-gray border border-brd-primary rounded-3 order-2"
													@click="this.sendObjectNote()">
												<img :src="require('@/assets/icons/save-icon.svg')" alt="Оставить примечание" width="24" height="24">
											</button>
										</div>
									</div>

								</div>
                            </div>
                        </div>
                    </div>
					<div v-show="!this.is_state_ready" class="modal-body p-0">
						<Loader />
					</div>
                </div>
            </div>
        </div>

		<NewTaskModal ref="taskModal"
					  :objectId="this.object_id"
					  :clientId="this.client_id"
					  :requisitionId="this.requisition_id"
					  :statusId="this.status_id"
					  :eventId="this.current_event_id"
					  :eventType="this.current_event_type"
					  :eventSection="this.section"
					  :isCheckOwner="this.is_check_owner"
					  @newTasksClosed="this.newEventReset()"
					  @updateTasksList="(list) => this.updateTasksList(list)"
					  @updateHistoryList="(list) => this.updateHistoryList(list)" />

        <!--Чек-лист-->
        <CheckListModal />

		<DocumentsListModal />
		<ResultsModal id="addTaskResultModal"
				  :state="this.resultsModalState.state"
				  :title="this.resultsModalState.title"
				  :message="this.resultsModalState.message"	/>

    </div>
</template>

<script>
    import NavTabs from "@/components/common/NavTabs"
    import CheckListModal from "@/components/modals/CheckListModal"
    import AutoSearchResultModal from "@/components/modals/AutoSearchResultModal"
    import CommonService from "@/services/CommonService";
	import api from "@/api";
	import Switcher from "@/components/inputs/Switcher";
	import Loader from "@/components/common/Loader";
	import EmptyList from "@/components/common/EmptyList";
	import NewTaskModal from "@/components/modals/NewTaskModal";
	import UserPic from "@/components/common/UserPic";
	import {CommonDataService} from "@/services/CommonDataService";
	import DocumentsListModal from "@/components/modals/DocumentsListModal";
	import SelectBox from "@/components/inputs/SelectBox";
	import TextArea from "@/components/inputs/TextArea";
	import ResultsModal from "@/components/modals/ResultsModal";

    export default {
        name: 'HistoryTasksModal',
        props: {
            fromSection: { type: String },
			fromAction: { type: String },
			injectData: { type: Object },
            tempBlockTitle : {
				type: String,
				default: 'История и задачи'
			},
			objectId: null,
			clientId: null,
			requisitionId: null,
			statusId: null
        },

        components: {
			TextArea,
			SelectBox,
			DocumentsListModal,
			UserPic,
			NewTaskModal,
			EmptyList,
			Loader,
			Switcher,
            NavTabs,
            CheckListModal,
			ResultsModal
        },

        data() {
            return {
				resultsModalState: {
					state: '',
					title: '',
					message: ''
				},
				section: (typeof this.$props.fromSection !== "undefined") ? this.$props.fromSection : null,
				object_id: (typeof this.$props.objectId !== "undefined") ? this.$props.objectId : null,
				client_id: (typeof this.$props.clientId !== "undefined") ? this.$props.clientId : null,
				requisition_id: (typeof this.$props.requisitionId !== "undefined") ? this.$props.requisitionId : null,
				status_id: (typeof this.$props.statusId !== "undefined") ? this.$props.statusId : null,
				action: this.$props.fromAction ?? null,
				modal: null,
				item: {
					autosearch: {
						filter: null
					},
					fields: null
				},
				current_event_id: null,
				current_event_type: null,
				current_note_id: null,
				is_state_ready: false,
				is_potential_show: false,
				is_check_owner: false,
                tabItems: [],
				clientHistoryMenu: [],
				comment_input: '',
				note_input: '',
				tasks: [],
				objects: [],
				variants: [],
				history: [],
				notes: [],
				errors: [],
				links: {
					whatsapp: 'whatsapp://send?text={message}&phone=+{phone}',
					telegram: 'tg://msg?text={message}&to=+{phone}',
				},
				stage_client_id: null,
				stage_requisition_id: null,
				stage_funnel_id: null,
				stage_id: null,
				stage_name: null,
				roles_list: [],
				types_list: [],
				sources_list: [],
				partners_list: [],
				employees_list: [],
				sub_comment: {
					id: null,
					task_type: null,
					is_show: false,
					employee_id: null,
					text: null,
					task_id: null,
					user_id: null,
				},
				confirmModalState: {
					id: null,
					title: '',
					text: '',
					recipient: '',
					fromSection: null,
					action: null,
					btnText: ''
				},
            }
        },

        methods: CommonService.mergeRecursive({
          fixLinks(comment) {
            if (comment.includes('"/download_file.php')) {
              return comment.replace(/"\/download_file.php/, '"https://crm.intermarkproperty.ru//download_file.php', 'g');
            } else {
              return comment;
            }
          },
			hideModal() {

				if (this.modal)
					this.modal.hide();

			},
			showModal() {

				if (this.modal)
					this.modal.show();

			},
			formatPhone(value) {
				return CommonService.formatPhoneNumber(value);
			},
			alertNote(message) {
				return alert(message);
			},
			isEmpty(data) {
				return CommonService.isEmpty(data);
			},
			inArray(needle, haystack) {
				return CommonService.inArray(needle, haystack);
			},
			formatDate(format, datetime) {
				return CommonService.formatDateTime(datetime, 'ru', format);
			},
			formatPrice(value, currency, sign) {
				return CommonService.formatPrice(value, currency, sign);
			},
			sortArray(data) {
				return CommonService.sortArray(data);
			},
			editSubComment(task_type, event_id, sub_comment) {
				this.sub_comment.task_type = task_type;
				this.sub_comment.task_id = event_id;
				this.sub_comment.id = sub_comment.id;
				this.sub_comment.text = sub_comment.comment;
				this.sub_comment.user_id = this.$store.getters.userInfo.id;
				this.sub_comment.employee_id = sub_comment.user_for_id;
				this.sub_comment.is_show = true;
			},
			deleteSubComment(task_type, event_id, sub_comment) {
				this.sub_comment = sub_comment;
				this.sub_comment.task_type = task_type;
				this.sub_comment.task_id = event_id;
				if (confirm('Вы действительно желаете удалить комментарий к задаче?')) {
					api.delete('/common/sub-comment', {
						params: {
							section: this.section,
							event_id: this.sub_comment.task_id,
							sub_id: this.sub_comment.id || null
						}
					}).then((response) => {

						if (process.env.NODE_ENV == "development") {
							console.debug('deleteSubComment()::axios', {
								response: response.data
							});
						}

						if (response.status == 200 && response.data.success) {

							if (this.section == 'objects') {
								this.getObjectTasks(this.object_id, false);
							} else if (this.section == 'clients') {
								this.getClientTasks(this.client_id, false);
							} else if (this.section == 'requisitions') {
								this.getRequisitionTasks(this.requisition_id, false);
							}

							this.goToIntoView();
						}

					}).catch(function (error) {

						CommonService.log('error', 'deleteSubComment()::axios', error);

					});
				}
			},
			showSubCommentForm(task_id, task_type, user_id, sub_id) {
				this.sub_comment.id = sub_id;
				this.sub_comment.task_id = task_id;
				this.sub_comment.task_type = task_type;
				this.sub_comment.user_id = user_id;
				this.sub_comment.employee_id = null;
				this.sub_comment.is_show = !this.sub_comment.is_show;

				if (this.sub_comment.is_show && task_type == 'pending_moderation')
					this.getEmployees(task_id, this.object_id, sub_id).then(list => this.employees_list = list);

			},
			submitSubComment() {
				this.sendEventSubComment();

				this.sub_comment.id = null;
				this.sub_comment.task_id = null;
				this.sub_comment.task_type = null;
				this.sub_comment.user_id = null;
				this.sub_comment.employee_id = null;
				this.sub_comment.text = null;
				this.sub_comment.is_show = false;

				if (this.section == 'objects') {
					this.getObjectTasks(this.object_id, false);
				} else if (this.section == 'clients') {
					this.getClientTasks(this.client_id, false);
				} else if (this.section == 'requisitions') {
					this.getRequisitionTasks(this.requisition_id, false);
				}
			},
			toFlatList(list) {
				let text = '';
				list.forEach(item => text = text + (!this.isEmpty(text) ? ', ' : '') + item);
				return text;
			},
			playAudio(audio, event) {

				CommonService.log('info', {audio, event});

				/*if (!this.isEmpty(audio) && !this.isEmpty(event.target)) {
					let modal = CommonService.getModal('historyTasksModal');
					if (modal) {

						CommonService.log('info', modal._dialog.querySelectorAll('audio'));

						let items = modal._dialog.querySelectorAll('audio');
						items.forEach(item => {
							item.currentTime = 0;
							item.pause();
						});
					}

					CommonService.debounce(() => {
						event.target.currentTime = 0;
						event.target.play();
					});
				}*/
			},
			formatDateTime(datetime, locale, format) {
				return CommonService.formatDateTime(datetime, locale, format);
			},
			hideAndGoToRoute(path) {

				CommonService.log('info', path);

				let modal = CommonService.getModal('historyTasksModal');
				if (modal) {
					modal.hide();

					if (!this.isEmpty(this.item.id))
						path = path + '#item_' + this.item.id.toString();

					this.$router.push(path);
				}
			},
			fillConfirmModalState(str) {
				if(str === 'restoreClients') {
					this.confirmModalState.title = "Восстановление клиента";
					this.confirmModalState.text = "Вы действительно хотите восстановить выбранного клиента?";
					this.confirmModalState.action = 'restoreClients';
					this.confirmModalState.btnText = "Подтвердить";
				} else if(str === 'restoreRequisition') {
					this.confirmModalState.title = "Восстановление заявки";
					this.confirmModalState.text = "Вы действительно хотите восстановить выбранную заявку?";
					this.confirmModalState.action = 'restoreRequisition';
					this.confirmModalState.btnText = "Подтвердить";
				}
			},
			getRoles(section) {
				return api.get('/common/roles', {
					params: {
						section: section
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getRoles()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, role]) => {
								list[role.id.toString()] = role.name;
							});
						}
						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getRoles()::axios', error);

				});
			},
			getTypes(section) {
				if (typeof section !== "undefined") {
					return api.get('/common/types', {
						params: {
							section: section
						}
					})
					.then((response) => {

						CommonService.log('debug', 'getTypes()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {
							let list = [];
							Object.entries(response.data.list).forEach(([key, type]) => {
								list[type.id.toString()] = type.name;
							});

							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getTypes()::axios', error);

					});
				}
			},
			getPartners() {
				return api.get('/partners/list', {
					params: {
						filters: {
							only_active: 1,
							with_managers: 1
						}
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getPartners()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							let groups = (response.data.groups) ? response.data.groups : [];
							Object.entries(response.data.list).forEach(([key, partner]) => {
								let group_id = partner.group_id ?? null;
								list.push({
									value: partner.id.toString(),
									name: partner.name,
									company: (!this.isEmpty(groups[group_id])) ? groups[group_id] : null,
								});
							});
						}

						return list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getPartners()::axios', error);

				});
			},
			getSources() { // @todo: need to remove, implement in `CommonDataService`

				let _this = this;
				return api.get('/common/sources', {
					params: {}
				})
					.then((response) => {

						CommonService.log('debug', 'getSources()::axios', {response: response.data});

						if (response.status == 200 && response.data.success) {

							let list = [];
							if (response.data.list) {
								Object.entries(response.data.list).forEach(([key, source]) => {
									list.push({
										value: source.id.toString(),
										name: source.name
									});
								});
							}

							CommonService.log('info', 'HistoryTasksModal::getSources()', list);

							if (typeof _this.$store !== "undefined")
								_this.$store.commit('setSourcesList', list);

							return list;
						} else {
							return {};
						}

					}).catch(function (error) {

						CommonService.log('error', 'getSources()::axios', error);

					});
			},
			getPotentialShow() {
				let _this = this;
				setTimeout(function () {

					if (!_this.isEmpty(_this.section)) {
						_this.is_potential_show = (_this.section == 'objects' && !_this.item.add_user_id);
					}

				}, 600);
			},
			setObjectPotential(object_id) {
				api.put('/object/potential', {
					object_id: object_id
				}).then((response) => {
					CommonService.log('debug', 'setObjectPotential()::axios', {response});

					if (response.status == 200 && response.data.success)
						this.item.is_potential = response.data.is_potential;

				}).catch(function (error) {

					this.item.is_potential = 0;

					CommonService.log('error', 'setObjectPotential()::axios', error);

				});
			},
			setAndValidate(name, value) {

				if (this.section == "objects" && name == 'is_potential') {
					this.setObjectPotential(this.object_id);
					return;
				}

				let errors = [];
				let parent = null;
				let child = null;
				let parts = name.split('.', 2);
				if (parts.length == 2) {
					parent = parts[0];
					child = parts[1];
				}

				// Валидация значения
				if (this.$refs[name] && typeof this.$refs[name] !== "undefined") {
					/*if ('invalid' in this.$refs[name])
						this.$refs[name].invalid = false;*/

					if (this.$refs[name].inputLabel) {
						let label = this.$refs[name].inputLabel.replace(/:+$/, '');

						if (this.$refs[name].required && value.toString() == '0')
							errors.push('Поле `' + label + '` обязательно к заполнению!');
						else if (this.$refs[name].required && this.isEmpty(value))
							errors.push('Поле `' + label + '` обязательно к заполнению!');

						this.errors[name] = {
							name: name,
							label: label,
							errors: errors
						};

						if (!this.isEmpty(errors)) {
							this.$refs[name].valid = false;
							this.$refs[name].invalid = errors.join('<br/>');
						} else {
							this.$refs[name].valid = true;
							this.$refs[name].invalid = false;
						}

					}
				}

				// Установка значения
				if (parent && child) {
					if (!this.isEmpty(this.item[parent])) {
						if (typeof (this.item[parent][child]) !== "undefined") {
							this.item[parent][child] = value;
							
						} else {
							this.item[parent] = {
								...{
									[child]: value
								},
								...this.item[parent]
							};
						}
					} else {
						this.item[parent] = {
							[child]: value
						};
					}
				} else if (parent == null && name) {
					this.item[name] = value;
				} else {
					if (parent && child)
						this.item[parent][child] = null;
					else
						this.item[name] = null;
				}

				CommonService.log('debug', 'setAndValidate()', { name: name, value: value, errors: errors });

			},
			getCancelLabel(event_type) {
				if (this.inArray(event_type, ['call', 'show']))
					return 'Отменен';
				else if (this.inArray(event_type, ['meet', 'deal', 'even']))
					return 'Отменена';

				return 'Отменено';
			},
			getObject(object_id) {

				let only_agency = false;

				this.emitter.emit("global.startGetObject", { ref: 'HistoryTasksModal::getObject()', object_id, only_agency });

				return api.get('/object', {
					params: {
						object_id: object_id,
						only_agency: only_agency,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getObject()::axios', {response: response.data});

					this.emitter.emit("global.endGetObject", { response: response });

					if (response.status == 200 && response.data.success)
						return response.data.item;

					return null;
				}).catch(function (error) {

					CommonService.log('error', 'getObject()::axios', error);
					this.emitter.emit("global.endGetObject", { error: error });

				});
			},
			async getClient(client_id) {
				return api.get('/client', {
					params: {
						client_id: client_id,
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getClient()::axios', {response: response.data});

					let client = null;
					if (response.status == 200 && response.data.success)
						client = response.data.item;

					return client;
				}).catch(function (error) {

					CommonService.log('error', 'getClient()::axios', error);

				});
			},
			async getRequisition(requisition_id) {
				return api.get('/requisition', {
					params: {
						requisition_id: requisition_id,
					}
				}).then((response) => {

					CommonService.log('debug', 'getRequisitions()::axios', {response: response.data});

					let requisition = null;
					if (response.status == 200 && response.data.success) {
						requisition = response.data.item;
					}

					return requisition;
				}).catch(function (error) {

					CommonService.log('error', 'getRequisitions()::axios', error);

				});
			},
            initPopovers() {

				let containers = document.querySelectorAll('.popover-container');
				if (!this.isEmpty(containers)) {
					let selector = '[data-bs-toggle="popover"]'
					containers.forEach(container => {
						CommonService.popoversInit(container, selector)
					})
				}
            },
			tabIsShow(tab_id) {
				//return (this.$refs.tabItems.activeItem == tab_id) || false;
				return true;
            },
			getEmployees(event_id, object_id, sub_id) {

				let _this = this;
				return api.get('/adverts/moderators', {
					params: {
						event_id: event_id,
						object_id: object_id,
						sub_id: sub_id,
						section: 'events'
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getEmployees()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {

						let list = [];
						if (response.data.list) {
							Object.entries(response.data.list).forEach(([key, employee]) => {
								list.push({
									value: employee.id.toString(),
									name: (employee.department !== 'null') ? employee.name : employee.name + ' (' + employee.department + ')'
								});
							});
						}

						return list;
					} else {
						return {};
					}
				}).catch(function (error) {
					CommonService.log('error', 'getEmployees()::axios', error);
				});
			},
			userCan(user_id) {

				if (typeof user_id == "undefined")
					user_id = null;

				return {
					cancel_meet: true,
					edit: (user_id && user_id == this.$store.getters.userInfo.id),
					sub_comment: (user_id && user_id == this.$store.getters.userInfo.id),
					delete: (user_id && user_id == this.$store.getters.userInfo.id),
					edit_sub_comment: (user_id && user_id == this.$store.getters.userInfo.id),
					delete_sub_comment: (user_id && user_id == this.$store.getters.userInfo.id),
				};
			},
			getObjectTasks(object_id, scroll) {
				return this.getObject(object_id).then((object) => {

					if (!object)
						return false;

					this.item = object;

					CommonService.log('debug', 'getObjectTasks()::axios / object', this.item);

					if (this.item.id) {

						this.is_state_ready = false;
						api.get('/common/tasks', {
							params: {
								object_id: this.item.id,
							}
						})
						.then((response) => {

							
							CommonService.log('debug', 'getObjectTasks()::axios', {response: response.data});

							if (response.status == 200 && response.data.success) {
								this.tasks = response.data.tasks;
								this.is_state_ready = true;

								this.getPotentialShow();

								if (scroll === true || typeof scroll == "undefined") {
									this.goToIntoView();
								}
							}

						}).catch(function (error) {

							CommonService.log('error', 'getObjectTasks()::axios', error);

						});

						api.get('/object/notes', {
							params: {
								object_id: this.item.id,
							}
						})
						.then((response) => {

							CommonService.log('debug', 'getObjectTasks()::axios', {response: response.data});

							if (response.status == 200 && response.data.success) {
								this.notes = response.data.notes;
								this.is_state_ready = true;
								this.goToIntoView();
							}

						}).catch(function (error) {

							CommonService.log('error', 'getObjectTasks()::axios', error);

						});

						this.emitter.emit('objectLoaded', object);
					}

					return this.item;
				});
			},
			getClientTasks(client_id, scroll) {
				return this.getClient(client_id).then((client) => {

					if (!client)
						return false;

					this.item = client;

					CommonService.log('debug', 'getClientTasks()::axios / client', this.item);

					if (this.item.id) {

						this.is_state_ready = false;
						api.get('/common/tasks', {
							params: {
								client_id: this.item.id,
							}
						}).then((response) => {

							CommonService.log('debug', 'getClientTasks()::axios / tasks', {response: response.data});

							if (response.status == 200 && response.data.success) {
								this.tasks = response.data.tasks;
								this.is_state_ready = true;

								if (scroll === true || typeof scroll == "undefined") {
									this.goToIntoView();
								}
							}
						}).catch(function (error) {

							CommonService.log('error', 'getClientTasks()::axios / tasks', error);

						});

						api.get('/client/variants', {
							params: {
								client_id: this.item.id,
							}
						}).then((response) => {

							CommonService.log('debug', 'getClientTasks()::axios / variants', {response: response.data});

							if (response.status == 200 && response.data.success)
								this.variants = response.data.variants;

						}).catch(function (error) {

							CommonService.log('error', 'getClientTasks()::axios / variants', error);

						});

						api.get('/client/history', {
							params: {
								client_id: this.item.id,
							}
						}).then((response) => {

							CommonService.log('debug', 'getClientTasks()::axios / history', {response: response.data});

							if (response.status == 200 && response.data.success)
								this.history = response.data.history;

						}).catch(function (error) {

							CommonService.log('error', 'getClientTasks()::axios / history', error);

						});

						this.emitter.emit('clientLoaded', client);
					}

					return this.item;
				});
			},
			getRequisitionTasks(requisition_id, scroll) {
				return this.getRequisition(requisition_id).then((requisition) => {

					if (!requisition)
						return false;

					this.item = requisition;

					CommonService.log('debug', 'getRequisitionTasks()::axios / requisition', this.item);

					if (this.item.id) {

						this.is_state_ready = false;

						if (!this.isEmpty(this.item.client_id)) {
							this.getClient(this.item.client_id).then((client) => {
								this.item.client_name = client.name;
								this.item.client_phone = client.phone;
								this.item.client_email = client.email;
							});
						}

						api.get('/common/tasks', {
							params: {
								requisition_id: this.item.id,
								client_id: (!this.isEmpty(this.item.client_id)) ? this.item.client_id : null,
							}
						}).then((response) => {

							CommonService.log('debug', 'getRequisitionTasks()::axios / tasks', {response: response.data});

							if (response.status == 200 && response.data.success) {
								this.tasks = response.data.tasks;
								this.is_state_ready = true;

								if (scroll === true || typeof scroll == "undefined") {
									this.goToIntoView();
								}
							}
						}).catch(function (error) {

							CommonService.log('error', 'getRequisitionTasks()::axios / tasks', error);

						});

						api.get('/client/variants', {
							params: {
								requisition_id: this.item.id,
								client_id: (!this.isEmpty(this.item.client_id)) ? this.item.client_id : null,
							}
						}).then((response) => {

							CommonService.log('debug', 'getRequisitionTasks()::axios / variants', {response: response.data});

							if (response.status == 200 && response.data.success)
								this.variants = response.data.variants;

						}).catch(function (error) {

							CommonService.log('error', 'getRequisitionTasks()::axios / variants', error);

						});

						api.get('/client/history', {
							params: {
								requisition_id: this.item.id,
								client_id: (!this.isEmpty(this.item.client_id)) ? this.item.client_id : null,
							}
						}).then((response) => {

							CommonService.log('debug', 'getRequisitionTasks()::axios / history', {response: response.data});

							if (response.status == 200 && response.data.success)
								this.history = response.data.history;

						}).catch(function (error) {

							CommonService.log('error', 'getRequisitionTasks()::axios / history', error);

						});

						this.emitter.emit('requisitionLoaded', requisition);
					}

					return this.item;
				});
			},
			editEvent(event_id, event_type) {
				if (typeof event_id !== "undefined" && typeof event_type !== "undefined") {
					if (this.inArray(event_type, ['call', 'call_owner', 'meet', 'show', 'deal', 'even', 'file', 'partner_id'])) {
						this.newEvent(event_type, event_id);
					} else {
						if (this.current_event_id == event_id) {
							this.current_event_id = null;
							this.current_event_type = null;
							this.$refs.commentInput.rows = 1;
							this.comment_input = '';
						} else {
							this.current_event_id = Number(event_id);
							let task = this.tasks.filter(item => item.id == this.current_event_id);
							if (!this.isEmpty(task[0])) {
								this.comment_input = task[0].event.comment;
							}
						}
					}
				}
			},
			newEvent(task_type, event_id, owner = false) {
				this.resultsModalState.state = '';
				this.resultsModalState.title = '';
				this.resultsModalState.message = '';
						this.current_event_type = task_type;

						if (typeof event_id !== "undefined")
							this.current_event_id = event_id;

						
						this.is_check_owner = owner;
						
						if(task_type == 'call_owner' && typeof event_id === 'undefined') {
							
							api.get('/common/owner_call', {
											params: {
												object_id: this.object_id
											}
										})
										.then((response) => {
											
											CommonService.log('debug', 'getCalendarData()::axios', {response: response.data});

											if (response.status == 200 && response.data.success) {

												let res = response.data.result;
												if(res.call == 0){
													this.$refs.taskModal.showModal();
												} else {
													this.resultsModalState.state = 'error';
													this.resultsModalState.title = 'Произошла ошибка!';
													this.resultsModalState.message = 'Нужно закрыть задачу от '+res.date_call;
												}
											}
										}).catch(function (error) {
											CommonService.log('error', 'gnewEvent()::axios', error);
											
										});

						} else {

							this.$refs.taskModal.showModal();
						}
					
				

				CommonService.log('debug', 'newEvent()', { task_type, event_id });
			},
			newEventReset() {
				this.current_event_id = null;
				this.current_event_type = null;
				this.comment_input = '';
				this.note_input = '';

				CommonService.log('debug', 'newEventReset()');

			},
			closeModal() {
				let modal = CommonService.getModal('historyTasksModal')
				if (modal) {
					modal.hide();
				}
			},
			goToIntoView() {
				const modalBody = this.$refs.modalBody;
				if (modalBody) {
					let tabContent = modalBody.querySelector('.tab-content');

					CommonService.log('info', tabContent);

					if (tabContent) {
						//CommonService.scrollIntoView(tabContent, tabContent.scrollHeight)
						CommonService.debounce(() => {
							document.querySelector('.tab-content').scrollIntoView({
								behavior: 'smooth',
								block: 'end',
							});
						}, 600);
					}
				}
			},
			addObjectsToPDF(date_group, event) {
				this.$refs["variants-tab"].querySelectorAll(date_group).forEach(item => {
					if (!this.isEmpty(item.dataset['object-id']), !this.isEmpty(item.dataset['object-title']), !this.isEmpty(item.dataset['object-address'])) {

						this.objects.push({
							id: item.dataset['object-id'],
							title: item.dataset['object-title'],
							address: item.dataset['object-address'],
						});

						this.$store.commit('setObjectsToPDF', this.objects);

						CommonService.log('debug', 'addObjectsToPDF()', this.objects);

					}
				});
			},
			addObjectToPDF(data, event) {
				if (!this.isEmpty(data.object_id), !this.isEmpty(data.object_title), !this.isEmpty(data.object_address)) {

					this.objects.push({
						id: data.object_id,
						title: data.object_title,
						address: data.object_address,
					});

					this.$store.commit('setObjectsToPDF', this.objects);

					CommonService.log('debug', 'addObjectToPDF()', this.objects);

				}
			},
			updateTasksList(tasks) {

				if (typeof tasks !== "undefined")
					this.tasks = tasks;

				this.current_event_id = null;
				this.current_event_type = null;
				this.goToIntoView();

				CommonService.log('debug', 'updateTasksList()', tasks);

			},
			updateHistoryList(history) {

				if (typeof history !== "undefined")
					this.history = history;

				this.current_event_id = null;
				this.current_event_type = null;
				this.goToIntoView();

				CommonService.log('debug', 'updateHistoryList()', history);

			},
			deleteEvent(event_id) {
				if (typeof event_id !== "undefined") {
					if (confirm('Вы действительно желаете удалить эту задачу?')) {

						this.is_state_ready = false;
						this.current_event_id = Number(event_id);
						api.delete('/common/task', {
							params: {
								object_id: this.object_id,
								client_id: this.client_id,
								requisition_id: this.requisition_id,
								event_id: this.current_event_id
							}
						}).then((response) => {

							if (process.env.NODE_ENV == "development")
								console.debug('deleteEvent()::axios', {
									response: response.data
								});

							if (response.status == 200 && response.data.success) {
								this.tasks = response.data.tasks;
								this.current_event_id = null;
								this.$refs.commentInput.rows = 1;
								this.comment_input = '';
								this.is_state_ready = true;
							}

						}).catch(function (error) {

							this.current_event_id = null;
							this.$refs.commentInput.rows = 1;
							this.comment_input = '';

							CommonService.log('error', 'deleteEvent()::axios', error);

						});
					}
				}
			},
			cancelEvent(event_id) {
				if (typeof event_id !== "undefined") {
					this.current_event_id = Number(event_id);
					if (confirm('Вы действительно желаете отменить эту задачу?')) {

						this.is_state_ready = false;
						api.put('/common/task', {
							object_id: this.object_id,
							client_id: this.client_id,
							requisition_id: this.requisition_id,
							event_id: this.current_event_id,
							action: 'cancel',
						}).then((response) => {

							if (process.env.NODE_ENV == "development")
								console.debug('sendEventComment()::axios', {
									response: response.data
								});

							if (response.status == 200 && response.data.success) {
								this.tasks = response.data.tasks;
								this.current_event_id = null;
								this.comment_input = '';
								this.is_state_ready = true;
							}

						}).catch(function (error) {

							this.current_event_id = null;
							this.$refs.commentInput.rows = 1;
							this.comment_input = '';

							CommonService.log('error', 'sendEventComment()::axios', error);

						});
					}
				}
			},

			changeStatusEventOwner(event_type, event_id, state, index){
				if(this.tasks[index].event){
					this.tasks[index].event.is_completed = !state;
				}
				if(state){
					this.newEvent(event_type, event_id, true);
				}
			},

			changeStatusEvent(event_id, state) {
				if (typeof event_id !== "undefined") {

					api.put('/common/task', {
						object_id: this.object_id,
						client_id: this.client_id,
						requisition_id: this.requisition_id,
						event_id: event_id,
						action: 'state',
						state: Number(state),
					}).then((response) => {

						if (process.env.NODE_ENV == "development")
							console.debug('changeStatusEvent()::axios', {
								response: response.data
							});

					}).catch(function (error) {

						CommonService.log('error', 'changeStatusEvent()::axios', error);

					});
				}
			},
			handleNewLine(str) {
				return str.replace(/(?:\r\n|\r|\n)/g, '<br />');
			},
			initRestore() {
				if (this.section == 'clients') {
					if (this.client_id && confirm('Вы уверены, что желаете восстановить данного клиента?')) {
						let clients_ids = [];
						clients_ids.push(this.client_id);
						api.post('/clients/restore', {
							clients_ids: clients_ids
						}).then((response) => {

							CommonService.log('debug', 'initRestore()::axios', {response});

							if (response.status == 200 && response.data.success) {
								this.$emit('initRestoreClient', {clients_ids: clients_ids});
								this.$emit('updateClient');
								this.closeModal();
							}
						}).catch(function (error) {

							CommonService.log('error', 'initRestore()::axios', error);

						});
					}
				} else if (this.section == 'requisitions') {
					if (this.requisition_id && confirm('Вы уверены, что желаете восстановить данную заявку?')) {
						let requisitions_ids = [];
						requisitions_ids.push(this.requisition_id);
						api.post('/requisitions/restore', {
							requisitions_ids: requisitions_ids
						}).then((response) => {

							CommonService.log('debug', 'initRestore()::axios', {response});

							if (response.status == 200 && response.data.success) {
								this.$emit('initRestoreRequisition', {requisitions_ids: requisitions_ids});
								this.$emit('updateRequisitions');
								this.closeModal();
							}
						}).catch(function (error) {

							CommonService.log('error', 'initRestore()::axios', error);

						});
					}
				}
			},
			initDelete() {
				if (this.section == 'clients') {
					if (this.client_id && confirm('Вы точно хотите безвозвратно удалить этого клиента?')) {
						api.delete('/client', {
							params: {
								client_id: this.client_id
							}
						}).then((response) => {

							CommonService.log('debug', 'initDelete()::axios', {response});

							if (response.status == 200 && response.data.success) {
								this.$emit('initDeleteClient', {client_id: this.client_id});
								this.$emit('updateClient');
								this.closeModal();
							}
						}).catch(function (error) {

							CommonService.log('error', 'initDelete()::axios', error);

						});
					}
				} else if (this.section == 'requisitions') {
					if (this.requisition_id && confirm('Вы точно хотите безвозвратно удалить эту заявку?')) {
						api.delete('/requisition', {
							params: {
								requisition_id: this.requisition_id
							}
						}).then((response) => {

							CommonService.log('debug', 'initDelete()::axios', {response});

							if (response.status == 200 && response.data.success) {
								this.$emit('initDeleteRequisition', {requisition_id: this.requisition_id});
								this.$emit('updateRequisitions');
								this.closeModal();
							}
						}).catch(function (error) {

							CommonService.log('error', 'initDelete()::axios', error);

						});
					}
				}
			},
			getFieldsList(section) {
				return api.get('/fields', {
					params: {
						section: section
					}
				})
				.then((response) => {

					CommonService.log('debug', 'getFieldsList()::axios', {response: response.data});

					if (response.status == 200 && response.data.success) {
						return response.data.list;
					} else {
						return {};
					}

				}).catch(function (error) {

					CommonService.log('error', 'getFieldsList()::axios', error);

				});
			},
			sendEventComment() {
				if (!this.isEmpty(this.comment_input)) {

					this.is_state_ready = false;
					api.post('/common/task', {
						object_id: this.object_id,
						client_id: this.client_id,
						requisition_id: this.requisition_id,
						event_id: this.current_event_id,
						event_type: 'comment',
						task: {
							comment: this.handleNewLine(this.comment_input)
						},
					}).then((response) => {

						if (process.env.NODE_ENV == "development")
							console.debug('sendEventComment()::axios', {
								response: response.data
							});

						if (response.status == 200 && response.data.success) {
							this.tasks = response.data.tasks;
							this.current_event_id = null;
							this.$refs.commentInput.rows = 1;
							this.comment_input = '';
							this.is_state_ready = true;
							this.goToIntoView();
						}

					}).catch(function (error) {

						CommonService.log('error', 'sendEventComment()::axios', error);

					});
				}
			},
			sendEventSubComment() {
				if (!this.isEmpty(this.sub_comment.task_id) && !this.isEmpty(this.sub_comment.text)) {

					api.post('/common/sub-comment', {
						section: this.section,
						event_id: this.sub_comment.task_id,
						comment: this.sub_comment.text,
						object_id: this.object_id || null,
						client_id: this.client_id || null,
						requisition_id: this.requisition_id || null,
						employee_id: this.sub_comment.employee_id || null,
						sub_id: this.sub_comment.id || null,
					}).then((response) => {

						if (process.env.NODE_ENV == "development") {
							console.debug('sendEventSubComment()::axios', {
								response: response.data
							});
						}

						if (response.status == 200 && response.data.success) {
							this.goToIntoView();
						}

					}).catch(function (error) {

						CommonService.log('error', 'sendEventSubComment()::axios', error);

					});
				}
			},
			editNote(note_id) {
				if (typeof note_id !== "undefined") {
					if (this.current_note_id == Number(note_id)) {
						this.current_note_id = null;
						this.note_input = '';
					} else {
						this.current_note_id = Number(note_id);
						let note = this.notes.filter(item => item.id == this.current_note_id);
						if (!this.isEmpty(note[0])) {
							this.note_input = note[0].comment;
						}
					}
				}
			},
			deleteNote(note_id) {
				if (typeof note_id !== "undefined") {
					if (confirm('Вы действительно желаете удалить эту заметку?')) {

						this.current_note_id = Number(note_id);

						api.delete('/object/note', {
							params: {
								object_id: this.object_id,
								note_id: this.current_note_id
							}
						}).then((response) => {
							if (process.env.NODE_ENV == "development")
								console.debug('deleteNote()::axios', {
									response: response.data
								});

							if (response.status == 200 && response.data.success) {
								this.notes = response.data.notes;
								this.current_note_id = null;
								this.note_input = '';
								this.$refs['note_'+note_id].remove();
								this.$emit('updateObject', this.object_id);
								this.goToIntoView();
							}

						}).catch(function (error) {

							CommonService.log('error', 'deleteNote()::axios', error);

						});
					}
				}
			},
			sendObjectNote() {
				if (!this.isEmpty(this.note_input)) {

					api.post('/object/note', {
						object_id: this.object_id,
						note_id: this.current_note_id,
						comment: this.note_input
					}).then((response) => {

						if (process.env.NODE_ENV == "development")
							console.debug('sendObjectNote()::axios', {
								response: response.data
							});

						if (response.status == 200 && response.data.success) {
							this.notes = response.data.notes;
							this.$emit('updateObject', this.object_id);
							this.current_note_id = null;
							this.note_input = '';
							this.goToIntoView();
						}

					}).catch(function (error) {

						CommonService.log('error', 'sendObjectNote()::axios', error);

					});
				}
			},
			sendToMessanger(platform) {
				let uri = '';

				let phone = this.item.phone;
				let comment = this.comment_input;

				if (this.section == 'requisitions')
					phone = this.item.client_phone;

				if (!this.isEmpty(phone)) {

					if (platform == 'whatsapp')
						uri = this.links.whatsapp;
					else if (platform == 'telegram')
						uri = this.links.telegram;

					uri = uri.replace('{phone}', phone);

					if (!this.isEmpty(comment))
						uri = uri.replace('{message}', encodeURI(comment));
					else
						uri = uri.replace('{message}', "");

					window.open(uri, '_blank');
				}

				if (process.env.NODE_ENV == "development") {
					console.debug('sendToMessanger()::axios', {
						platform: platform,
						phone: phone,
						comment_input: comment,
						uri: uri,
					});
				}

				this.$refs.commentInput.rows = 1;
				this.comment_input = '';
			},
        }, CommonDataService.methods),

        mounted() {

			this.is_state_ready = false;

			this.modal = document.getElementById('historyTasksModal');
			if (this.modal && typeof this.modal !== "undefined") {

				let _this = this;
				this.modal.addEventListener('hidden.bs.modal', function (event) {
					if(_this.object_id > 0 && _this.status_id > 0){
						_this.$emit('updateObject', _this.object_id);
					}
					_this.object_id = 0;
					_this.client_id = 0;
					_this.requisition_id = 0;
					_this.status_id = 0;
					_this.current_event_id = null;
					_this.current_note_id = null;
					_this.comment_input = '';
					_this.note_input = '';
					_this.item = {
						autosearch: {
							filter: null
						},
						fields: null
					};
					_this.tasks = [];
					_this.history = [];
					_this.variants = [];
					_this.notes = [];
					_this.section = null;
					_this.is_state_ready = false;

					_this.stage_client_id = null;
					_this.stage_requisition_id = null;
					_this.stage_funnel_id = null;
					_this.stage_id = null;
					_this.stage_name = null;
					_this.emitter.emit("global.modalClose", this.modal);
				});

				this.modal.addEventListener('shown.bs.modal', function (event) {

					_this.tasks = null;
					_this.history = null;
					_this.variants = null;
					_this.notes = null;
					_this.is_state_ready = false;
					_this.current_event_id = null;
					_this.current_note_id = null;
					_this.comment_input = '';
					_this.note_input = '';

					let target = event.relatedTarget;

					if (typeof target !== "undefined") {

						if (typeof target.getAttribute('data-bs-object-id') != "undefined") {
							_this.section = 'objects';
							_this.object_id = target.getAttribute('data-bs-object-id');
							if (typeof target.getAttribute('data-bs-object-status-id') != "undefined") {
								_this.status_id = target.getAttribute('data-bs-object-status-id');
							}
						}

						if (typeof target.getAttribute('data-bs-client-id') != "undefined") {
							_this.section = 'clients';
							_this.client_id = target.getAttribute('data-bs-client-id');
							_this.confirmModalState.id = _this.client_id;
							_this.confirmModalState.fromSection = 'confirmRestoreClient';
						}

						if (typeof target.getAttribute('data-bs-requisition-id') != "undefined") {
							_this.section = 'requisitions';
							_this.requisition_id = target.getAttribute('data-bs-requisition-id');
							_this.confirmModalState.id = _this.requisition_id;
							_this.confirmModalState.fromSection = 'confirmRestoreRequisition';
							console.info(target);
						}

						if (typeof target.getAttribute('data-bs-section') != "undefined")
							_this.section = target.getAttribute('data-bs-section');

						if (typeof target.getAttribute('data-bs-action') != "undefined")
							_this.action = target.getAttribute('data-bs-action');

					} else if (!_this.isEmpty(_this.injections)) {
						if (!_this.isEmpty(_this.injections.object_id)) {
							_this.section = 'objects';
							_this.object_id = _this.injections.object_id;
						} else if (!_this.isEmpty(_this.injections.client_id)) {
							_this.section = 'clients';
							_this.client_id = _this.injections.client_id;
						} else if (!_this.isEmpty(_this.injections.requisition_id)) {
							_this.section = 'requisitions';
							_this.requisition_id = _this.injections.requisition_id;
						}
					}

					if (_this.section == 'objects' && _this.object_id) {

						_this.getObjectTasks(_this.object_id).then(item => _this.item = item);

						_this.clientHistoryMenu = [
							{
								label: 'Звонок',
								icon: require('@/assets/icons/phone-icon.svg'),
								className: 'bg-primary',
								action: () => _this.newEvent('call'),
							}, {
								label: 'Звонок собственнику',
								icon: require('@/assets/icons/phone-icon.svg'),
								className: 'bg-primary',
								action: () => _this.newEvent('call_owner'),
							},{
								label: 'Встреча',
								icon: require('@/assets/icons/bag-icon.svg'),
								className: 'bg-pink',
								action: () => _this.newEvent('meet'),
							}, {
								label: 'Показ',
								icon: require('@/assets/icons/eye-icon.svg'),
								className: 'bg-warning',
								action: () => _this.newEvent('show'),
							}, {
								label: 'Сделка',
								icon: require('@/assets/icons/deal-icon.svg'),
								className: 'bg-raspberry',
								action: () => _this.newEvent('deal'),
							}, {
								label: 'Задача',
								icon: require('@/assets/icons/task-icon.svg'),
								className: 'bg-ocean',
								action: () => _this.newEvent('even'),
							}, {
								label: 'Файл',
								icon: require('@/assets/icons/file-icon.svg'),
								className: 'bg-darkblue',
								action: () => _this.newEvent('file'),
							}
						];

						_this.tabItems = [
							{
								label: "Задачи",
								title: "Задачи",
								id: 'tasks-tab',
								data_bs_toggle:'tab',
								data_bs_target: '#tasks',
								active: (_this.action != 'notes'),
								href: '#',
								action: () => console.log('Tab 1 clicked!')
							}, {
								label: "Примечания",
								title: "Примечания",
								id: 'notes-tab',
								data_bs_toggle:'tab',
								data_bs_target: '#notes',
								active: (_this.action == 'notes'),
								href: '#',
								action: () => console.log('Tab 4 clicked!')
							},
						];

					} else if (_this.section == 'clients' && _this.client_id) {

						_this.getClientTasks(_this.client_id).then(item => {
							_this.item = item;
							_this.stage_client_id = _this.item.id;
							_this.stage_funnel_id = _this.item.funnel_id;
							_this.stage_id = _this.item.stage_id;
							_this.stage_name = _this.item.stage_name;
						});

						_this.tabItems = [
							{
								label: "Задачи",
								title: "Задачи",
								id: 'tasks-tab',
								data_bs_toggle:'tab',
								data_bs_target: '#tasks',
								active: true,
								href: '#',
								action: () => console.log('Tab 1 clicked!')
							}, {
								label: "Предложенные варианты",
								title: "Предложенные варианты",
								id: 'variants-tab',
								data_bs_toggle:'tab',
								data_bs_target: '#variants',
								active: false,
								href: '#',
								action: () => console.log('Tab 2 clicked!')
							}, {
								label: "История",
								title: "История",
								id: 'history-tab',
								data_bs_toggle:'tab',
								data_bs_target: '#history',
								active: false,
								href: '#',
								action: () => console.log('Tab 3 clicked!')
							},
						];


					} else if (_this.section == 'requisitions' && _this.requisition_id) {
						_this.getRequisitionTasks(_this.requisition_id).then(item => {
							_this.item = item;
							_this.stage_requisition_id = _this.item.id;
							_this.stage_funnel_id = _this.item.funnel_id;
							_this.stage_id = _this.item.stage_id;
							_this.stage_name = _this.item.stage_name;
						});

						_this.tabItems = [
							{
								label: "Задачи",
								title: "Задачи",
								id: 'tasks-tab',
								data_bs_toggle:'tab',
								data_bs_target: '#tasks',
								active: true,
								href: '#',
								action: () => console.log('Tab 1 clicked!')
							}, {
								label: "Предложенные варианты",
								title: "Предложенные варианты",
								id: 'variants-tab',
								data_bs_toggle:'tab',
								data_bs_target: '#variants',
								active: false,
								href: '#',
								action: () => console.log('Tab 2 clicked!')
							}, {
								label: "История",
								title: "История",
								id: 'history-tab',
								data_bs_toggle:'tab',
								data_bs_target: '#history',
								active: false,
								href: '#',
								action: () => console.log('Tab 3 clicked!')
							},
						];
					}

					if (_this.section == 'clients' || _this.section == 'requisitions') {

						_this.getRoles(_this.section).then((list) => _this.roles_list = list);
						_this.getTypes(_this.section).then((list) => _this.types_list = list);
						_this.getSources().then((list) => _this.sources_list = list);
						_this.getPartners().then((list) => _this.partners_list = list);

						_this.clientHistoryMenu = [
							{
								label: 'Звонок',
								icon: require('@/assets/icons/phone-icon.svg'),
								className: 'bg-primary',
								action: () => _this.newEvent('call'),
							}, {
								label: 'Встреча',
								icon: require('@/assets/icons/bag-icon.svg'),
								className: 'bg-pink',
								action: () => _this.newEvent('meet'),
							}, {
								label: 'Показ',
								icon: require('@/assets/icons/eye-icon.svg'),
								className: 'bg-warning',
								action: () => _this.newEvent('show'),
							}, {
								label: 'Сделка',
								icon: require('@/assets/icons/deal-icon.svg'),
								className: 'bg-raspberry',
								action: () => _this.newEvent('deal'),
							}, {
								label: 'Задача',
								icon: require('@/assets/icons/task-icon.svg'),
								className: 'bg-ocean',
								action: () => _this.newEvent('even'),
							}, {
								label: 'Файл',
								icon: require('@/assets/icons/file-icon.svg'),
								className: 'bg-darkblue',
								action: () => _this.newEvent('file'),
							}, {
								label: 'Партнер',
								icon: require('@/assets/icons/partner-icon.svg'),
								className: 'bg-purple',
								action: () => _this.newEvent('partner_id'),
							},
						];
					}

					if (_this.tabItems.length) {
						_this.tabItems.forEach((item) => {
							if (item.active && _this.$refs[item.id]) {
								_this.$refs[item.id].classList.add('show', 'active');
							} else {
								_this.$refs[item.id].classList.remove('show', 'active');
							}
						});
					}


					if (process.env.NODE_ENV == "development") {
						console.debug('historyTasksModal::show.bs.modal', {
							section: _this.section,
							object_id: _this.object_id,
							client_id: _this.client_id,
							requisition_id: _this.requisition_id,
						});
					}

					CommonService.debounce(_this.initPopovers(), 3000);
					_this.emitter.emit("global.modalShown", this.modal);
				});
			}
		},
		watch: {
			section(value, oldValue) {
				if (process.env.NODE_ENV == "development") {
					console.debug('section::watch', value);
				}
			},
			item(value, oldValue) {
				if (process.env.NODE_ENV == "development") {
					console.debug('item::watch', value);
				}
			},
			tasks(value, oldValue) {
				if (process.env.NODE_ENV == "development") {
					console.debug('tasks::watch', value);
				}
			},
			comment_input: {
				handler: function(value, oldValue) {
					this.$nextTick(() => {

						if (value) {

							let breaks = value.split('\n').length;
							if (breaks > 1 && breaks <= 4) {
								this.$refs.commentInput.rows = breaks;
							}

							if (value.length == 0)
								this.$refs.commentInput.rows = 1;

							if (value.length > 12 || oldValue.length > 12) {
								this.$refs.commentInput.classList.remove('order-2', 'me-10');
								this.$refs.commentInput.classList.add('w-100', 'align-self-start', 'order-0', 'mb-2', 'me-0');
							} else {
								this.$refs.commentInput.classList.remove('w-100', 'align-self-start', 'order-0', 'mb-2', 'me-0');
								this.$refs.commentInput.classList.add('order-2', 'me-10');
							}
						}
					});
				},
				deep: true
			},
			note_input: {
				handler: function(value, oldValue) {
					this.$nextTick(() => {

						if (value) {

							let breaks = value.split('\n').length;
							if (breaks > 1 && breaks <= 4) {
								this.$refs.noteInput.rows = breaks;
							}

							if (value.length == 0)
								this.$refs.noteInput.rows = 1;

							if (value.length > 12 || oldValue.length > 12) {
								this.$refs.noteInput.classList.remove('order-2', 'me-10');
								this.$refs.noteInput.classList.add('w-100', 'align-self-start', 'order-0', 'mb-2', 'me-0');
							} else {
								this.$refs.noteInput.classList.remove('w-100', 'align-self-start', 'order-0', 'mb-2', 'me-0');
								this.$refs.noteInput.classList.add('order-2', 'me-10');
							}
						}
					});
				},
				deep: true
			},
			history: {
				handler: function(value) {
					this.$nextTick(() => {

						/*let className = 'vh-85';
						if (this.section == 'objects')
							className = 'vh-75';

						if (value) {
							if (value.length && this.$refs["history-tab"].closest('.modal-content').offsetHeight >= window.innerHeight) {
								this.$refs["history-tab"].classList.add(className);
							} else {
								this.$refs["history-tab"].classList.remove(className);
							}
						}*/
					});
				},
				deep: true
			},
			variants: {
				handler: function(value) {
					this.$nextTick(() => {

						let className = 'vh-85';
						if (this.section == 'objects')
							className = 'vh-75';

						if (value) {
							if (value.length && this.$refs["variants-tab"].closest('.modal-content').offsetHeight >= window.innerHeight) {
								this.$refs["variants-tab"].classList.add(className);
							} else {
								this.$refs["variants-tab"].classList.remove(className);
							}
						}
					});
				},
				deep: true
			},
			notes: {
				handler: function(value) {
					this.$nextTick(() => {

						let className = 'vh-85';
						if (this.section == 'objects')
							className = 'vh-75';

						if (value) {
							if (value.length && this.$refs["notes-tab"].closest('.modal-content').offsetHeight >= window.innerHeight) {
								this.$refs["notes-tab"].classList.add(className);
							} else {
								this.$refs["notes-tab"].classList.remove(className);
							}
						}
					});
				},
				deep: true
			},
		},
		computed: {
			injections() {
				return (typeof this.$props.injectData !== "undefined") ? this.$props.injectData : null;
			},
			user_can() {
				return {
					close_client: this.section === 'clients' && !this.isEmpty(this.client_id) && (this.$store.getters.userInfo.is_agency || this.$store.getters.userInfo.is_users_admin || this.$store.getters.userInfo.permissions.menu_all_clients_edit == 1 || this.$store.getters.userInfo.id == this.item.agent_id),
					delete_client: this.section === 'clients' && !this.isEmpty(this.client_id) && this.item.is_deleted,
					edit_client: this.section === 'clients' && !this.isEmpty(this.client_id) && (this.$store.getters.userInfo.is_agency || this.$store.getters.userInfo.is_users_admin || this.$store.getters.userInfo.permissions.menu_all_clients_edit == 1 || this.$store.getters.userInfo.id == this.item.agent_id),
					restore_client: this.section === 'clients' && !this.isEmpty(this.client_id) && this.item.is_deleted,
					close_requisition: this.section === 'requisitions' && !this.isEmpty(this.requisition_id) && (this.$store.getters.userInfo.is_agency || this.$store.getters.userInfo.is_users_admin || this.$store.getters.userInfo.permissions.menu_all_submissions_edit == 1 || this.$store.getters.userInfo.id == this.item.agent_id),
					delete_requisition: this.section === 'requisitions' && !this.isEmpty(this.requisition_id) && this.item.is_deleted,
					edit_requisition: this.section === 'requisitions' && !this.isEmpty(this.requisition_id) && (this.$store.getters.userInfo.is_agency || this.$store.getters.userInfo.is_users_admin || this.$store.getters.userInfo.permissions.menu_all_submissions_edit == 1 || this.$store.getters.userInfo.id == this.item.agent_id),
					restore_requisition: this.section === 'requisitions' && !this.isEmpty(this.requisition_id) && this.item.is_deleted,
				};
			},
			type() {

				let types = this.types_list ?? {};
				if (!this.isEmpty(types[this.item.type_id]))
					return types[this.item.type_id].toString();

				return null;
			},
			source() {

				if (this.item.source_id !== 0) {

					let sources = this.sources_list ?? {};
					if (!this.isEmpty(sources)) {
						let source = sources.filter((source) => source.value == this.item.source_id).map((source) => {
							return source.name;
						});

						if (!this.isEmpty(source))
							return source.toString();
					}
				}

				return null;
			},
			partner() {

				let partners = this.partners_list ?? {};
				if (!this.isEmpty(partners)) {

					let partner = partners.filter((partner) => partner.value == this.item.partner_id).map((partner) => {
						return partner.company;
					});

					if (!this.isEmpty(partner))
						return partner.toString();

				}

				return null;
			},
			filter_fields() {

				let list = null;
				if (this.item.autosearch !== "undefined") {
					if (!this.isEmpty(this.item.autosearch.filter)) {
						list = this.item.autosearch.filter;
					}
				}

				return list;
			},
			custom_fields() {

				let list = null;
				if (!this.isEmpty(this.item.fields)) {
					if (this.section == 'clients') {
						/*list = [];
						Object.entries(this.item.fields).forEach((item, index) => {
							if (this.inArray(item[1]['id'], [2, 3])) {
								if (item[1]['id'] == 2) {

									console.log
									let params = Object.assign(item[1]['params']['items']).filter(item => item.value == item[1]['value']);
									list.push({
										id: item[1]['id'],
										label: item[1]['label'],
										value: params['name'],
									});
								} else if (item[1]['id'] == 3) {
									let params = Object.assign(item[1]['params']['items']).filter(item => this.inArray(item.value, item[1]['value']));
									list.push({
										id: item[1]['id'],
										label: item[1]['label'],
										value: params.join(', '),
									});
								}
							} else {
								list.push({
									id: item[1]['id'],
									label: item[1]['label'],
									value: item[1]['value'],
								});
							}
						});*/
						list = this.item.fields;
					} else if (this.section == 'requisitions') {
						list = this.item.fields;
					}
				}
				return list;
			},
		}
    }
</script>

<style lang="scss">

    /*$popover-border-color: #8EB3F5;

    .popover {
        border-color: $popover-border-color !important;

        &-arrow {
            &::before {
                border-bottom-color: $popover-border-color !important;
            }
        }
    }*/
</style>
