<template>
	<div class="modal fade" id="sendPresentationsModal" ref="sendPresentationsModal" tabindex="-1" aria-labelledby="Отправить PDF-презентации" aria-hidden="true">
		<div class="modal-dialog modal-fullscreen modal-dialog-centered modal-dialog-scrollable">
			<div class="modal-content p-20">
				<div class="modal-header d-flex align-items-center mb-20 border-0 p-0 px-1">
					<span v-if="this.send_in_proccessed" class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Отправка презентаций...</span>
					<span v-else class="modal-title d-flex me-auto fs-2 fw-semi font-semi lh-1 me-auto w-90">Отправить презентации</span>
					<button type="button" class="d-flex ms-auto btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
				</div>
				<div v-if="this.send_in_proccessed" class="modal-body d-flex flex-column p-0 px-1">
					<Loader />
				</div>
				<div v-if="!this.send_in_proccessed" class="modal-body d-flex flex-column p-0 px-1">
					<div class="row-fluid row-cols-1">
						<div class="container-fluid px-0">
							<div class="col mb-3">
								<div class="fw-bold mb-2">Информация:</div>
								<div class="input-group fs-4">
									<p>Будут сформированы презентации: {{ this.objects.length }} шт.</p>
									<p>Письмо будет отправлено с почты: <a href="javascript:{}" class="link">robot@joywork.ru</a></p>
									<button class="btn btn-outline-secondary me-2 rounded-3"
											v-clipboard:copy="this.catalog_link"
											v-clipboard:success="() => this.showAlertMessage('Ссылка успешно скопирована!')"
											v-clipboard:error="() => this.showAlertMessage('Не удалось скопировать ссылку: ' + this.catalog_link)">Ссылка на каталог</button>
									<button class="btn btn-outline-danger me-2 rounded-3"
											@click="this.clearList()">Очистить список</button>
								</div>
							</div>
							<div class="col mb-3">
								<div class="fw-bold mb-2">
									Объекты:
									<sup class="badge bg-success rounded-3">{{ this.objects.length }}</sup>
								</div>

								<div class="input-group fs-4">
									<ul v-if="this.objects_ready == true" class="list-group scroller scroller-y nowrap-mask pb-2 w-100 max-vh-35">
										<li v-for="(object, index) of this.objects"
											class="list-group-item border-start-0 border-end-0 px-0 py-3"
											:class="{
												'border-top-0' : (index == 0),
												'border-bottom-0' : ((index + 1) == this.objects.length)
											}"
											:key="object.id">
											<small v-text="'#' + object.id" class="d-flex text-dark-gray" />
											<a href="javascript:{}" @click.prevent="this.downloadLink(object.id)" class="d-inline-flex link fs-3 lh-1 mt-1 mb-2" v-text="object.title" download />
											<p  class="d-flex text-dark-gray" v-text="object.address" />

											<Switcher inputId="hide_contact"
													  inputName="hide_contact"
													  inputLabel="Скрыть мои контакты"
													  :inputValue="this.inArray(object.id, this.hidden_contacts) ? true : false"
													  inputClass="form-switch-sm px-0 mb-3"
													  inputWrapClass="flex-column mb-3"
													  @setValue="(value) => {this.addRemoveHiddenContacts(object.id, value); this.getPresentationLink(object.id, 1).then(link => {	this.links[object.id] = link;})}" />

											<Switcher inputId="hide_address"
													  inputName="hide_address"
													  inputLabel="Скрыть адрес"
													  :inputValue="this.inArray(object.id, this.hidden_address) ? true : false"
													  inputClass="form-switch-sm px-0 mb-3"
													  inputWrapClass="flex-column mb-3"
													  @setValue="(value) => this.addRemoveHiddenAddress(object.id, value)" />

											<button class="d-inline-flex btn rounded-3"
													:class="(!this.isEmpty(this.descriptions['object_'+object.id])) ? 'icon-color-warning btn-outline-warning' : 'btn-light-gray'"
													@click="this.editDescription(object.id)">
												<img class="d-inline-flex" :src="require('@/assets/icons/pen-icon.svg')" alt="Редактировать" width="24" height="24">
											</button>
											<button class="d-inline-flex btn btn-light-gray rounded-3 ms-2" @click="this.removeFromList(object.id)">
												<img class="d-inline-flex" :src="require('@/assets/icons/delete.svg')" alt="Удалить" width="24" height="24">
											</button>
											<button class="d-inline-flex btn btn-light-gray rounded-3 ms-2"
													v-clipboard:copy="this.links[object.id]"
													v-clipboard:success="() => this.showAlertMessage('Ссылка успешно скопирована!')"
													v-clipboard:error="() => this.showAlertMessage('Не удалось скопировать ссылку: ' + this.links[object.id])">
												<img class="d-inline-flex" :src="require('@/assets/icons/link.svg')" alt="Копировать ссылку" width="24" height="24">
											</button>
										</li>
									</ul>
									<div v-else class="col-12 w-100" style="min-height: 160px;">
										<Loader />
									</div>
								</div>

								<div class="input-group fs-4">

									<Switcher inputId="hide_all_addresses"
											  inputName="hide_all_addresses"
											  inputLabel="Скрыть все адреса"
											  :inputValue="false"
											  inputClass="px-0 mb-1"
											  inputWrapClass="flex-column mt-3"
											  @setValue="(value) => this.hiddenAllAddresses(value)" />

								</div>
							</div>
							<div class="col mb-3">

								<ToggleButtons
									inputId="send_with"
									inputLabel="Отправка посредством:"
									inputName="send_with"
									ref="send_with"
									inputType="radio"
									:inputOptions="this.send_with_list"
									inputBtnClass="btn-light-gray rounded-3 me-2 mb-2"
									inputWrapClass="flex-column mb-3"
									:inputValue="this.send_with"
									inputRequired="true"
									@setValue="(value) => this.setSendWith(value)" />

								<div class="fw-bold mb-2">Получатель:</div>

								<a href="#collapseFilters"
								   class="d-inline-flex link mt-1 mb-3"
								   data-bs-toggle="collapse"
								   role="button" 
								   aria-expanded="false"
								   aria-controls="collapseFilters">
									Уточнить список клиентов
								</a>

								<div class="collapse mb-4" id="collapseFilters">

									<SelectBox
										v-if="!this.isEmpty(this.filters.employees)"
										inputId="clients_employees"
										inputName="clients_employees"
										inputClass="rounded-1 p-2"
										inputPlaceholder="Ответственный"
										inputWrapClass="flex-column my-2"
										:inputOptions="this.filters.employees"
										inputSearch="true"
										inputMultiple="true"
										@setValue="(value) => this.setClientsFilter('employees', value)" />

									<SelectBox
										v-if="!this.isEmpty(this.filters.types)"
										inputId="clients_type"
										inputName="clients_type"
										inputClass="rounded-1 p-2"
										inputPlaceholder="Тип"
										inputWrapClass="flex-column my-2"
										:inputOptions="this.filters.types"
										inputSearch="true"
										inputMultiple="true"
										@setValue="(value) => this.setClientsFilter('types', value)" />

									<SelectBox
										v-if="!this.isEmpty(this.filters.tags)"
										inputId="clients_tags"
										inputName="clients_tags"
										inputClass="rounded-1 p-2"
										inputPlaceholder="Теги"
										inputWrapClass="flex-column my-2"
										:inputOptions="this.filters.tags"
										inputSearch="true"
										inputMultiple="true"
										@setValue="(value) => this.setClientsFilter('tags', value)" />

									<SelectBox
										v-if="!this.isEmpty(this.filters.roles)"
										inputId="clients_roles"
										inputName="clients_roles"
										inputClass="rounded-1 p-2"
										inputPlaceholder="Роль клиента"
										inputWrapClass="flex-column my-2"
										:inputOptions="this.filters.roles"
										inputSearch="true"
										inputMultiple="true"
										@setValue="(value) => this.setClientsFilter('roles', value)" />

									<SelectBox
										v-if="!this.isEmpty(this.filters.sources)"
										inputId="clients_sources"
										inputName="clients_sources"
										inputClass="rounded-1 p-2"
										inputPlaceholder="Источник"
										inputWrapClass="flex-column my-2"
										:inputOptions="this.filters.sources"
										inputSearch="true"
										inputMultiple="true"
										@setValue="(value) => this.setClientsFilter('source', value)" />

								</div>
								
								<SelectBox inputId="send_clients"
										   inputLabel="Кому:"
										   inputName="send_clients"
										   inputClass="rounded-1 p-2"
										   inputWrapClass="flex-column mb-4"
										   :inputOptions="this.clients"
										   :inputInValid="this.clientsIsValid ? false : 'Нет доступных клиентов'"
										   :inputDisabled="this.clientsIsValid ? false : true"
										   ref="send_clients"
										   inputSearch="true"
										   :inputMultiple="(this.send_with != 1) ? false : true"
										   :inputPlaceholder="(this.send_with != 1) ? 'Выберите клиента' : 'Выберите клиента(ов)'"
										   inputLazyLoad="true"
										   inputRequired="true"
										   :inputValue="this.send_clients"
										   @searchInput="(term) => this.search_query = term"
										   @endOfList="(length) => this.clients_offset = length"
										   @setValue="(value) => this.send_clients = value" />

								<InputSelect
									v-show="this.send_with == 1" inputId="send_provide"
									inputLabel="Способ отправки:"
									inputName="send_provide"
									ref="send_provide"
									:inputOptions="this.send_methods"
									inputClass="rounded-1 mb-10"
									inputWrapClass="flex-column mb-3"
									:inputValue="this.send_provide"
									inputRequired="true"
									@setValue="(value) => this.send_provide = value" />

								<InputText
									v-show="this.send_with == 1" inputId="send_subject"
									inputLabel="Тема:"
									inputName="send_subject"
									ref="send_subject"
									inputPlaceholder=""
									inputClass="rounded-1 mb-10"
									inputWrapClass="flex-column mb-0"
									:inputValue="this.send_subject"
									@setValue="(value) => this.send_subject = value" />

								<TextArea
									inputId="send_message"
									inputLabel="Сообщение:"
									inputName="send_message"
									ref="send_message"
									inputClass="rounded-1 mb-10"
									inputWrapClass="flex-column mb-3"
									:inputValue="this.send_message"
									@setValue="(value) => this.send_message = value" />

								<Switcher v-show="(this.send_with == 2 || this.send_with == 3)" inputId="autoopen_links"
										  inputName="autoopen_links"
										  ref="autoopen_links"
										  inputLabel="Открыть в мессенджере"
										  :inputValue="this.autoopen_links"
										  inputClass="px-0 mb-3"
										  inputWrapClass="flex-column mb-3"
										  @setValue="(value) => this.autoopen_links = value" />
								
							</div>
						</div>
					</div>
				</div>
				<div v-if="!this.send_in_proccessed" class="modal-footer border-0 p-0 px-1">
					<button
						type="button"
						class="btn btn-primary col-12 rounded-3 py-3 text-white fs-3"
						:class="{ 'disabled': (this.isEmpty(this.objects) || this.isEmpty(this.send_clients)) }"
						@click="this.sendPresentations()">
						Отправить
					</button>
				</div>
			</div>
		</div>
	</div>

	<EditObjectDescrModal ref="editObjectDescrModal"
						  needSave="false"
						  useEditor="false"
						  @saveDescription="(data) => this.setDescription(data.object_id, data.description, data.price)" />

	<ResultsModal
		id="presentations"
		:state="this.resultsModalState.state"
		:title="this.resultsModalState.title"
		:message="this.resultsModalState.message"
	/>
</template>

<script>
import ResultsModal from "@/components/modals/ResultsModal";
import SelectBox from "@/components/inputs/SelectBox";
import ToggleButtons from "@/components/inputs/ToggleButtons";
import InputSelect from "@/components/inputs/InputSelect";
import InputText from "@/components/inputs/InputText";
import TextArea from "@/components/inputs/TextArea";
import api from "@/api";
import CommonService from "@/services/CommonService";
import Switcher from "@/components/inputs/Switcher";
import EditObjectDescrModal from "@/components/modals/EditObjectDescrModal";
import Loader from "@/components/common/Loader";
import {CommonDataService} from "@/services/CommonDataService";
import {copyText} from "vue3-clipboard";

export default {
	name: "SendPresentationsModal",
	props: {
		objectsList: { type: Array },
		clientsList: { type: Array },
		employeesList: { type: Array },
		typesList: { type: Array },
		tagsList: { type: Array },
		rolesList: { type: Array },
		sourcesList: { type: Array },
	},
	components: {
		Loader,
		EditObjectDescrModal,
		Switcher,
		TextArea,
		InputText,
		InputSelect,
		ToggleButtons,
		SelectBox,
		ResultsModal
	},
	data() {
		return {
			modal: {},
			objects: [],
			clients: (typeof this.clientsList !== "undefined") ? this.clientsList : [],
			descriptions: {},
			send_clients: [],
			hidden_contacts: [],
			hidden_address: [],
			catalog_id: null,
			send_with: 1,
			send_methods: [
				//{value: 1, name: 'Список ссылок'},
				{value: 3, name: 'Ссылка (каталог)'},
				{value: 2, name: 'Вложение презентаций'},
			],
			send_with_list: [
				{value: 1, name: 'E-mail'},
				{value: 2, name: 'WhatsApp'},
				{value: 3, name: 'Telegram'},
			],
			send_provide: 3, // 3 - Ссылка (каталог)
			send_subject: '',
			send_message: '',
			proccessed: false,
			objects_ready: false,
			send_in_proccessed: false,
			autoopen_links: 1,
			search_query: '',
			catalog_link: '',
			links: [],
			clients_offset: 0,
			filter: {
				employees: [],
				types: [],
				tags: [],
				roles: [],
				source: [],
			},
			filters: {
				employees: (typeof this.employeesList !== "undefined") ? this.employeesList : [],
				types: (typeof this.typesList !== "undefined") ? this.typesList : [],
				tags: (typeof this.tagsList !== "undefined") ? this.tagsList : [],
				roles: (typeof this.rolesList !== "undefined") ? this.rolesList : [],
				sources: (typeof this.sourcesList !== "undefined") ? this.sourcesList : [],
			},
			resultsModalState: {
				state: null,
				title: '',
				message: ''
			},
		}
	},
	methods: CommonService.mergeRecursive({
		clearList() {
			this.objects = [];
			this.$store.commit('clearToPDF');
		},
		alertNote(message) {
			CommonService.debounce(() => alert(message), 1000);
		},
		removeFromList(object_id) {

			this.objects = this.objects.filter(object => {
				return object.id != object_id;
			});

			this.$store.commit('setObjectsToPDF', this.objects);
		},
		getEmployees() {
			return api.get('/common/employees', {
				params: {
					self_exclude: false,
					add_groups: true
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getEmployees()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {

					let list = [];
					if (response.data.list) {
						Object.entries(response.data.list).forEach(([key, employee]) => {

							let prefix = 'u';
							if (employee.is_manager)
								prefix = 'm';

							list.push({
								value: prefix + employee.id.toString(),
								name: (employee.department !== 'null') ? employee.name : employee.name + ' (' + employee.department + ')'
							});
						});
					}
					return list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getEmployees()::axios', error);

			});
		},
		getTypes() {
			return api.get('/common/types', {
				params: {
					section: 'clients'
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getTypes()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {
					let list = [];
					Object.entries(response.data.list).forEach(([key, option]) => {
						list.push({
							value: option.id,
							name: option.name
						});
					});
					return list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getTypes()::axios', error);

			});
		},
		getTags() {
			return api.get('/common/tags').then((response) => {

				CommonService.log('debug', 'getTags()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {
					let list = [];
					Object.entries(response.data.list).forEach(([key, option]) => {
						list.push({
							value: option.id,
							name: option.name
						});
					});
					return list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getTags()::axios', error);

			});
		},
		getRoles() {
			return api.get('/common/roles', {
				params: {
					section: 'clients'
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getRoles()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {

					let list = [];
					if (response.data.list) {
						Object.entries(response.data.list).forEach(([key, role]) => {
							list.push({
								value: role.id.toString(),
								name: role.name
							});
						});
					}
					return list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getRoles()::axios', error);

			});
		},
		getSources() { // @todo: need to remove, implement in `CommonDataService`

			let _this = this;
			return api.get('/common/sources', {
				params: {}
			})
			.then((response) => {

				CommonService.log('debug', 'getSources()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {

					let list = [];
					if (response.data.list) {
						Object.entries(response.data.list).forEach(([key, source]) => {
							list.push({
								value: source.id.toString(),
								name: source.name
							});
						});
					}

					CommonService.log('info', 'SendPresentationsModal::getSources()', list);

					if (typeof _this.$store !== "undefined")
						_this.$store.commit('setSourcesList', list);

					return list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getSources()::axios', error);

			});
		},
		getObjects(objects_ids) {

			let _this = this;
			CommonService.log('debug', 'getObjects()', objects_ids);

			return api.get('/objects/list', {
				params: {
					objects_ids: objects_ids,
					section: 'presentations'
				}
			})
			.then((response) => {

				CommonService.log('debug', 'getObjects()::axios', {response: response.data});

				this.objects_ready = true;
				if (response.status == 200 && response.data.success) {

					_this.getCatalogLink();

					response.data.list.forEach(object => {
						_this.getPresentationLink(object.id).then(link => {
							_this.links[object.id] = link;
						})
					});

					return response.data.list;
				} else {
					return {};
				}

			}).catch(function (error) {

				CommonService.log('error', 'getObjects()::axios', error);

			});
		},
		setClientsFilter(filter_name, filter_value) {

			if (typeof this.filter[filter_name] !== "undefined") {
				this.filter[filter_name] = filter_value;
			} else {
				this.filter = [...this.filter, {
					[filter_name]: filter_value,
				}];
			}

			CommonService.log('log', 'setClientsFilter()', this.filter);

			let _this = this;
			setTimeout(function () {
				if (!_this.proccessed) {
					_this.clients = [];
					_this.getClientsList().then(data => {
						_this.clients = data
					});
				}
			}, 2000);

		},
		isEmpty(data) {
			return CommonService.isEmpty(data);
		},
		inArray(needle, haystack) {
			return CommonService.inArray(needle, haystack);
		},
		resetData() {
			this.objects = [];
			this.objectsIds = [];
			this.send_clients = [];
			//this.send_with = 1;
			this.send_with_list = [
				{value: 1, name: 'E-mail'},
				{value: 2, name: 'WhatsApp'},
				{value: 3, name: 'Telegram'},
			];
			this.send_provide = 1;
			this.send_subject = '';
			this.send_message = '';
			this.proccessed = false;
			this.objects_ready = false;
			this.autoopen_links = 1;
			this.search_query = '';
			this.clients_offset = 0;
			this.descriptions = {};
			this.hidden_contacts = [];
			this.hidden_address = [];
			this.filter = {
				employees: [],
				types: [],
				tags: [],
				roles: [],
				source: [],
			};
			this.send_in_proccessed = false;
		},
		hiddenAllAddresses: function(state) {
			if (state) {
				this.hidden_address = this.objectsIds;
			} else {
				this.hidden_address = [];
			}
		},
		getFilter: function() {
			return this.filter;
		},
		showAlertMessage: function(text) {
			alert(text);
		},
		setSendWith: function(value) {

			this.send_with = value;

			CommonService.log('debug', 'setSendWith()', this.send_with);

		},
		getPresentationLink(object_id, send_with) {
			let _this = this;
			return api.post('/common/presentation-link', {
				object_id: object_id,
				//newbuilding_id: null,
				hide_contacts: _this.hidden_contacts,
				hidden_address: _this.hidden_address,
				with: (typeof send_with != "undefined") ? send_with : _this.send_with,
				descriptions: _this.descriptions,
			}).then((response) => {


				CommonService.log('debug', 'getPresentationLink()::axios', {response});

				if (response.status == 200 && response.data.success && !_this.isEmpty(response.data.link))
					return response.data.link;

				return null;
			}).catch(function (error) {

				CommonService.log('error', 'getPresentationLink()::axios', error);

				return null;
			});
		},
		getCatalogLink() {
			let _this = this;
			return api.post('/common/catalog-link', {
				objects_ids: _this.objectsIds,
				clients_ids: _this.send_clients,
				catalog_id: _this.catalog_id,
				hide_contacts: _this.hidden_contacts,
				hide_address: _this.hidden_address,
				with: _this.send_with,
				descriptions: _this.descriptions,
			}).then((response) => {


				CommonService.log('debug', 'getCatalogLink()::axios', {response});

				if (response.status == 200 && response.data.success && !_this.isEmpty(response.data.link)) {

					if (!_this.isEmpty(response.data.catalog_id))
						_this.catalog_id = response.data.catalog_id;

					let link = response.data.link;
					_this.catalog_link = link;
					return link;
				}

				return null;
			}).catch(function (error) {

				CommonService.log('error', 'getCatalogLink()::axios', error);

				return null;
			});
		},
		downloadLink(object_id) {
			this.getPresentationLink(object_id, 2).then(url => {
				if (!this.isEmpty(url)) {
					const link = document.createElement('a');
					link.href = url;
					link.target = "_blank";
					link.download = true;
					link.click();
					URL.revokeObjectURL(link.href);
				} else {
					console.error('Ошибка генерации ссылки. ИД объекта: ' + object_id);
					alert('Ошибка генерации ссылки.');
				}
			});
		},
		setDescription(object_id, description, price) {

			this.descriptions['object_' + object_id] = {
				description: description,
				price: price
			};

			CommonService.log('debug', 'setDescription()', this.descriptions);

		},
		editDescription(object_id) {

			this.$refs.editObjectDescrModal.object_id = object_id;

			if (!this.isEmpty(this.descriptions['object_'+object_id])) {

				let item = this.descriptions['object_'+object_id];
				if (!this.isEmpty(item.description))
					this.$refs.editObjectDescrModal.description = item.description;

				if (!this.isEmpty(item.price))
					this.$refs.editObjectDescrModal.price = item.price;

			}

			this.$refs.editObjectDescrModal.showModal();

			CommonService.log('debug', 'editDescription()', { object_id });

		},
		getClientsList() {

			let filter = this.getFilter();
			this.proccessed = true;

			CommonService.log('debug', 'getClientsList()', filter);

			return api.get('/common/clients', {
				params: {
					filters: filter,
					offset: this.clients_offset ?? 0,
					search_query: this.search_query,
				}
			}).then((response) => {

				this.proccessed = false;

				CommonService.log('debug', 'getClientsList()::axios', {response: response.data});

				if (response.status == 200 && response.data.success) {
					let clients = [];
					Object.entries(response.data.list).forEach(([key, option]) => {

						let client_name = option.name;

						let contacts = '';
						if (option.phone && option.phone !== '')
							contacts = option.phone;

						if (option.email && contacts !== '')
							contacts = contacts + ', ' + option.email;
						else if (option.email)
							contacts = option.email;

						if (contacts !== '')
							client_name = client_name + ' (' + contacts + ')';

						clients.push({
							value: option.id,
							name: client_name
						});
					});

					return clients;
				}

				return {};

			}).catch(function (error) {

				CommonService.log('error', 'getClientsList()::axios', error);

				this.proccessed = false;
			});
		},
		addRemoveHiddenContacts(object_id, state) {

			CommonService.log('debug', 'addRemoveHiddenContacts()', {object_id: object_id, state: state});

			if (state) {
				this.hidden_contacts.push(object_id);
			} else if (this.hidden_contacts.includes(object_id)) {
				let index = this.hidden_contacts.indexOf(object_id);
				if (index !== -1)
					this.hidden_contacts.splice(index, 1);
			}
		},
		addRemoveHiddenAddress(object_id, state) {

			CommonService.log('debug', 'addRemoveHiddenAddress()', {object_id: object_id, state: state});

			if (state) {
				this.hidden_address.push(object_id);
			} else if (this.hidden_address.includes(object_id)) {
				let index = this.hidden_address.indexOf(object_id);
				if (index !== -1)
					this.hidden_address.splice(index, 1);
			}
		},
		setAndValidate(name, value) {

			let errors = [];
			let parent = null;
			let child = null;
			let parts = name.split('.', 2);
			if (parts.length == 2) {
				parent = parts[0];
				child = parts[1];
			}

			// Валидация значения
			if (this.$refs[name] && typeof this.$refs[name] !== "undefined") {

				if (this.$refs[name].inputLabel) {
					let label = this.$refs[name].inputLabel.replace(/:+$/, '');

					if (this.$refs[name].required) {
						if (value) {
							if (value.toString() == '0' || value.toString() == '-1')
								errors.push('Поле `' + label + '` обязательно к заполнению!');
							else if (this.isEmpty(value))
								errors.push('Поле `' + label + '` обязательно к заполнению!');
						} else {
							errors.push('Поле `' + label + '` обязательно к заполнению!');
						}
					}

					if (!this.isEmpty(errors)) {
						this.errors[name] = {
							name: name,
							label: label,
							errors: errors
						};

						this.$refs[name].valid = false;
						this.$refs[name].invalid = errors.join('<br/>');
					} else {
						this.errors[name] = null;
						this.$refs[name].valid = true;
						this.$refs[name].invalid = false;
					}
				}
			}

			// Установка значения
			if (parent && child) {
				if (!this.isEmpty(this[parent])) {
					if (typeof (this[parent][child]) !== "undefined") {
						this[parent][child] = value;
					} else {
						this[parent] = {
							...{
								[child]: value
							},
							...this[parent]
						};
					}
				} else {
					this[parent] = {
						[child]: value
					};
				}
			} else if (parent == null && name) {
				this[name] = value;
			} else {
				if (parent && child)
					this[parent][child] = null;
				else
					this[name] = null;
			}

			CommonService.log('debug', 'setAndValidate()', { name: name, value: value, is_form_changed: this.is_form_changed, errors: errors });

		},
		validateAll(fields) {

			this.errors = [];
			let is_valid = false;
			let invalid_input = null;
			for (const [name, value] of Object.entries(fields)) {
				this.setAndValidate(name, value);
			}

			setTimeout(() => {
				this.errors = CommonService.removeEmpty(this.errors);
			}, 600);

			if (typeof Object.values(this.errors) == "object") {
				this.errors = CommonService.removeEmpty(this.errors);
				if (typeof (this.errors[0]) !== "undefined") {
					if (!this.isEmpty(Object.values(this.errors)[0]['name'])) {
						let invalid_input = Object.values(this.errors)[0]['name'];
						if (invalid_input && typeof invalid_input !== "undefined") {
							let input = this.$refs[invalid_input].$el;
							if (input) {
								CommonService.scrollIntoView(input);
							}
						}
					}
				}
			}

			is_valid = this.isEmpty(this.errors);

			CommonService.log('debug', 'validateAll()', {fields: fields, is_valid: is_valid, errors: this.errors});

			return is_valid;
		},
		sendPresentations() {

			let _this = this;
			_this.send_in_proccessed = true;

			let fields = {
				send_clients: _this.send_clients,
				send_with: _this.send_with,
				send_provide: _this.send_provide,
				send_subject: _this.send_subject,
				send_message: _this.send_message,
				autoopen_links: _this.autoopen_links
			};

			if (this.validateAll(fields) && !CommonService.isEmpty(_this.objects) && !CommonService.isEmpty(_this.clients)) {

				let params = {
					objects_ids: _this.objects.map(object => object.id),
					//newbuildings_ids: [],
					clients_ids: _this.send_clients,
					hide_contacts: _this.hidden_contacts,
					hide_address: _this.hidden_address,
					with: _this.send_with,
					autoopen_links: _this.autoopen_links,
					catalog_id: _this.catalog_id,
					descriptions: _this.descriptions,
					provide: _this.send_provide,
					subject: _this.send_subject,
					message: _this.send_message,
				};

				CommonService.log('info', 'sendPresentations()', params);

				api.post('/common/presentations', params, {
					timeout: 300000 // 5 min.
				}).then((response) => {

					CommonService.log('debug', 'sendPresentations()::axios', {response});

					if (response.status == 200 && response.data.success) {

						let message = '';
						if ((_this.send_with == 2 || _this.send_with == 3) && !_this.isEmpty(response.data.links)) {

							if (_this.autoopen_links == 1) {
								_this.emitter.emit("openLink", {url: response.data.links[0], target: '_blank'});
							} else {

								if (_this.objects.length > 1)
									message = 'Презентации сформированы.';
								else
									message = 'Презентация сформирована.';

								if (_this.send_with == 2) {

									if (_this.send_clients.length > 1)
										message += '<br/><br/>Вы можете открыть WhatApp для отправки сообщений клиентам по ссылкам:<br/>';
									else
										message += '<br/><br/>Вы можете открыть WhatApp для отправки сообщения клиенту по ссылке:<br/>';

								} else if (_this.send_with == 3) {

									if (_this.send_clients.length > 1)
										message += '<br/><br/>Вы можете открыть Telegram для отправки сообщений клиентам по ссылкам:<br/>';
									else
										message += '<br/><br/>Вы можете открыть Telegram для отправки сообщения клиенту по ссылке:<br/>';

								}

								message += '<ul class="list-unstyled mt-3">';
								response.data.links.forEach((url, key) => {
									message += '<li><a href="'+url+'" target="_blank">Сообщение #'+(key+1)+'</a></li>';
								});
								message += '</ul>';

								_this.resultsModalState.message = message;
								_this.resultsModalState.state = 'success';
								_this.resultsModalState.title = 'Спасибо!';
							}
						} else {
							if (_this.objects.length > 1)
								message = 'Презентации были успешно отправлены.';
							else
								message = 'Презентация была успешно отправлена.';

							_this.resultsModalState.message = message;
							_this.resultsModalState.state = 'success';
							_this.resultsModalState.title = 'Спасибо!';
						}

						_this.clearList();
						_this.resetData();
					} else {
						_this.resultsModalState.state = 'error';
						_this.resultsModalState.title = 'Произошла ошибка!';
						_this.resultsModalState.message = 'Презентации не были отправлены.';
						_this.resetData();
					}

					/*CommonService.debounce(() => {
						let modal = CommonService.getModal('sendPresentationsModal')
						if (modal) {
							this.send_in_proccessed = false;
							modal.hide();
						}
					}, 1000);*/
				}).catch(function (error) {

					_this.send_in_proccessed = false;
					
					CommonService.log('error', 'sendPresentations()::axios', error);

					_this.resultsModalState.state = 'error';
					_this.resultsModalState.title = 'Произошла ошибка!';
					_this.resultsModalState.message = 'Презентации не были отправлены.';

					/*CommonService.debounce(() => {
						_this.resetData();
						let modal = CommonService.getModal('sendPresentationsModal')
						if (modal) {
							_this.send_in_proccessed = false;
							modal.hide();
						}
					}, 1000);*/
				});
			} else {

				/*let modal = CommonService.getModal('sendPresentationsModal')
				if (modal) {
					_this.send_in_proccessed = false;
					modal.hide();
				}*/

				_this.resultsModalState.state = 'error';
				_this.resultsModalState.title = 'Ошибка!';
				_this.resultsModalState.message = 'Необходимо выбрать минимум один объект и одного клиента для отправки презентации.';
			}
		},
	}, CommonDataService.methods),
	mounted() {

		this.getClientsList().then(data => { this.clients = data });

		if (!this.isEmpty(this.filters.employees) == 0)
			this.getEmployees().then(data => { this.filters.employees = data });

		if (!this.isEmpty(this.filters.types) == 0)
			this.getTypes().then(data => { this.filters.types = data });

		if (!this.isEmpty(this.$store.getters.tagsList)) {
			this.filters.tags = this.$store.getters.tagsList;
		} else if (!this.isEmpty(this.filters.tags) == 0) {
			this.getTags().then(data => { this.filters.tags = data });
		}

		if (!this.isEmpty(this.$store.getters.rolesList)) {
			this.filters.roles = this.$store.getters.rolesList;
		} else if (!this.isEmpty(this.filters.roles) == 0) {
			this.getRoles().then(data => { this.filters.roles = data });
		}

		if (!this.isEmpty(this.$store.getters.sourcesList)) {
			this.filters.sources = this.$store.getters.sourcesList;
		} else if (!this.isEmpty(this.filters.sources) == 0) {
			this.getSources().then(data => { this.filters.sources = data });
		}

		this.modal = document.getElementById('sendPresentationsModal');
		if (this.modal && typeof this.modal !== "undefined") {

			let _this = this;
			this.modal.addEventListener('hidden.bs.modal', function (event) {
				_this.objects = [];
				_this.objectsIds = [];
				_this.clients = [];
				_this.emitter.emit("global.modalClose", this.modal);
			});

			this.modal.addEventListener('shown.bs.modal', function (event) {

				_this.send_clients = [];
				_this.catalog_id = null;
				_this.hidden_contacts = [];
				_this.hidden_address = [];
				_this.send_with = 1;
				_this.send_provide = 3;
				_this.descriptions = [];
				_this.objects = _this.objectsList;

				if (_this.isEmpty(_this.clients))
					_this.getClientsList().then(data => { _this.clients = data });

				if (!_this.isEmpty(_this.$store.getters.userInfo)) {

					if (!_this.isEmpty(_this.$store.getters.userInfo.send_message))
						_this.send_message = _this.$store.getters.userInfo.send_message;

				}

				if (!_this.isEmpty(_this.objectsIds)) {
					_this.getObjects(_this.objectsIds).then((objects) => {
						_this.objects = objects;
					});
				}

				if (process.env.NODE_ENV == "development") {
					console.debug('sendPresentationsModal::show.bs.modal', {
						objects: _this.objects,
						objects_ids: _this.objectsIds,
						clients: _this.clients,
						employees: _this.filters.employees,
						types: _this.filters.types,
						tags: _this.filters.tags,
						roles: _this.filters.roles,
						sources: _this.filters.sources,
					});
				}
				_this.emitter.emit("global.modalShown", this.modal);
			});
		}
	},
	watch: {
		'send_with': function(value, oldValue) {
			let clients = this.send_clients;
			if (!this.isEmpty(clients) && (value == 2 || value == 3) && oldValue == 1)
				this.send_clients = clients[0];
			else if (!this.isEmpty(this.send_clients) && (oldValue == 2 || oldValue == 3) && value == 1)
				this.send_clients = [clients];
			else if (!this.isEmpty(this.send_clients) && (value == 2 || value == 3) && (oldValue == 2 || oldValue == 3))
				this.send_clients = clients;
			else
				this.send_clients = null;
		},
		search_query(value, oldValue) {
			if ((value !== oldValue && (value.length >= 3 || oldValue.length >= 3)) || value.length == 0) {
				this.getClientsList().then(data => {
					this.clients = data;
				});
				//this.clients_offset = 0;
			}
		},
		clients_offset(value, oldValue) {
			if (value !== oldValue && value != 0) {
				this.getClientsList().then(data => {
					this.clients = [...this.clients, ...data];
				});
			}
		}
	},
	computed: {
		objectsIds() {
			return (typeof this.objectsList !== "undefined") ? this.objectsList : [];
		},
		objectsItems() {
			return this.objects ?? [];
		},
		clientsIsValid() {
			return this.$nextTick(() => {
				return !this.isEmpty(this.clients);
			});
		}
	},
}
</script>